import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import InfoBar from './InfoBar';
// import { bulkEmit } from '../../utils/socket';
import { kickMember } from '../../actions/RoomActions';
import Draggable from './Draggable';
import styles from './PeerView.module.css';
import {
  VIDEO_SIZE,
  VIDEO_BITRATE,
  SHARE_VIDEO_SIZE,
  SHARE_VIDEO_BITRATE,
} from '../../constants';
import { useSelector, useDispatch } from 'react-redux';

import { stringAvatar } from '../../utils/avatar';
import {
  setSpatialLayer,
  setSpatialLayerShare,
} from '../../store/slices/producerLayerSlice';
import { withRoomContext } from '../../lib/RoomClientContext';
import logger from '../../utils/logger';

function getMaxLayer(height, width, sh, sw, nLayers, cover) {
  let maxLayer = nLayers - 1;
  let threshold = Math.pow(2, nLayers);

  for (let i = 0; i < nLayers - 1; i++) {
    threshold /= 2;
    const meetsCondition = cover
      ? height < sh / threshold && width < sw / threshold
      : height < sh / threshold || width < sw / threshold;

    if (meetsCondition) {
      maxLayer = i;
      break;
    }
  }

  return maxLayer;
}

function PeerView({
  roomId,
  peerId,
  producers,
  show,
  mute,
  deviceSettings,
  dominantSpeaker,
  raisedHand,
  resolution,
  order,
  isSelf,
  userData,
  videoFit,
  onVideoFitChange,
  floatSelf,
  setFloatSelf,
  floatShare,
  setFloatShare,
  onPin,
  pinned,
  onPeerMute,
  audioMuted, // Self audio
  shareFullscreen,
  setShareFullscreen,
  isShare,
  isAdmin,
  isMobile,
  isAlone,
  viewLayout,
  networkStatus,
  debug,
  roomClient,
}) {
  const clientOptions = useSelector((state) => state.user.clientOptions);
  const [loading, setLoading] = useState(true);
  const [minimized, setMinimized] = useState(false);
  const [_videoFit, _setVideoFit] = useState(
    videoFit || (isSelf ? 'contain' : 'cover')
  );
  const containerRef = useRef(null);
  const renderRef = useRef(null);
  const spatialLayer = useSelector((state) => state.producerLayer.spatialLayer);
  const spatialLayerShare = useSelector(
    (state) => state.producerLayer.spatialLayerShare
  );
  const peersMuted = useSelector((state) => state.member.peersMuted);
  const [isMuted, setIsMuted] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let t;
    if (isAlone) {
      t = setTimeout(() => {
        setMinimized(false);
        setFloatSelf(false, false);
      }, 2000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [isAlone]);

  useEffect(() => {
    if (!renderRef.current) return;
    if (renderRef.current?.nodeName === 'VIDEO') {
      renderRef.current.style.objectFit = _videoFit;
    } else {
      const canvas = renderRef.current.querySelector('canvas');
      if (canvas) {
        canvas.style.objectFit = _videoFit;
      }
    }
  }, [_videoFit]);

  useEffect(() => {
    if (videoFit) {
      _setVideoFit(videoFit);
    } else if (
      clientOptions?.startVideoFit &&
      (clientOptions?.startVideoFit === 'cover' ||
        clientOptions?.startVideoFit === 'contain')
    ) {
      _setVideoFit(clientOptions?.startVideoFit);
    } else {
      _setVideoFit('cover');
    }
  }, [videoFit, clientOptions]);

  useEffect(() => {
    if (
      videoFit ||
      loading ||
      clientOptions?.startVideoFit ||
      !renderRef.current
    )
      return;
    let videoAspectRatio;
    if (renderRef.current?.nodeName === 'VIDEO') {
      videoAspectRatio =
        renderRef.current.videoWidth / renderRef.current.videoHeight;
    } else {
      const canvas = renderRef.current.querySelector('canvas');
      if (!canvas) return;
      videoAspectRatio = canvas.width / canvas.height;
    }
    const containerAspectRatio =
      containerRef.current.offsetWidth / containerRef.current.offsetHeight;
    const areaLost = videoAspectRatio - containerAspectRatio;
    const videoIsPortrait = videoAspectRatio < 1;
    if (videoIsPortrait) {
      if (containerAspectRatio > 0.8) {
        _setVideoFit('contain');
      } else {
        _setVideoFit('cover');
      }
    } else {
      if (Math.abs(areaLost) > 1.1) {
        _setVideoFit('contain');
      } else {
        _setVideoFit('cover');
      }
    }
  }, [producers, resolution, videoFit, loading, clientOptions]);

  useEffect(() => {
    if (isSelf || !renderRef.current) return;
    (async () => {
      for (const producer of producers) {
        if (producer.producer.kind === 'video') {
          const { width, height } = renderRef.current.getBoundingClientRect();
          let spatialLayers;
          let sh;
          let sw;
          if (isShare) {
            spatialLayers = SHARE_VIDEO_BITRATE.length;
            sh = SHARE_VIDEO_SIZE.height;
            sw = SHARE_VIDEO_SIZE.width;
          } else {
            spatialLayers = VIDEO_BITRATE.length;
            sh = VIDEO_SIZE.height;
            sw = VIDEO_SIZE.width;
          }

          const maxLayer = getMaxLayer(
            height,
            width,
            sh,
            sw,
            spatialLayers,
            videoFit === 'cover'
          );
          const spatialLayerToSet = Math.max(
            0,
            Math.min(maxLayer, spatialLayers - 1)
          );

          const currentSpatialLayer = isShare
            ? spatialLayerShare
            : spatialLayer;
          if (spatialLayerToSet !== currentSpatialLayer) {
            if (isShare) {
              dispatch(setSpatialLayerShare(spatialLayerToSet));
            } else {
              dispatch(setSpatialLayer(spatialLayerToSet));
            }
            await roomClient.request('setPreferredLayers', {
              fillWithPeerId: true,
              otherPeerId: peerId,
              consumerId: producer?.consumer?.id,
              producerId: producer.producer.id,
              layers: {
                spatialLayer: spatialLayerToSet,
              },
            });
          }
        }
      }
    })();
  }, [producers, resolution, isSelf, peerId, roomId, videoFit]);

  useEffect(() => {
    (async () => {
      if (isSelf) return;
      for (const producer of producers) {
        const _show = show;
        if (producer.consumer && producer.consumer.kind === 'video') {
          if (!_show) {
            logger.log('Inside pause consumer video');
            // if (producer.consumer.paused) {
            //   return;
            // }
            const { cancelled } = await roomClient.request('pauseConsumer', {
              consumerId: producer.consumer.id,
            });
            if (!cancelled) {
              producer.consumer.pause();
            }
          } else {
            logger.log('Inside resume consumer video');
            // if (!producer.consumer.paused) {
            //   return;
            // }
            const { cancelled } = await roomClient.request('resumeConsumer', {
              consumerId: producer.consumer.id,
            });
            if (!cancelled) {
              producer.consumer.resume();
            }
          }
        }
      }
    })();
  }, [producers, show, isSelf, roomId]);

  useEffect(() => {
    const isMuted =
      (isSelf && audioMuted) ||
      (!isSelf &&
        (peersMuted[peerId] ||
          producers
            .filter((x) => x.producer.kind === 'audio')
            .every((producer) => !producer.show)));
    logger.log(`Is peer: ${peerId} muted: ${isMuted}`);
    setIsMuted(isMuted);
  }, [peersMuted]);

  const count = producers.filter((x) => x.producer.kind === 'video').length;
  const noVideo = !count;
  const topAlign = producers.find((x) => x.producer.kind === 'video')?.canvas
    ?.controls;

  const container = (
    <div
      className={classnames(styles.container, {
        [styles.video]: isSelf && isShare ? !floatShare : !floatSelf,
        [styles.spotlight]:
          !isAlone && viewLayout === 'spotlight' && order === 0,
        [styles.floating]: isSelf && isShare ? floatShare : floatSelf,
      })}
      style={{
        order,
        display: show ? 'flex' : 'none',
      }}
      ref={containerRef}
    >
      {(debug ? [] : producers).map((producer) => (
        <div
          key={producer.producer.id}
          className={styles.videoContainer}
          style={{
            display: producer.producer.kind === 'audio' ? 'none' : 'flex',
          }}
        >
          {producer.producer.kind === 'audio' ? (
            <audio
              key={`${producer.producer.id}-audio`}
              data-muted={isMuted}
              hidden
              autoPlay
              playsInline
              controls={false}
              ref={(ref) => {
                if (
                  ref &&
                  ((!ref.srcObject && producer.stream) ||
                    (ref.srcObject && !producer.stream))
                ) {
                  ref.srcObject = producer.stream;
                  ref.id = 'audio-' + producer.producer.id;
                }
                if (ref && ref.setSinkId && deviceSettings.outputDevice) {
                  ref.setSinkId(deviceSettings.outputDevice);
                }
              }}
            />
          ) : (
            <>
              <InfoBar
                roomId={roomId}
                peerId={peerId}
                raisedHand={raisedHand}
                muted={isMuted}
                pinned={pinned}
                userData={userData}
                isMobile={isMobile}
                isAdmin={isAdmin}
                isSelf={isSelf}
                isShare={isShare}
                shareFullscreen={shareFullscreen}
                setShareFullscreen={setShareFullscreen}
                floatSelf={floatSelf}
                floatShare={floatShare}
                setFloatSelf={setFloatSelf}
                setFloatShare={setFloatShare}
                isAlone={isAlone}
                minimized={minimized}
                setMinimized={setMinimized}
                onPin={onPin}
                onPeerMute={onPeerMute}
                peerMuted={peersMuted[peerId]}
                kickMember={kickMember}
                audioMuted={audioMuted}
                videoFit={_videoFit}
                onVideoFitChange={onVideoFitChange}
                topAlign={topAlign}
                networkStatus={networkStatus}
              />
              <div
                className={classnames(styles.videoContainerBorder, {
                  [styles.videoContainerBorderActive]:
                    dominantSpeaker &&
                    producer.producer.kind === 'video' &&
                    peerId === dominantSpeaker.peerId,
                })}
              />
              {!minimized && show ? (
                producer.stream && !producer.canvas ? (
                  <video
                    key={`${producer.producer.id}-video`}
                    ref={(ref) => {
                      renderRef.current = ref;
                      if (
                        ref &&
                        ((!ref.srcObject && producer.stream) ||
                          (ref.srcObject && !producer.stream) ||
                          producer.stream?.force)
                      ) {
                        ref.srcObject = producer.stream;
                        if (producer.stream?.force)
                          producer.stream.force = false;
                        setLoading(true);
                        function onLoaded() {
                          setLoading(false);
                          ref.removeEventListener('loadeddata', onLoaded);
                        }
                        ref.addEventListener('loadeddata', onLoaded);
                        ref.style.width = '100%';
                        ref.style.height = '100%';
                        ref.style.objectFit = _videoFit;
                        ref.id = 'video-' + producer.producer.id;
                        if (
                          isSelf &&
                          !isShare &&
                          !clientOptions.disableMirroredCamera
                        ) {
                          ref.style.transform = 'scaleX(-1)';
                        }
                      }
                    }}
                    playsInline
                    autoPlay
                    controls={false}
                    muted
                  />
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    ref={(ref) => {
                      renderRef.current = ref;
                      if (ref && producer.canvas) {
                        ref.innerHTML = '';
                        producer.canvas.style.width = '100%';
                        producer.canvas.style.height = '100%';
                        producer.canvas.style.objectFit = _videoFit;
                        producer.canvas.id = 'video-self';
                        if (
                          isSelf &&
                          !isShare &&
                          !clientOptions?.disableMirroredCamera
                        ) {
                          producer.canvas.style.transform = 'scaleX(-1)';
                        }
                        ref.appendChild(producer.canvas);
                        setLoading(false);
                      }
                    }}
                  />
                )
              ) : null}
              {loading || (!isSelf && producer?.producer?.paused) ? (
                <div className={styles.loader}>
                  <CircularProgress />
                </div>
              ) : null}
            </>
          )}
        </div>
      ))}
      {noVideo ? (
        <div className={styles.videoContainer}>
          {!minimized ? (
            <div className={styles.avatarContainer}>
              <Avatar
                {...stringAvatar(userData?.name, { width: 100, height: 100 })}
                className={styles.avatar}
              />
            </div>
          ) : null}
          <InfoBar
            roomId={roomId}
            peerId={peerId}
            raisedHand={raisedHand}
            muted={isMuted}
            pinned={pinned}
            userData={userData}
            isMobile={isMobile}
            isAdmin={isAdmin}
            isSelf={isSelf}
            isShare={isShare}
            shareFullscreen={shareFullscreen}
            setShareFullscreen={setShareFullscreen}
            floatSelf={floatSelf}
            floatShare={floatShare}
            setFloatSelf={setFloatSelf}
            setFloatShare={setFloatShare}
            isAlone={isAlone}
            minimized={minimized}
            setMinimized={setMinimized}
            onPin={onPin}
            onPeerMute={onPeerMute}
            peerMuted={peersMuted[peerId]}
            kickMember={kickMember}
            audioMuted={audioMuted}
            videoFit={_videoFit}
            onVideoFitChange={onVideoFitChange}
            topAlign={topAlign}
            networkStatus={networkStatus}
          />
          <div
            className={classnames(styles.videoContainerBorder, {
              [styles.videoContainerBorderActive]:
                dominantSpeaker && peerId === dominantSpeaker.peerId,
            })}
          />
        </div>
      ) : null}
    </div>
  );

  if ((!isAlone && floatSelf) || (isSelf && isShare && floatShare)) {
    return (
      <Draggable
        minimized={minimized}
        startPos={isShare ? { right: 10, top: 0 } : { right: 10, bottom: 0 }}
        movable={!isShare}
      >
        {container}
      </Draggable>
    );
  }
  return container;
}

export default withRoomContext(PeerView);
