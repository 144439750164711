import { createSlice } from '@reduxjs/toolkit';

export const snackSlice = createSlice({
  name: 'snack',
  initialState: {
    snacks: [],
  },
  reducers: {
    clear: (state) => {
      state.snacks = [];
    },
    clearLast: (state) => {
      state.snacks = state.snacks.slice(0, -1);
    },
    clearOldSnacks: (state) => {
      state.snacks = state.snacks.filter(
        (snack) => Date.now() - snack.created < (snack.waitTime || 6000)
      );
    },
    addSnack: (state, action) => {
      const snack = {
        ...action.payload.options,
        msg: action.payload.msg,
        type: action.payload.type,
        created: Date.now(),
      };
      state.snacks = [...state.snacks, snack];
    },
  },
});

export const { clear, clearOldSnacks, clearLast, addSnack } =
  snackSlice.actions;

export default snackSlice.reducer;
