import { createSlice } from '@reduxjs/toolkit';
import { getStorageItem, setStorageItem } from '../../utils/storage';

export const controlSlice = createSlice({
  name: 'control',
  initialState: {
    shareFullScreen: false,
    muted: getStorageItem(localStorage, 'muted', false), // Fetch the last state from localStorage,
    micLoading: false,
    hideVideo: getStorageItem(localStorage, 'hideVideo', false), // Fetch the last state from localStorage,
    handRaised: false,
    videoLoading: false,
    showTalkingPopover: false,
  },
  reducers: {
    setShareFullScreen: (state, action) => {
      state.shareFullScreen = action.payload;
    },
    setMuted: (state, action) => {
      state.muted = action.payload;
      // Persist the muted state to localStorage
      setStorageItem(localStorage, 'muted', action.payload);
    },
    toggleMuted: (state) => {
      // Protect against toggling whilst changing state
      if (!state.micLoading) {
        state.muted = !state.muted;
        // Persist the muted state to localStorage
        setStorageItem(localStorage, 'muted', state.muted);
      }
    },
    setHideVideo: (state, action) => {
      state.hideVideo = action.payload;
      // Persist the hideVideo state to localStorage
      setStorageItem(localStorage, 'hideVideo', action.payload);
    },
    toggleHideVideo: (state) => {
      // Protect against toggling whilst changing state
      if (!state.videoLoading) {
        state.hideVideo = !state.hideVideo;
        // Persist the hideVideo state to localStorage
        setStorageItem(localStorage, 'hideVideo', state.hideVideo);
      }
    },
    setMicLoading: (state, action) => {
      state.micLoading = action.payload;
    },
    setHandRaised: (state, action) => {
      state.handRaised = action.payload;
    },
    setVideoLoading: (state, action) => {
      state.videoLoading = action.payload;
    },
    setShowTalkingPopover: (state, action) => {
      state.showTalkingPopover = action.payload;
    },
  },
});

export const {
  setShareFullScreen,
  setMuted,
  toggleMuted,
  setHideVideo,
  toggleHideVideo,
  setMicLoading,
  setHandRaised,
  setVideoLoading,
  setShowTalkingPopover,
} = controlSlice.actions;

export default controlSlice.reducer;
