export function getObjectKeysWithDifferences(firstObject, secondObject) {
  const differingKeys = [
    ...Object.keys(firstObject).filter(
      (key) =>
        firstObject[key] !== undefined &&
        (!secondObject.hasOwnProperty(key) ||
          JSON.stringify(firstObject[key]) !==
            JSON.stringify(secondObject[key]))
    ),
    ...Object.keys(secondObject).filter(
      (key) =>
        secondObject[key] !== undefined && !firstObject.hasOwnProperty(key)
    ),
  ];

  return differingKeys;
}
