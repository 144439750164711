import { createSlice } from '@reduxjs/toolkit';

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState: {
    networkStatuses: {},
  },
  reducers: {
    addNetworkStatus: (state, action) => {
      state.networkStatuses = { ...state.networkStatuses, ...action.payload };
    },
  },
});

export const { addNetworkStatus } = monitoringSlice.actions;

export default monitoringSlice.reducer;
