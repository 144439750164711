import React from 'react';
import styles from './Control.module.css';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material/styles';
import { Icon } from '../Icons';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';

const Control = React.forwardRef(function Control(
  {
    label,
    onClick,
    ariaLabel,
    icon,
    color = 'white',
    iconSize = 30,
    disabled = false,
    selected = false,
    isMobile = false,
  },
  ref
) {
  const theme = useTheme();
  if (isMobile) {
    return (
      <Tooltip title={label}>
        <span>
          <IconButton
            id={ariaLabel}
            aria-label={ariaLabel}
            color={color}
            disabled={disabled}
            onClick={(e) => {
              if (onClick) {
                e.stopPropagation();
                e.preventDefault();
                onClick(e);
              }
            }}
            ref={ref}
          >
            <Icon
              name={icon}
              width={iconSize}
              height={iconSize}
              color={disabled ? '#6B6B6B' : color}
            />
          </IconButton>
        </span>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={label}>
      <FormControlLabel
        className={classnames(styles.control, { [styles.selected]: selected })}
        control={
          <IconButton
            id={ariaLabel}
            aria-label={ariaLabel}
            color={color}
            disabled={disabled}
            onClick={(e) => {
              if (onClick) {
                e.stopPropagation();
                e.preventDefault();
                onClick(e);
              }
            }}
            ref={ref}
          >
            <Icon
              name={icon}
              width={iconSize}
              height={iconSize}
              color={disabled ? '#6B6B6B' : color}
            />
          </IconButton>
        }
        label={label}
        labelPlacement="bottom"
        sx={{ color: theme.palette[color]?.main || '#fff' }}
      />
    </Tooltip>
  );
});

export default Control;
