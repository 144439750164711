import { createSlice } from '@reduxjs/toolkit';

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    chat: [],
    numUnreads: 0,
  },
  reducers: {
    addChatMessage: (state, action) => {
      state.chat = [...state.chat, action.payload.message];
      if (action.payload.userId !== action.payload.message.peerId) {
        state.numUnreads += 1;
      }
    },
    setChat: (state, action) => {
      state.chat = action.payload;
    },
    resetUnreads: (state) => {
      state.numUnreads = 0;
    },
  },
});

export const { addChatMessage, setChat, resetUnreads } = chatSlice.actions;

export default chatSlice.reducer;
