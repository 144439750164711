import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import styles from './Draggable.module.css';
export default function Draggable({
  minimized,
  children,
  startPos = { right: 10, bottom: 0 },
  movable = true,
}) {
  const [moving, setMoving] = useState(false);
  const [pos, setPos] = useState(startPos);
  const offsetPosRef = useRef({ x: 0, y: 0 });
  const elRef = useRef(null);
  const posRef = useRef(pos);
  const headerHeight = 50;
  const snap = false;
  useEffect(() => {
    posRef.current = pos;
  }, [pos]);

  useEffect(() => {
    if ((!pos || minimized) && startPos) {
      setPos(startPos);
    }
  }, [pos, minimized, startPos]);

  function onMouseMove(e) {
    const parent = elRef.current.parentElement;
    const { x, y } = offsetPosRef.current;
    const { clientX, clientY } = e;

    const maxTop = parent.clientHeight - elRef.current.clientHeight;
    const maxLeft = parent.clientWidth - elRef.current.clientWidth;
    const left = Math.min(maxLeft, Math.max(0, clientX - x));
    const top = Math.min(maxTop, Math.max(0, clientY - headerHeight - y));
    if (left <= parent.clientWidth / 2) {
      setPos({ left, top });
    } else {
      setPos({ right: maxLeft - left, top });
    }
  }

  function onMouseUp(e) {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);

    // Check which quadrant of the window the mouse is in
    if (snap) {
      const { width, height } =
        elRef.current.parentNode.getBoundingClientRect();
      const { width: elWidth, height: elHeight } =
        elRef.current.getBoundingClientRect();

      const x = e.clientX;
      const y = e.clientY;
      const isLeft = x < window.innerWidth / 2;
      const isTop = y < window.innerHeight / 2;
      const isRight = x > window.innerWidth / 2;
      const isBottom = y > window.innerHeight / 2;

      let newPos;
      // Set the position to the closest corner
      if (isLeft && isTop) {
        newPos = { left: 16, top: 16 };
      }
      if (isRight && isTop) {
        newPos = { right: 16, top: 16 };
      }
      if (isLeft && isBottom) {
        newPos = { left: 16, bottom: 16 };
      }
      if (isRight && isBottom) {
        newPos = { right: 16, bottom: 16 };
      }

      const p = { ...posRef.current };

      if (newPos.right) {
        // Calculate the right value for animation based on left
        const right = width - p.left - elWidth;
        p.right = right;
        delete p.left;
      }

      if (newPos.bottom) {
        // Calculate the bottom value for animation based on top
        const bottom = height - p.top - elHeight;
        p.bottom = bottom;
        delete p.top;
      }
      setPos(p);

      setTimeout(() => {
        setPos(newPos);
        setMoving(false);
      }, 100);
    } else {
      setMoving(false);
    }
  }
  return (
    <div
      className={classnames(styles.floating, {
        [styles.moving]: moving,
        [styles.minimized]: minimized,
        [styles.movable]: movable,
      })}
      style={{ ...pos }}
      ref={elRef}
      onMouseDown={(e) => {
        if (!movable) return;
        setMoving(true);
        e.preventDefault();
        e.stopPropagation();
        // get diff from mousedown in box to box left/top
        const { left, top } = elRef.current.parentNode.getBoundingClientRect();
        const { left: innerLeft, top: innerTop } =
          elRef.current.getBoundingClientRect();
        const { clientX: x, clientY: y } = e;
        const pos = {
          x: x + left - innerLeft,
          y: y - top - innerTop + headerHeight,
        };
        offsetPosRef.current = pos;

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      }}
    >
      {children}
    </div>
  );
}
