import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DisconnectedModal.module.css';
import { Button, Grid } from '@mui/material';
import { withRoomContext } from '../../lib/RoomClientContext';

function DuplicatePeerModal({ roomClient }) {
  const { t } = useTranslation();

  return (
    <div className={styles.disconnectedModalContainer}>
      <div className={styles.disconnectedModalContent}>
        <h1>{t('Uh-oh!')}</h1>
        <p>
          {t(
            'You seem to be active from another tab/window/device. Cancel to close this request OR Join Here to close the previous request and join from here'
          )}
        </p>
      </div>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Button
            className={styles.button}
            color="newgreen"
            variant="outlined"
            onClick={() => roomClient.cancelJoinRequest()}
          >
            {t('Cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={styles.button}
            color="newgreen"
            variant="contained"
            onClick={() => roomClient.joinFromHereRequest()}
          >
            {t('Join Here')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRoomContext(DuplicatePeerModal);
