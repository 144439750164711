import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './i18n';
import './index.css';
import App from './App';
import store from './store/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';

import '@fontsource/manrope/300.css';
import '@fontsource/manrope/400.css';
import '@fontsource/manrope/500.css';
import '@fontsource/manrope/700.css';

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: [
        'Manrope',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontWeight: 550,
      fontSize: '0.8rem',
      lineHeight: 1.3,
      letterSpacing: '0.009em',
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#3265b7',
    },
    secondary: {
      main: '#EF4444',
    },
    gray: {
      main: '#3E3E3E',
      contrastText: '#bababa',
    },
    white: {
      main: '#FFFFFF',
      contrastText: '#000',
    },
    black: {
      main: '#000000',
    },
    danger: {
      main: '#EF4444',
    },
    success: {
      main: '#33B980',
    },
    green: {
      main: '#93c03e',
      contrastText: '#FFF',
    },
    newgreen: {
      main: '#1E5B53',
      contrastText: '#FFF',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '10px !important',
          background: 'white',
        },
        inputField: {
          background: 'white',
          color: '#000',
          paddingTop: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#8D8D8D',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#3E3E3E',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.12)',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(50, 101, 183, 0.15)',
            '&:hover': {
              backgroundColor: 'rgba(50, 101, 183, 0.15)',
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: '#6B6B6B',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#6B6B6B',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: '12px',
          paddingBottom: '12px',
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.NODE_ENV === 'development') {
  root.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  );
} else {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
