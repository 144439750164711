import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Stack, Typography as Text } from '@mui/material';
import Button from '@mui/material/Button';
import SnackBar from '../components/SnackBar';
import useWaitingListListener from '../hooks/useWaitingListListener';
import logger from '../utils/logger';
import { useDispatch, useSelector } from 'react-redux';
import { loadToken } from '../store/slices/userSlice';
import { withRoomContext } from '../lib/RoomClientContext';

function WaitingRoom({ roomClient }) {
  const dispatch = useDispatch();
  const { roomId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isRoomLocked = useSelector((state) => state.room.isRoomLocked);
  const admitted = useSelector((state) => state.user.admitted);

  useEffect(() => {
    dispatch(loadToken(roomId));
  }, [roomId]);

  const onJoin = (_data) => {
    logger.log('joining', roomId);
    navigate(`/video/${roomId}`);
  };

  useEffect(() => {
    if (!isRoomLocked || admitted) {
      onJoin();
    }
  }, [isRoomLocked, admitted]);

  const { resend } = useWaitingListListener(roomId, onJoin, roomClient);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100vh"
      sx={{ background: 'black', color: 'white !important' }}
    >
      <Helmet>
        <title>{roomId} - Waiting Room</title>
      </Helmet>
      <SnackBar />
      <Stack gap={6} alignItems="center" sx={{ maxWidth: 400 }}>
        <Stack gap={2}>
          <Text fontSize={36} fontWeight={800}>
            {t('Waiting Room')}
          </Text>
          <Text>
            {t(
              'This meeting is locked. We have sent a notification to the meeting host that you are waiting. The name you filled in will be visible to the host.'
            )}
          </Text>
          <Text>
            {t(
              'The meeting starts immediately when you are accepted into the meeting.'
            )}
          </Text>
        </Stack>
        <Button
          variant="contained"
          color="newgreen"
          onClick={resend}
          sx={{ width: 250, alignSelf: 'start' }}
        >
          {t('Knock again')}
        </Button>
      </Stack>
    </Stack>
  );
}

export default withRoomContext(WaitingRoom);
