import { uuidv4 } from './uuid';

const indexedDB =
  window.indexedDB ||
  window.webkitIndexedDB ||
  window.mozIndexedDB ||
  window.OIndexedDB ||
  window.msIndexedDB;
const IDBTransaction =
  window.IDBTransaction ||
  window.webkitIDBTransaction ||
  window.OIDBTransaction ||
  window.msIDBTransaction;
const dbVersion = 1.0;

let db;

function openDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('db', dbVersion);

    request.onerror = (event) => {
      reject(event.target.errorCode);
    };

    request.onsuccess = (event) => {
      db = event.target.result;
      resolve();
    };

    request.onupgradeneeded = (event) => {
      db = event.target.result;
      db.createObjectStore('store', { keyPath: 'key' });
    };
  });
}

export function storeImage(image, key = uuidv4()) {
  return new Promise(async (resolve, reject) => {
    if (!db) {
      await openDB();
    }
    if (!db) return resolve(null);
    const transaction = db.transaction(['store'], 'readwrite');
    const store = transaction.objectStore('store');
    const request = store.put({ key, value: image });

    request.onerror = (event) => {
      reject(event.target.errorCode);
    };

    request.onsuccess = (event) => {
      resolve(key);
    };
  });
}

export function removeImage(key) {
  return new Promise(async (resolve, reject) => {
    if (!db) {
      await openDB();
    }
    if (!db) return resolve(null);
    const transaction = db.transaction(['store'], 'readwrite');
    const store = transaction.objectStore('store');
    const request = store.delete(key);

    request.onerror = (event) => {
      reject(event.target.errorCode);
    };

    request.onsuccess = (event) => {
      resolve();
    };
  });
}

export function getImage(key) {
  return new Promise(async (resolve, reject) => {
    if (!db) {
      await openDB();
    }
    if (!db) return resolve(null);
    const transaction = db.transaction(['store'], 'readonly');
    const store = transaction.objectStore('store');
    const request = store.get(key);

    request.onerror = (event) => {
      reject(event.target.errorCode);
    };

    request.onsuccess = (event) => {
      resolve(request.result?.value);
    };
  });
}

export function getImages() {
  return new Promise(async (resolve, reject) => {
    if (!db) {
      await openDB();
    }
    if (!db) return resolve([]);
    const transaction = db.transaction(['store'], 'readonly');
    const store = transaction.objectStore('store');
    const request = store.getAll();

    request.onerror = (event) => {
      reject(event.target.errorCode);
    };

    request.onsuccess = (event) => {
      resolve(request.result);
    };
  });
}
