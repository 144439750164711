import React from 'react';
import classnames from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icons';
import NetworkStatusIcon from './NetworkStatusIcon';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import styles from './InfoBar.module.css';
import { withRoomContext } from '../../lib/RoomClientContext';

function InfoBar({
  roomId,
  peerId,
  raisedHand,
  userData,
  muted,
  pinned,
  isMobile,
  isAdmin,
  isSelf,
  isShare,
  shareFullscreen,
  setShareFullscreen,
  floatSelf,
  setFloatSelf,
  floatShare,
  setFloatShare,
  isAlone,
  minimized,
  setMinimized,
  onPin,
  onPeerMute,
  peerMuted,
  audioMuted,
  videoFit,
  onVideoFitChange,
  topAlign,
  networkStatus = -1,
  roomClient,
}) {
  const { t } = useTranslation();
  const fontSize = floatSelf ? 20 : 24;

  const renderMicIconOrButton = () => {
    const muteIcon = (
      <Icon
        name={(isSelf ? audioMuted : peerMuted) ? 'micOff' : 'micOn'}
        width={fontSize}
        height={fontSize}
        color={(isSelf ? audioMuted : peerMuted) ? 'white' : 'white'}
        className={styles.icon}
      />
    );

    if (isSelf && muted) {
      return <>{muteIcon}</>;
    }
    if (!isSelf && !isShare && !isAlone) {
      if (isAdmin) {
        return (
          <Tooltip title={t('Toggle microphone')}>
            <IconButton onClick={() => onPeerMute()} className={styles.button}>
              {muteIcon}
            </IconButton>
          </Tooltip>
        );
      } else {
        return <>{muteIcon}</>;
      }
    }
    return <></>;
  };

  async function forceLowerHand() {
    roomClient.send({ action: 'forceLowerHand', peerId });
  }

  return (
    <div
      className={classnames(styles.infoBar, {
        [styles.mobile]: isMobile,
        [styles.minimized]: minimized,
        [styles.topAlign]: topAlign,
      })}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <p className={styles.userName}>
        {isSelf
          ? isShare
            ? minimized
              ? t('Shared')
              : t('Shared screen')
            : t('You')
          : userData?.name || t('Unknown')}
      </p>
      {networkStatus > -1 && (
        <NetworkStatusIcon
          status={networkStatus}
          tooltip={t('Poor connection')}
        />
      )}
      {raisedHand && (!isAdmin || isSelf) && !floatSelf ? (
        <Icon
          name="raisedHand"
          color="white"
          className={styles.icon}
          width={fontSize}
          height={fontSize}
        />
      ) : null}
      {raisedHand && isAdmin && !isSelf ? (
        <Tooltip title={t('Lower hand')}>
          <IconButton
            className={styles.button}
            onClick={() => forceLowerHand()}
          >
            <Icon
              name="raisedHand"
              color="white"
              className={styles.icon}
              width={fontSize}
              height={fontSize}
            />
          </IconButton>
        </Tooltip>
      ) : null}
      {isShare && (!floatShare || !isSelf) ? (
        <Tooltip title={shareFullscreen ? t('Collapse') : t('Expand')}>
          <IconButton
            className={styles.button}
            onClick={() => {
              setShareFullscreen(!shareFullscreen);
            }}
          >
            <Icon
              name={shareFullscreen ? 'collapse' : 'expand'}
              width={fontSize}
              height={fontSize}
              color="white"
              className={styles.icon}
            />
          </IconButton>
        </Tooltip>
      ) : null}

      {!(!isAlone && floatSelf) && !isShare && !minimized ? (
        <Tooltip title={t('Pin')}>
          <IconButton
            className={styles.button}
            onClick={() => {
              onPin();
            }}
          >
            <Icon
              name={pinned ? 'pinned' : 'pin'}
              width={fontSize}
              height={fontSize}
              color={pinned ? 'white' : 'white'}
              className={styles.icon}
            />
          </IconButton>
        </Tooltip>
      ) : null}
      {!isAlone && isSelf ? (
        <Tooltip
          title={t((isShare ? floatShare : floatSelf) ? 'Move in' : 'Move out')}
        >
          <IconButton
            className={styles.button}
            onClick={(e) => {
              e.stopPropagation();
              if (!isShare) {
                if (floatSelf && minimized) {
                  setMinimized(false);
                }
                if (floatSelf) {
                  setShareFullscreen(false);
                }
                setFloatSelf(!floatSelf);
              } else {
                if (floatShare && minimized) {
                  setMinimized(false);
                }
                if (!floatShare) {
                  setShareFullscreen(false);
                }
                setFloatShare(!floatShare);
              }
            }}
          >
            <Icon
              name={
                (isShare ? floatShare : floatSelf)
                  ? 'thumbnailSolid'
                  : 'thumbnailOutlined'
              }
              width={fontSize}
              height={fontSize}
              className={styles.icon}
            />
          </IconButton>
        </Tooltip>
      ) : null}
      {renderMicIconOrButton()}
      {!minimized && !floatSelf && !isShare ? (
        <Tooltip
          title={videoFit === 'cover' ? t('Fit to frame') : t('Fill frame')}
        >
          <IconButton
            className={styles.button}
            style={{ width: 32, height: 32 }}
            onClick={() => {
              onVideoFitChange(videoFit === 'contain' ? 'cover' : 'contain');
            }}
          >
            <AspectRatioIcon color="white" sx={{ fontSize }} />
          </IconButton>
        </Tooltip>
      ) : null}
      {!isAlone && isSelf ? (
        <Tooltip title={t(minimized ? 'Show' : 'Hide')}>
          <IconButton
            className={styles.button}
            onClick={(e) => {
              e.stopPropagation();
              if (isShare && !floatShare && !minimized) {
                setFloatShare(true);
              } else if (!isShare && !floatSelf && !minimized) {
                setFloatSelf(true);
              }
              if (isShare && !minimized) {
                setShareFullscreen(false);
              }
              setMinimized(!minimized);
            }}
          >
            <Icon
              name={minimized ? 'caretUp' : 'caretDown'}
              width={30}
              height={30}
              className={styles.icon}
              style={{ paddingLeft: 0 }}
            />
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  );
}

export default withRoomContext(InfoBar);
