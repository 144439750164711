import React, { useEffect, useState, useRef, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './VideoRoom.module.css';
import VideoControls from '../components/video/VideoControls';
import RoomHeader from '../components/RoomHeader';
import Chat from '../components/chat/Chat';
import MembersList from '../components/memberslist/MembersList';
import NPSView from '../components/NPSView.js';
import SnackBar from '../components/SnackBar';
import RecordingHeader from '../components/video/RecordingHeader';
import { WebRTCIssueStatusProvider } from '../contexts/WebRTCIssueContext';
import logger from '../utils/logger';
import { createSnack } from '../actions/SnackActions';
import { track } from '../actions/TrackActions';
import { debounce } from '../utils/functions';
import { useSelector, useDispatch } from 'react-redux';
import { ALLOW_VIDEO_MANIPULATION } from '../utils/media';
import DisconnectedModal from '../components/video/DisconnectedModal';
import Sidebar from '../components/Sidebar';
import VideoPeers from './VideoPeers';
import {
  toggleMuted,
  toggleHideVideo,
  setMicLoading,
  setShowTalkingPopover,
} from '../store/slices/controlSlice';
import {
  setFloatSelf,
  setNumPages,
  setMaxHeight,
  setMaxWidth,
  setShowSidebars,
  setViewLayout,
} from '../store/slices/layoutSlice';

import { setPeersSortMap } from '../store/slices/memberSlice';
import { setMicSound, setShowSettings } from '../store/slices/deviceSlice';
import { ConnectionState, setConnectionState } from '../store/slices/roomSlice';
import { withRoomContext } from '../lib/RoomClientContext.js';

import { sleep } from '../utils/sleep.js';

import DuplicatePeerModal from '../components/video/DuplicatePeerModal.js';

function VideoRoom({ roomClient }) {
  const dispatch = useDispatch();
  const clientOptions = useSelector((state) => state.user.clientOptions);
  const token = useSelector((state) => state.user.token);
  const role = useSelector((state) => state.user.role);
  const userId = useSelector((state) => state.user.userId);
  const shareFullScreen = useSelector((state) => state.control.shareFullScreen);
  const hideVideo = useSelector((state) => state.control.hideVideo);
  const muted = useSelector((state) => state.control.muted);
  const viewLayout = useSelector((state) => state.layout.viewLayout);
  const dominantSpeaker = useSelector((state) => state.layout.dominantSpeaker);
  const floatShare = useSelector((state) => state.layout.floatShare);
  const floatSelf = useSelector((state) => state.layout.floatSelf);
  const pinned = useSelector((state) => state.layout.pinned);
  const page = useSelector((state) => state.layout.page);
  const showSidebars = useSelector((state) => state.layout.showSidebars);
  const showMemberList = showSidebars['left'];
  const showChat = showSidebars['right'];
  const members = useSelector((state) => state.member.members);
  const peersSortMap = useSelector((state) => state.member.peersSortMap);
  const raisedHands = useSelector((state) => state.member.raisedHands);
  const deviceSettings = useSelector((state) => state.device.deviceSettings);
  const showSettings = useSelector((state) => state.device.showSettings);
  const deviceSetup = useSelector((state) => state.device.deviceSetup);
  const connectionState = useSelector((state) => state.room.connectionState);
  const peers = useSelector((state) => state.room.peers);
  const joinedRoom = useSelector((state) => state.room.joinedRoom);
  const showNPS = useSelector((state) => state.room.showNPS);
  const shareData = useSelector((state) => state.room.shareData);
  const sharingScreen = useSelector((state) => state.room.sharingScreen);
  const maxWidth = useSelector((state) => state.layout.maxWidth);
  const maxHeight = useSelector((state) => state.layout.maxHeight);
  const isDuplicatePeer = useSelector((state) => state.room.isDuplicatePeer);
  const isRoomLockEnabled = useSelector(
    (state) => state.room.isRoomLockEnabled
  );
  const showTalkingPopover = useSelector(
    (state) => state.control.showTalkingPopover
  );

  const micSound = useSelector((state) => state.device.micSound);
  const roomData = useSelector((state) => state.room.roomData);
  const enableMetricsCollection = useSelector(
    (state) => state.room.enableMetricsCollection
  );

  const { roomId } = useParams();
  const [query, setQuery] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [lastShownTalkingNotification, setLastShownTalkingNotification] =
    useState(0);
  const [peerReconnecting, setPeerReconnecting] = useState(
    query.get('peerReconnecting') === 'true'
  );
  const [usersetFloatSelf, setUsersetFloatSelf] = useState(false);

  let talkingDetectorInterval;

  const isMobile = maxWidth <= 780 || maxHeight <= 520;

  // Moderator can do everything an admin can, except for seeing the lobby
  const isAdmin = role === 'admin' || role === 'moderator';

  const _setShowTalkingPopover = debounce((val) => {
    clearTimeout(roomClient.talkingTimeout);
    if (roomClient.triggeredTalkingPopover && val) {
      return;
    }
    if (val) {
      roomClient.triggeredTalkingPopover = true;
      dispatch(setShowTalkingPopover(val));
    } else {
      roomClient.talkingTimeout = setTimeout(() => {
        dispatch(setShowTalkingPopover(false));
      }, 5000);
    }
  }, 500);

  useEffect(() => {
    const v = roomClient.videoLayouts;
    if (!v) return;
    v.setSelfId(userId);
    v.setPins(pinned);
    v.setHands(raisedHands);
    v.setActiveSpeaker(dominantSpeaker?.peerId);
    v.setShareData(shareData);
    v.setFloatShare(floatShare);
    v.setFloatSelf(floatSelf);
    v.setPeers(members.filter((x) => x.joined && !x.isGhost).map((x) => x.id));
    v.setRenderSize(viewLayout === 'grid' ? (isMobile ? 6 : 9) : 4);
    v.createRenderList(page);
    dispatch(setNumPages(v.getNumPages()));
    dispatch(setPeersSortMap(v.getOrderMap()));
  }, [
    joinedRoom,
    isMobile,
    members,
    userId,
    pinned,
    dominantSpeaker,
    shareData,
    floatShare,
    floatSelf,
    raisedHands,
    viewLayout,
    page,
  ]);

  useEffect(() => {
    if (clientOptions?.disableChat) {
      setShowChat(false);
    }
    if (clientOptions?.disableMembersList) {
      setShowMemberList(false);
    }
    if (
      clientOptions?.disableVisualEffects &&
      roomClient.videoManipulator &&
      deviceSettings
    ) {
      roomClient.onNewDeviceSettings({
        ...deviceSettings,
        videoEffect: null,
      });
    }
  }, [clientOptions]);

  // const membersRef = useRef();
  useEffect(() => {
    if (roomClient.members.length) {
      const m = roomClient.members.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});

      const joined = members.reduce((acc, cur) => {
        if (!m[cur.id] && cur.id !== userId && cur.joined) {
          acc.push(cur);
        }
        return acc;
      }, []);

      const left = roomClient.members.reduce((acc, cur) => {
        const found = members.find((m) => m.id === cur.id);
        if ((!found || found.isGhost) && cur.id !== userId && !cur.isGhost) {
          acc.push(cur);
        }
        return acc;
      }, []);

      if (joined.length > 0) {
        if (joined.length === 1) {
          createSnack(
            dispatch,
            `${joined[0].userData?.name || t('Unknown')} ${t(
              'joined the room'
            )}`,
            'info'
          );
        } else {
          createSnack(
            dispatch,
            `${joined.length} ${t('users joined the room')}`,
            'info'
          );
        }
      }

      if (left.length > 0) {
        if (left.length === 1) {
          createSnack(
            dispatch,
            `${left[0].userData?.name || t('Unknown')} ${t('left the room')}`,
            'info'
          );
        } else {
          createSnack(
            dispatch,
            `${left.length} ${t('users left the room')}`,
            'info'
          );
        }
      }
    }
    roomClient.members = members;
  }, [members]);

  const checkForSinglePeer = debounce((numPeers) => {
    logger.log('number of peers left', numPeers);
    if (usersetFloatSelf) return;
    if (numPeers >= 1) {
      dispatch(setFloatSelf(true));
    }
  }, 100);

  function setShowChat(show) {
    dispatch(setShowSidebars({ side: 'right', show }));
  }

  function setShowMemberList(show) {
    dispatch(setShowSidebars({ side: 'left', show }));
  }

  useEffect(() => {
    if (muted) {
      _setShowTalkingPopover(micSound);
    }
  }, [micSound]);

  useEffect(() => {
    (async () => {
      if (!roomClient.audioProducer) return;
      dispatch(setMicLoading(true));
      await roomClient.toggleAudio();
      dispatch(setMicLoading(false));
      await track('audioMutedToggled', { muted }, token);
    })();
  }, [muted]);

  useEffect(() => {
    if (!deviceSetup) return;
    if (!joinedRoom) return;
    debounce(() => {
      roomClient.onToggleVideo();
    }, 250)();
  }, [hideVideo]);

  useEffect(() => {
    if (!muted) {
      talkingDetectorInterval = setInterval(async () => {
        if (
          !roomClient.talkingDetector ||
          !roomClient.talkingDetector.enabled ||
          !roomClient.talkingDetector.detector
        )
          return;
        let micVolume;
        dispatch(
          setMicSound((prevState) => {
            micVolume = prevState;
            return prevState;
          })
        );
        if (micVolume === false) {
          const isTalking = await roomClient.talkingDetector.update();
          if (isTalking) {
            const now = new Date();
            setLastShownTalkingNotification((prevState) => {
              if (now - prevState > 10000) {
                createSnack(
                  dispatch,
                  t('You seem to be talking, but we cant hear you')
                );
                return now;
              }
              return prevState;
            });
          }
        } else if (micVolume) {
          roomClient.talkingDetector.reset();
        }
      }, 200);
      return () => clearInterval(talkingDetectorInterval);
    }
  }, [muted]);

  const onResize = debounce(() => {
    dispatch(setMaxWidth(window.innerWidth));
    dispatch(setMaxHeight(window.innerHeight));
    if (window.innerWidth <= 768) {
      dispatch(setViewLayout('grid'));
      roomClient.preShareLayout = null;
    }
  });

  useEffect(() => {
    if (!enableMetricsCollection) return;
    const t = setInterval(async () => {
      const producerStats = await roomClient.producerTransport?.getStats();
      const outbounds = Array.from(producerStats.values()).filter(
        (x) => x.type === 'outbound-rtp'
      );
      const consumerStats = await roomClient.consumerTransport?.getStats();
      const inbounds = Array.from(consumerStats.values()).filter(
        (x) => x.type === 'inbound-rtp' && x.mid !== 'probator'
      );
      await track('rtp-stats', { inbounds, outbounds }, token);
    }, 10000);
    return () => {
      clearInterval(t);
    };
  }, [enableMetricsCollection]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  async function fetchToken() {
    const queryTokenId = query.get('tokenid');
    await roomClient.fetchToken({ queryTokenId, roomId, t, navigate });
  }

  async function configureToken() {
    await roomClient.configureToken(query, setQuery);
  }

  async function retryWithTimeoutAndDelay({
    action,
    timeLimit,
    successAction,
    failureAction,
    retryDelayMilliseconds,
  }) {
    const startTime = Date.now();

    while (true) {
      try {
        await action();
        // If the async function completes without error, execute the success action.
        successAction();
        break;
      } catch (error) {
        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;
        Sentry.captureException(
          new Error(`${error.message} (state: ${connectionState})`, {
            fingerprint: ['{{ default }}', error.message],
          })
        );

        if (elapsedTime >= timeLimit) {
          // If the time limit is exceeded, execute the failure action.
          failureAction();
          break;
        } else {
          // logger.error(error.message);
          Sentry.captureException(
            new Error(`${action.name} error: ${error.message}`)
          );
          // Sleep for the specified duration before the next retry.
          await sleep(retryDelayMilliseconds);
        }
      }
    }
  }

  useEffect(() => {
    // This useEffect handles the connection mechanism
    let timeLimit;
    switch (connectionState) {
      case ConnectionState.UNINITIALIZED:
        // Load token from sessionStorage
        // You only get one shot
        (async () => {
          try {
            await fetchToken();
            dispatch(setConnectionState(ConnectionState.INITIALIZED));
          } catch (err) {
            logger.error(err.message.toUpperCase());
            Sentry.captureException(err);
          }
        })();
        break;
      case ConnectionState.INITIALIZED:
        // Configure the connection by getting the token
        // You only get one shot
        (async () => {
          try {
            await configureToken();
            dispatch(setConnectionState(ConnectionState.CONFIGURED));
          } catch (err) {
            logger.error(err.message.toUpperCase());
            throw err;
          }
        })();
        break;
      case ConnectionState.RECONNECTING:
        // set peerReconnecting query parameter before reloading
        // query.set('peerReconnecting', 'true');
        // setQuery(query);
        // window.location.reload();
        break;
      case ConnectionState.CONFIGURED:
        // Connect the socket with a retry mechanism
        timeLimit = 30 * 1000; // time limit for retries
        retryWithTimeoutAndDelay({
          action: async () => await roomClient.initializeSocketConnection(),
          timeLimit,
          successAction: () => {
            dispatch(setConnectionState(ConnectionState.CONNECTED));
          },
          failureAction: () => {
            dispatch(setConnectionState(ConnectionState.FAILED));
          },
          retryDelayMilliseconds: 5000,
        });
        break;
      case ConnectionState.CONNECTED:
        if (!deviceSetup) return;
        timeLimit = 30 * 1000; // time limit for retries
        retryWithTimeoutAndDelay({
          action: async () => await roomClient.setupConnection(),
          timeLimit,
          successAction: () => {
            setPeerReconnecting(false);
          },
          failureAction: () => {
            dispatch(setConnectionState(ConnectionState.FAILED));
          },
          retryDelayMilliseconds: 5000,
        });
        break;

      case ConnectionState.FAILED:
        // stop trying to connect
        roomClient.close();
        break;
      default:
        logger.error('Unknown case connectionState', connectionState);
    }
  }, [connectionState, deviceSetup]);

  let peersCombined = useMemo(() => {
    logger.log('peers Object is', peers);
    let peersArray = [];
    Object.values(peers).forEach((peer) => {
      if (peer['audio']) {
        peersArray.push(peer['audio']);
      }
      if (peer['video']) {
        peersArray.push(peer['video']);
      }
    });
    logger.log('peersArray is', peersArray);
    const y = peersArray.reduce((acc, x) => {
      if (x.producer.id === shareData?.producerId) return acc;
      if (!acc[x.peerId]) {
        acc[x.peerId] = {
          id: x.peerId,
          producers: [],
          show: false,
        };
      }
      acc[x.peerId].producers.push(x);
      if (x.show) {
        acc[x.peerId].show = true;
      }
      return acc;
    }, {});

    (members || []).forEach((member) => {
      if (!y[member.id] && member.id !== userId && !member.isGhost) {
        y[member.id] = {
          id: member.id,
          producers: [],
          show: true,
        };
      }
    });

    return Object.values(y).sort((a, b) => {
      return peersSortMap[a.id] - peersSortMap[b.id];
    });
  }, [members, peers, shareData, floatShare]);

  useEffect(() => checkForSinglePeer(peersCombined.length), [peersCombined]);

  const useSwipeableDrawer = maxWidth <= 1050 || isMobile;
  const renderSize = roomClient.videoLayouts.renderSize;

  const numPages = Math.ceil(
    (peersCombined.length +
      (shareData && !floatShare ? 1 : 0) +
      (floatSelf ? 0 : 1)) /
      renderSize
  );

  const onUserConfirmsLeave = () => {
    roomClient.close();
  };

  useEffect(() => {
    if (roomClient.closed) {
      return;
    }

    const handleBeforeUnload = (e) => {
      const confirmationMessage =
        'Are you sure you want to leave? Changes you made may not be saved.';
      e.preventDefault();
      e.returnValue = confirmationMessage; // This is necessary for the confirmation dialog to appear

      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', onUserConfirmsLeave);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', onUserConfirmsLeave);
    };
  }, [roomClient.closed, onUserConfirmsLeave]);

  if (showNPS) {
    return (
      <div className={styles.container}>
        <NPSView onClose={() => roomClient.onNPSClose()} />
      </div>
    );
  }

  const allowVideoManipulation =
    (ALLOW_VIDEO_MANIPULATION || clientOptions?.forceVirtualBackgroundUrl) &&
    !clientOptions?.disableVisualEffects;

  if (connectionState === ConnectionState.RECONNECTING) {
    function reloadWindow() {
      window.location.reload();
    }
    return (
      <div className={styles.container}>
        {peerReconnecting ||
        connectionState === ConnectionState.RECONNECTING ? (
          <DisconnectedModal
            connectionFailure={connectionState === ConnectionState.RECONNECTING}
            onClick={reloadWindow}
          />
        ) : null}
      </div>
    );
  }

  if (isDuplicatePeer) {
    return (
      <div className={styles.container}>
        <DuplicatePeerModal />
      </div>
    );
  }
  logger.log('ConnectionState is', connectionState);
  return (
    <>
      <SnackBar leftCollapse={!useSwipeableDrawer && showMemberList} />
      <div className={styles.container}>
        <Helmet>
          <title>
            {t('Video Meeting')}
            {roomData?.name ? ` - ${roomData.name}` : ''}
          </title>
        </Helmet>
        {!clientOptions.disableMembersList && (
          <Sidebar useSwipeableDrawer={useSwipeableDrawer} anchor="left">
            <MembersList
              onPeerMute={(peer) => roomClient.onPeerMute(peer)}
              roomId={roomId}
              isAdmin={isAdmin}
              onRaiseHand={() => roomClient.onRaiseHand()}
              onClose={() => setShowMemberList(false)}
            />
          </Sidebar>
        )}
        <div
          className={classnames(styles.innerContainer, {
            [styles.leftCollapse]: !useSwipeableDrawer && showMemberList,
            [styles.rightCollapse]: !useSwipeableDrawer && showChat,
          })}
          style={{ height: maxHeight }}
        >
          <RoomHeader
            roomName={roomData?.name || (roomData?.useLobby ? roomId : null)}
            numPeers={peersCombined.length + 1}
            useLobby={role === 'admin' && roomData?.useLobby}
            onToggleMemberList={() => setShowMemberList(!showMemberList)}
            onToggleChat={() => setShowChat(!showChat)}
            showChat={showChat}
            showMemberList={showMemberList}
            hideRoomLock={
              !isRoomLockEnabled || !(role === 'admin' || role === 'moderator')
            }
          />
          <RecordingHeader
            showMemberList={showMemberList}
            isMobile={isMobile}
          />
          <VideoPeers
            numPages={numPages}
            roomId={roomId}
            isAdmin={isAdmin}
            isMobile={isMobile}
            setUsersetFloatSelf={setUsersetFloatSelf}
            peersCombined={peersCombined}
            renderSize={renderSize}
            allowVideoManipulation={allowVideoManipulation}
            onPeerMute={(peer) => roomClient.onPeerMute(peer)}
            onToggleMute={() => dispatch(toggleMuted())}
          />
          <VideoControls
            onToggleVideo={() => dispatch(toggleHideVideo())}
            onToggleMute={() => dispatch(toggleMuted())}
            onToggleScreenShare={roomClient.onToggleScreenShare}
            onRaiseHand={() => roomClient.onRaiseHand()}
            onSetViewLayout={(option) => {
              dispatch(setViewLayout(option));
              roomClient.preShareLayout = null;
            }}
            onToggleRecording={(details) =>
              roomClient.onToggleRecording(details)
            }
            isAdmin={isAdmin}
            isMobile={isMobile}
            allowVideoManipulation={allowVideoManipulation}
            device={roomClient.device}
            showSettings={showSettings}
            setShowSettings={(status) => dispatch(setShowSettings(status))}
            onCloseSettings={(newSettings) =>
              roomClient.onCloseSettings(newSettings)
            }
            showTalkingPopover={showTalkingPopover}
            setShowTalkingPopover={(status) =>
              dispatch(setShowTalkingPopover(status))
            }
            userData={(members || []).find((x) => x.id === userId)?.userData}
            onLeave={() => roomClient.onLeave()}
            numPages={numPages}
          />
        </div>
        {!clientOptions.disableChat && (
          <Sidebar useSwipeableDrawer={useSwipeableDrawer} anchor="right">
            <Chat
              open={showChat}
              roomId={roomId}
              onClose={() => setShowChat(false)}
            />
          </Sidebar>
        )}
      </div>
    </>
  );
}

const VideoRoomWrapper = ({ roomClient }) => {
  const { roomId } = useParams();

  return (
    <WebRTCIssueStatusProvider roomId={roomId} roomClient={roomClient}>
      <VideoRoom roomClient={roomClient} />
    </WebRTCIssueStatusProvider>
  );
};

export default withRoomContext(VideoRoomWrapper);
