import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './Card.module.css';

const CardLayout = ({ heading, description, children, actions }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Grid
      style={{
        minWidth: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      spacing={0}
      alignItems="center"
      justify="center"
    >
      <Card className={styles.card}>
        <CardContent style={{ padding: 0 }}>
          <h1>{heading}</h1>
          <hr
            style={{
              borderTop: '3px solid #bbb',
              borderColor: 'turquoise',
              width: '10%',
              marginLeft: 0,
            }}
          ></hr>
          <p style={{ lineHeight: '25px' }}>{description}</p>
          {children}
        </CardContent>
        <CardActions style={{ padding: 0 }}>
          <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
            {actions}
          </Stack>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default CardLayout;
