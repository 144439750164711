import { createContext, useContext, useEffect, useState } from 'react';
import WebRTCIssueDetector, { IssueReason } from 'webrtc-issue-detector';
import { useTranslation } from 'react-i18next';
import usePeerNetworkStatusListener from '../hooks/usePeerNetworkStatusListener';
import { useDispatch, useSelector } from 'react-redux';
import { createSnack } from '../actions/SnackActions';
import { track } from '../actions/TrackActions';
import logger from '../utils/logger';

const NETWORK_ERROR_LIMIT = 5;
const CPU_ERROR_LIMIT = 3;
const MAX_ELAPSED_TIME = 10000;

const init = {
  webRtcIssuesDetector: {
    network: 0,
    cpu: 0,
  },
  setWebRtcIssuesDetector: () => {},
  networkStatuses: {},
  setNetworkStatuses: () => {},
  reportNetworkStatus: () => {},
};

export const WebRTCIssueStatusContext = createContext(init);

export const useWebRTCStatus = () => useContext(WebRTCIssueStatusContext);

export const WebRTCIssueStatusProvider = ({ children, roomId, roomClient }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const networkStatuses = useSelector(
    (state) => state.monitoring.networkStatuses
  );
  const { t } = useTranslation();
  const { reportNetworkStatus } = usePeerNetworkStatusListener(
    roomId,
    roomClient
  );
  const [networkIssuesIndex, setNetworkIssuesIndex] = useState(0);
  const [networkIssuesTimestamps, setNetworkIssuesTimestamps] = useState(
    Array(NETWORK_ERROR_LIMIT).fill(0)
  );
  const [cpuIssuesIndex, setCpuIssuesIndex] = useState(0);
  const [cpuIssuesTimestamps, setCpuIssuesTimestamps] = useState(
    Array(CPU_ERROR_LIMIT).fill(0)
  );

  useEffect(() => {
    const webRtcIssueDetector = new WebRTCIssueDetector({
      onIssues: (issues) =>
        issues.forEach((issue) => {
          logger.log('GOT WEBRTC ISSUE', issue);
          logger.log('Issues type:', issue.type); // eg. "network"
          logger.log('Issues reason:', issue.reason); // eg. "outbound-network-throughput"
          logger.log('Issues reason:', issue.statsSample); // eg. "packetLossPct: 12%, avgJitter: 230, rtt: 150"

          const { type, reason } = issue;
          if (
            type === 'network' &&
            reason === IssueReason.OutboundNetworkQuality
          ) {
            recordNetworkIssue();
          } else if (type === 'cpu') {
            recordCpuIssue();
          }

          track('webrtc-issue', { issue }, token);
        }),
    });

    webRtcIssueDetector.watchNewPeerConnections();
    return () => {
      webRtcIssueDetector.stopWatchingNewPeerConnections();
    };
  }, []);

  function recordNetworkIssue() {
    setNetworkIssuesIndex((networkIssuesIndex + 1) % NETWORK_ERROR_LIMIT);
    setNetworkIssuesTimestamps(
      networkIssuesTimestamps.map((item, index) =>
        index === networkIssuesIndex ? new Date() : item
      )
    );
  }

  function recordCpuIssue() {
    setCpuIssuesIndex((cpuIssuesIndex + 1) % CPU_ERROR_LIMIT);
    setCpuIssuesTimestamps(
      cpuIssuesTimestamps.map((item, index) =>
        index === cpuIssuesIndex ? new Date() : item
      )
    );
  }

  function reportNetworkIssue() {
    createSnack(dispatch, t('Your network connection is shaky'), 'warning');
    reportNetworkStatus(1);
    setNetworkIssuesIndex(0);
    setNetworkIssuesTimestamps(Array(NETWORK_ERROR_LIMIT).fill(0));
  }

  function reportCpuIssue() {
    createSnack(dispatch, t('Your CPU is struggling'), 'warning');
    setCpuIssuesIndex(0);
    setCpuIssuesTimestamps(Array(CPU_ERROR_LIMIT).fill(0));
  }

  useEffect(() => {
    const now = new Date();
    const showNotification = networkIssuesTimestamps.every(
      (val) => now - val <= MAX_ELAPSED_TIME
    );
    if (showNotification) {
      reportNetworkIssue();
    }
  }, [networkIssuesTimestamps]);

  useEffect(() => {
    const now = new Date();
    const showNotification = cpuIssuesTimestamps.every(
      (val) => now - val <= MAX_ELAPSED_TIME
    );
    if (showNotification) {
      reportCpuIssue();
    }
  }, [cpuIssuesTimestamps]);

  const value = {
    networkStatuses,
    reportNetworkStatus,
  };
  return (
    <WebRTCIssueStatusContext.Provider value={value}>
      {children}
    </WebRTCIssueStatusContext.Provider>
  );
};
