import { createSlice } from '@reduxjs/toolkit';
import { getStorageItem } from '../../utils/storage';
import { checkSafari } from '../../utils/browserCheck';

const isSafari = checkSafari();

let mergedDeviceSettings;

const initialState = {
  audioManipulation: isSafari ? false : true,
};

// Retrieve stored device settings or default to an empty object
const storedDeviceSettings = getStorageItem(localStorage, 'deviceSettings', {});
// Merge initialState with storedDeviceSettings
if (isSafari) {
  mergedDeviceSettings = { ...storedDeviceSettings, ...initialState };
} else {
  mergedDeviceSettings = { ...initialState, ...storedDeviceSettings };
}

export const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    deviceSetup: false,
    deviceSettings: mergedDeviceSettings,
    showSettings: false,
    micSound: undefined,
  },
  reducers: {
    setDeviceSetup: (state, action) => {
      state.deviceSetup = action.payload;
    },
    setDeviceSettings: (state, action) => {
      state.deviceSettings = action.payload;
    },
    setShowSettings: (state, action) => {
      state.showSettings = action.payload;
    },
    setMicSound: (state, action) => {
      state.micSound = action.payload;
    },
  },
});

export const {
  setDeviceSetup,
  setDeviceSettings,
  setShowSettings,
  setMicSound,
} = deviceSlice.actions;

export default deviceSlice.reducer;
