import { getUserMedia } from '../utils/media';

function stopTracks(stream) {
  // Helper function that stops all tracks in a stream
  stream?.getTracks().forEach((track) => {
    track.stop();
  });
}

class StreamHandler {
  constructor() {
    this.audioDeviceProducing = undefined;
    this.videoDeviceProducing = undefined;
    this.audioStream = new Map();
    this.videoStream = new Map();
  }

  destroy() {
    // Destroy all streams
    this.cleanup(true);
  }

  cleanup(force = false) {
    // Cleanup all streams except the ones that are currently producing
    this.audioStream.forEach((stream, device) => {
      this.stopAudioTracks(device, force);
    });
    this.videoStream.forEach((stream, device) => {
      this.stopVideoTracks(device, force);
    });
  }

  async getDevices() {
    return await navigator.mediaDevices.enumerateDevices();
  }

  stopAudioTracks(device, force = false) {
    if (device !== this.audioStreamProducing || force) {
      stopTracks(this.audioStream.get(device));
      this.audioStream.delete(device);
    }
    if (device === this.audioStreamProducing && force) {
      this.audioStreamProducing = undefined;
    }
  }

  stopVideoTracks(device, force = false) {
    if (device !== this.videoStreamProducing || force) {
      stopTracks(this.videoStream.get(device));
      this.videoStream.delete(device);
    }
    if (device === this.videoStreamProducing && force) {
      this.videoStreamProducing = undefined;
    }
  }

  async getAudioStream({
    device,
    token,
    audioDevice,
    isMobile,
    throwError = false,
    producing = false,
  }) {
    if (producing) {
      // When changing the audio device for producing, stop streams of all other devices
      this.audioStream.forEach((stream, device) => {
        if (device !== audioDevice) {
          this.stopAudioTracks(device, true);
        }
      });
    }
    if (this.audioStream.get(audioDevice)) {
      if (producing) {
        this.audioStreamProducing = audioDevice;
      }
      return this.audioStream.get(audioDevice);
    }
    const stream = await getUserMedia(
      device,
      token,
      { audioDevice },
      { useVideo: false, isMobile },
      throwError
    );
    if (stream) {
      this.audioStream.set(audioDevice, stream);
      if (producing) {
        this.audioStreamProducing = audioDevice;
      }
    }
    return stream;
  }

  async getVideoStream({
    device,
    token,
    videoDevice,
    isMobile,
    throwError = false,
    producing = false,
  }) {
    if (producing) {
      // When changing the video device for producing, stop streams of all other devices
      this.videoStream.forEach((stream, device) => {
        if (device !== videoDevice) {
          this.stopVideoTracks(device, true);
        }
      });
    }
    if (this.videoStream.get(videoDevice)) {
      if (producing) {
        this.videoStreamProducing = videoDevice;
      }
      return this.videoStream.get(videoDevice);
    }
    const stream = await getUserMedia(
      device,
      token,
      { videoDevice },
      { useAudio: false, isMobile },
      throwError
    );
    if (stream) {
      this.videoStream.set(videoDevice, stream);
      if (producing) {
        this.videoStreamProducing = videoDevice;
      }
    }
    return stream;
  }

  async verifyPermission({ useAudio = true, useVideo = true }) {
    if (this.audioStream.size > 0 && this.videoStream.size > 0) {
      // If both audio and video streams are already available, permission must already have been granted
      return true;
    }
    if (this.audioStream.size > 0 && !useVideo) {
      // If audio stream is available and video permission is not requested, permission must already have been granted
      return true;
    }
    if (this.videoStream.size > 0 && !useAudio) {
      // If video stream is available and audio permission is not requested, permission must already have been granted
      return true;
    }
    const stream = await this._requestStream({ useAudio, useVideo });
    if (stream) {
      stopTracks(stream);
      return true;
    }
    return false;
  }

  async permissionsRequested(attempt = 0) {
    const devices = await this.getDevices();
    const noLabels = devices.every((d) => !d.label);
    if (noLabels && attempt < 5) {
      return new Promise((resolve) =>
        setTimeout(() => {
          resolve(this.permissionsRequested(attempt + 1));
        }, 100)
      );
    } else {
      return devices.every((d) => !!d.label || d.deviceId === 'default');
    }
  }

  async _requestStream({ useAudio, useVideo }) {
    // Simplified version of utils/media:getUserMedia() that does not
    // require a socket connection to resolve device object.

    const stream = await navigator.mediaDevices.getUserMedia({
      audio: useAudio,
      video: useVideo,
    });

    return stream;
  }
}

export default StreamHandler;
