import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './slices/chatSlice';
import lobbyReducer from './slices/lobbySlice';
import requestReducer from './slices/requestSlice';
import snackReducer from './slices/snackSlice';
import userReducer from './slices/userSlice';
import controlReducer from './slices/controlSlice';
import layoutReducer from './slices/layoutSlice';
import recordReducer from './slices/recordSlice';
import memberReducer from './slices/memberSlice';
import deviceReducer from './slices/deviceSlice';
import roomReducer from './slices/roomSlice';
import producerLayerReducer from './slices/producerLayerSlice';
import monitoringSlice from './slices/monitoringSlice';

export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          'room/setPeers',
          'room/addPeer',
          'room/setShareData',
          'room/addShareDataProducer',
        ],
        // Ignore these paths in the state
        ignoredPaths: ['room.peers', 'room.shareData'],
      },
    }),
  reducer: {
    chat: chatReducer,
    lobby: lobbyReducer,
    request: requestReducer,
    snack: snackReducer,
    user: userReducer,
    control: controlReducer,
    layout: layoutReducer,
    record: recordReducer,
    member: memberReducer,
    device: deviceReducer,
    room: roomReducer,
    producerLayer: producerLayerReducer,
    monitoring: monitoringSlice,
  },
});
