import React, { useRef, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './VideoSettingsDialog.module.css';
import { Icon } from '../../Icons';
import { handleGetUserMediaError } from '../../../utils/media';
import VideoManipulator from '../../../utils/VideoManipulator';
import AudioManipulator from '../../../utils/AudioManipulator';
import VideoSettingsVA from './VideoSettingsVA';
import VideoSettingsEffects from './VideoSettingsEffects';
import VideoSettingsLanguage from './VideoSettingsLanguage';
import AudioManipulatorSettings from './AudioManipulatorSettings';
import useAudio from '../../../hooks/useAudio';
import { useDispatch, useSelector } from 'react-redux';

import { stringAvatar } from '../../../utils/avatar';
import { withRoomContext } from '../../../lib/RoomClientContext';
import { on } from 'events';

function ToggleDivider({ open, onToggle, t }) {
  return (
    <div className={styles.toggleDivider} onClick={() => onToggle(!open)}>
      <div className={styles.divider} />
      <p className={styles.toggleText}>{t('More functions')}</p>
      <Icon
        className={styles.toggleIcon}
        name={open ? 'caretDown' : 'caretUp'}
        width={30}
        height={30}
      />
    </div>
  );
}

function VideoSettingsDialog({
  device,
  deviceSettings,
  open,
  onClose,
  allowVideoManipulation,
  isMobile,
  userData,
  useAspectRatio,
  roomClient,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    play: playTestAudio,
    stop: stopTestAudio,
    setSink: setTestSink,
  } = useAudio();
  const token = useSelector((state) => state.user.token);
  const deviceSetup = useSelector((state) => state.device.deviceSetup);
  const clientOptions = useSelector((state) => state.user.clientOptions);
  const hideVideo = useSelector((state) => state.control.hideVideo);
  const [videoDevice, setActiveVideoDevice] = useState(
    deviceSettings.videoDevice
  );
  const [audioDevice, setActiveAudioDevice] = useState(
    deviceSettings.audioDevice
  );
  const [outputDevice, setOutputDevice] = useState(
    deviceSettings.outputDevice || 'notsupported'
  );
  const [videoEffect, setVideoEffect] = useState(deviceSettings.videoEffect);
  const [checkedPermissions, setCheckedPermissions] = useState(false);
  const [videoDenied, setVideoDenied] = useState(false);
  const [audioDenied, setAudioDenied] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableVideoDevices, setAvailableVideoDevices] = useState([]);
  const [availableAudioDevices, setAvailableAudioDevices] = useState([]);
  const [availableOutputDevices, setAvailableOutputDevices] = useState([]);

  const [videoStream, setVideoStream] = useState(null);
  const [videoCanvas, setVideoCanvas] = useState(null);
  const videoManipulatorRef = useRef(null);
  const audioManipulatorRef = useRef(null);
  const [audioLevel, setAudioLevel] = useState(0);
  const audioManipulation = useSelector(
    (state) => state.device.deviceSettings.audioManipulation
  );

  const [cameraTaken, setCameraTaken] = useState(false);

  const showJoinAction = !deviceSetup;
  const usingForcedEffect = clientOptions?.forceVirtualBackgroundUrl;

  useEffect(() => {
    return () => {
      stopTestAudio();
    };
  }, []);

  useEffect(() => {
    if (open) {
      setLoading(false);
    } else {
      stopTestAudio();
    }
  }, [open]);

  useEffect(() => {
    if (clientOptions?.disableCamera) {
      setActiveVideoDevice('nocam');
    }
    if (clientOptions?.disableMicrophone) {
      setActiveAudioDevice('nomic');
    }
  }, [clientOptions]);

  useEffect(() => {
    if (!device) return;
    (async () => {
      if (await roomClient.streamHandler.permissionsRequested()) {
        setCheckedPermissions(true);
      }
    })();
  }, [device]);

  useEffect(() => {
    if (outputDevice === 'notsupported') {
      return;
    }
    stopTestAudio();
    setTestSink(outputDevice);
  }, [outputDevice]);

  useEffect(() => {
    if (!checkedPermissions || !open) return;
    let stream;
    (async () => {
      if (videoDevice === 'nocam') {
        if (videoManipulatorRef.current) {
          videoManipulatorRef.current.destroy();
          videoManipulatorRef.current = null;
        }
        setVideoStream(null);
        return;
      }
      try {
        stream = await roomClient.streamHandler.getVideoStream({
          device,
          token,
          videoDevice,
          isMobile,
          throwError: true,
        });

        if (stream) {
          videoManipulatorRef.current = new VideoManipulator(
            stream,
            !allowVideoManipulation
          );
          if (clientOptions?.forceVirtualBackgroundUrl) {
            videoManipulatorRef.current.setEffect(
              {
                type: 'virtual-background',
                image: clientOptions.forceVirtualBackgroundUrl,
              },
              dispatch
            );
          } else {
            videoManipulatorRef.current.setEffect(videoEffect, dispatch);
          }
          setVideoCanvas(videoManipulatorRef.current.getCanvas());
          const o = videoManipulatorRef.current.getOutputStream();
          o.force = true;
          setVideoStream(o);
        } else {
          setVideoDenied(true);
        }
      } catch (e) {
        handleGetUserMediaError(e, {
          onSystemRejection: () => setCameraTaken(true),
          onCameraTaken: () => setCameraTaken(true),
          onUserDenied: () => setVideoDenied(true),
        });
      }
    })();

    return () => {
      if (videoManipulatorRef.current) {
        videoManipulatorRef.current.destroy();
        videoManipulatorRef.current = null;
      }

      setVideoCanvas(null);
      setVideoStream(null);
    };
  }, [checkedPermissions, open, videoDevice, device, allowVideoManipulation]);

  useEffect(() => {
    if (!checkedPermissions || !open) return;
    let stream;
    (async () => {
      if (audioDevice === 'nomic') {
        if (audioManipulatorRef.current) {
          audioManipulatorRef.current.destroy();
          audioManipulatorRef.current = null;
        }
        return;
      }
      try {
        stream = await roomClient.streamHandler.getAudioStream({
          device,
          token,
          audioDevice,
          isMobile,
        });
        if (stream) {
          setAudioLevel(0);
          audioManipulatorRef.current = new AudioManipulator(
            stream,
            deviceSettings?.audioManipulation
          );
          await audioManipulatorRef.current.init();
          audioManipulatorRef.current.activateVad();
          audioManipulatorRef.current.on('level', (level) => {
            const newLevel = parseInt(level * 100, 10);
            setAudioLevel(newLevel);
          });
        } else {
          setAudioDenied(true);
        }
      } catch (e) {
        handleGetUserMediaError(e, {
          onUserDenied: () => setAudioDenied(true),
        });
      }
    })();

    return () => {
      if (audioManipulatorRef.current) {
        audioManipulatorRef.current.destroy();
        audioManipulatorRef.current = null;
      }
    };
  }, [checkedPermissions, open, audioDevice, device]);

  useEffect(() => {
    if (!checkedPermissions || usingForcedEffect) return;
    videoManipulatorRef.current?.setEffect(videoEffect, dispatch);
  }, [videoEffect, checkedPermissions, usingForcedEffect]);

  function onSave() {
    onClose({
      videoDevice,
      audioDevice,
      outputDevice,
      videoEffect,
      audioManipulation,
    });
    setLoading(true);
    roomClient.joinMeeting();
    roomClient.getUsersInQueue();
  }

  async function requestPermissions() {
    if (!device) return;
    let devices = await roomClient.streamHandler.getDevices();
    if (devices.every((d) => !d.label && d.deviceId !== 'default')) {
      // We don't have permissions, request it
      try {
        if (!(await roomClient.streamHandler.verifyPermission())) {
          setAudioDenied(true);
          setVideoDenied(true);
        }
      } catch (e) {
        handleGetUserMediaError(e, {
          onSystemRejection: () => setCameraTaken(true),
          onCameraTaken: () => setCameraTaken(true),
          onUserDenied: () => {
            setAudioDenied(true);
            setVideoDenied(true);
          },
        });
      }
    }
    setCheckedPermissions(true);
  }

  const onToggleAudioManipulation = () => {
    dispatch({
      type: 'device/setDeviceSettings',
      payload: {
        ...deviceSettings,
        audioManipulation: !audioManipulation,
      },
    });
  };

  const videoWidth = isMobile ? window.innerWidth : 600;
  const videoHeight = videoWidth / (16 / 9);

  useEffect(() => {
    if (!showJoinAction) return;
    // only relevant when not yet joined
    const handlePreferredDevice = (
      availableDevices,
      preferredDeviceInfo,
      onPreferred
    ) => {
      const preferredDevice = availableDevices.find(
        (device) =>
          device.deviceId === preferredDeviceInfo?.deviceId ||
          device.label === preferredDeviceInfo?.label
      );
      if (preferredDevice) onPreferred(preferredDevice.deviceId);
      return preferredDevice ? preferredDevice.deviceId : null;
    };
    const preferredDevices = {
      audio: handlePreferredDevice(
        availableAudioDevices,
        clientOptions?.devices?.audio,
        setActiveAudioDevice
      ),
      video: handlePreferredDevice(
        availableVideoDevices,
        clientOptions?.devices?.video,
        setActiveVideoDevice
      ),
      output: handlePreferredDevice(
        availableOutputDevices,
        clientOptions?.devices?.audioOutput,
        setOutputDevice
      ),
    };
    // if all preferred devices are set, join the meeting
    if (
      preferredDevices.audio &&
      preferredDevices.video &&
      preferredDevices.output
    ) {
      onSave();
    }
  }, [availableAudioDevices, availableOutputDevices, availableVideoDevices]);

  return (
    <Dialog
      PaperProps={{
        className: classnames(styles.dialog, { [styles.isMobile]: isMobile }),
      }}
      open={open}
      onClose={(e, reason) => {
        if (showJoinAction || loading) return false;
        onClose();
      }}
      disableEscapeKeyDown={showJoinAction || loading}
    >
      <DialogContent className={styles.dialogContent} dividers>
        <div className={styles.container} id="settings-dialog">
          <div className={styles.content}>
            <div className={styles.videoContainer}>
              {!videoDenied && cameraTaken ? (
                <div className={styles.cameraTakenText}>
                  <p>
                    {t(
                      'The camera seems to be used by a different application. Please close that application and press Try again on this modal.'
                    )}
                  </p>
                  <Button
                    variant="contained"
                    color="newgreen"
                    onClick={() => window.location.reload()}
                  >
                    {t('Try again')}
                  </Button>
                </div>
              ) : device &&
                !hideVideo &&
                videoCanvas &&
                !clientOptions.disableCamera ? (
                allowVideoManipulation ? (
                  <div
                    key="canvas"
                    id="video-preview"
                    ref={(ref) => {
                      if (ref) {
                        ref.innerHTML = '';
                        videoCanvas.style.width = '100%';
                        videoCanvas.style.height = '100%';
                        videoCanvas.style.background = '#333';
                        videoCanvas.style.objectFit = useAspectRatio
                          ? 'contain'
                          : 'cover';
                        if (!clientOptions?.disableMirroredCamera) {
                          videoCanvas.style.transform = 'scaleX(-1)';
                        }
                        videoCanvas.style['max-height'] = '280px';
                        ref.appendChild(videoCanvas);
                      }
                    }}
                  />
                ) : (
                  <video
                    ref={(ref) => {
                      if (
                        ref &&
                        ((!ref.srcObject && videoStream) ||
                          (ref.srcObject && !videoStream) ||
                          videoStream?.force)
                      ) {
                        ref.srcObject = videoStream;
                        ref.style.width = '100%';
                        ref.style.height = '100%';
                        ref.style.background = '#333';
                        ref.style.objectFit = useAspectRatio
                          ? 'contain'
                          : 'cover';
                        if (!clientOptions?.disableMirroredCamera) {
                          ref.style.transform = 'scaleX(-1)';
                        }
                        ref.style['max-height'] = '280px';
                        if (videoStream?.force) videoStream.force = false;
                      }
                    }}
                    playsInline
                    autoPlay
                    controls={false}
                    muted
                  />
                )
              ) : (
                <div
                  key="avatar"
                  className={styles.avatarContainer}
                  style={{ minHeight: Math.min(280, videoHeight) }}
                >
                  <Avatar
                    {...stringAvatar(userData?.name, {
                      width: 100,
                      height: 100,
                    })}
                    className={styles.avatar}
                  />
                </div>
              )}
            </div>
            <div className={styles.scrollable}>
              <h3 className={styles.sectionTitle}>{t('Settings')}</h3>
              <VideoSettingsVA
                videoDevice={videoDevice}
                setVideoDevice={setActiveVideoDevice}
                audioDevice={audioDevice}
                setAudioDevice={setActiveAudioDevice}
                outputDevice={outputDevice}
                setOutputDevice={setOutputDevice}
                deviceSettings={deviceSettings}
                device={device}
                requestPermissions={requestPermissions}
                checkedPermissions={checkedPermissions}
                permissionsDenied={{ audio: audioDenied, video: videoDenied }}
                cameraTaken={cameraTaken}
                clientOptions={clientOptions}
                playTestAudio={playTestAudio}
                stopTestAudio={stopTestAudio}
                audioLevel={audioLevel}
                streamHandler={roomClient.streamHandler}
                availableVideoDevices={availableVideoDevices}
                setAvailableVideoDevices={setAvailableVideoDevices}
                availableAudioDevices={availableAudioDevices}
                setAvailableAudioDevices={setAvailableAudioDevices}
                availableOutputDevices={availableOutputDevices}
                setAvailableOutputDevices={setAvailableOutputDevices}
              />
              <VideoSettingsLanguage />
              <ToggleDivider
                onToggle={setShowAdvanced}
                open={showAdvanced}
                t={t}
              />
              {showAdvanced ? (
                <>
                  {/* <div className={styles.divider} /> */}
                  <VideoSettingsEffects
                    videoEffect={videoEffect}
                    setVideoEffect={setVideoEffect}
                    isMobile={isMobile}
                    allowVideoManipulation={allowVideoManipulation}
                    usingForcedEffect={usingForcedEffect}
                  />
                  {/* {showJoinAction ? (
                    <>
                      <div className={styles.divider} />
                      <h3 className={styles.sectionTitle}>
                        {t('Advanced settings')}
                      </h3>

                      <AudioManipulatorSettings
                        onToggleAudioManipulation={onToggleAudioManipulation}
                      />
                    </>
                  ) : null} */}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </DialogContent>
      {loading ? (
        <DialogActions
          className={classnames(styles.actions, styles.singleAction)}
        >
          <Button
            color="white"
            variant="contained"
            className={styles.button}
            disableRipple
            disableFocusRipple
            id="video-settings-loading"
          >
            <CircularProgress size={30} />
          </Button>
        </DialogActions>
      ) : showJoinAction ? (
        <DialogActions
          className={classnames(styles.actions, styles.singleAction)}
        >
          <Button
            color="white"
            variant="contained"
            className={styles.button}
            onClick={() => onSave()}
            id="video-settings-save"
          >
            {t('Join Meeting')}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions className={styles.actions}>
          <Button
            color="white"
            className={styles.button}
            onClick={() => onClose()}
            id="video-settings-cancel"
          >
            {t('Cancel')}
          </Button>
          <Button
            color="white"
            variant="contained"
            className={styles.button}
            onClick={() => onSave()}
            id="video-settings-save"
          >
            {t('Save')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default withRoomContext(VideoSettingsDialog);
