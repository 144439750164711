import { addSnack } from '../store/slices/snackSlice';

const supportsNotifications = 'Notification' in window;

export async function createSnack(
  dispatch,
  msg,
  type = 'info',
  { allowNotification = false, notificationText, ...options } = {}
) {
  dispatch(addSnack({ msg, type, options }));
  const inFocus = document.hasFocus();
  if (
    allowNotification &&
    supportsNotifications &&
    Notification.permission === 'granted' &&
    !inFocus
  ) {
    const note = new Notification(notificationText || msg, { silent: true });
    note.onclick = () => {
      window.focus();
    };
  }
}

export function requestNotificationPermission() {
  if (supportsNotifications && Notification.permission !== 'granted') {
    Notification.requestPermission();
  }
}
