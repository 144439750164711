import { getStorageItem } from '../storage';

export function getDeviceSettings() {
  return getStorageItem(localStorage, 'deviceSettings', {});
}

export function getDevice(devices, type) {
  const storedDeviceSettings = getDeviceSettings();
  const consideredDevice =
    type === 'audioInput'
      ? storedDeviceSettings.audioInput
      : storedDeviceSettings.videoInput;
  if (
    consideredDevice &&
    devices.some(
      (device) => device.deviceId === consideredDevice && device.kind === type
    )
  ) {
    return consideredDevice;
  }
  return devices.find((device) => device.kind === type)?.deviceId;
}
