import { useEffect, useState } from 'react';
import sound from '../assets/newMeeting.mp3';
const audio = new Audio(sound);
function useAudio() {
  const [playing, setPlaying] = useState(false);
  const [sink, setSink] = useState(null);

  const play = () => setPlaying(true);
  const stop = () => setPlaying(false);

  useEffect(() => {
    if (playing) {
      if (sink && audio.setSinkId) {
        audio.setSinkId(sink);
      }
      audio.currentTime = 0;
      audio.play();
    } else {
      audio.pause();
    }
  }, [playing]);

  return { playing, play, stop, setSink };
}

export default useAudio;
