import { createRoom } from '../../actions/RoomActions';

export async function createRoomCheck(token, roomId) {
  try {
    await createRoom(roomId, token);
    return true;
  } catch (err) {
    const { status, data } = err?.response || {};
    if (status === 409 && data?.message === 'Room already exists') return true;
    return false;
  }
}

export function joinRoomPromise(roomClient, roomId) {
  return new Promise((resolve, reject) => {
    roomClient.request('joinRoom', roomId).then((joinedRes) => {
      if (!joinedRes.ok) {
        reject(joinedRes); // Reject the Promise in case of an error
      }
      resolve(joinedRes); // Resolve the Promise with joinedRes
    });
  });
}
