import vad from 'voice-activity-detection';
import EventEmitter from './EventEmitter';
import logger from './logger';

class AudioManipulator extends EventEmitter {
  constructor(stream, applyAudioProcessing) {
    super();
    this.inputStream = stream;
    this.applyAudioProcessing = applyAudioProcessing;
  }

  async init() {
    const context = new AudioContext({
      latencyHint: 'interactive',
    });
    this.context = context;

    const isStereo =
      this.inputStream.getAudioTracks()[0].getSettings().channelCount > 1;
    let workletNode = context.createGain();
    workletNode.gain.value = 1;
    logger.log('The audio is stereo:', isStereo);
    const source = context.createMediaStreamSource(this.inputStream);
    this.gainNode = context.createGain();
    this.gainNode.gain.value = 0.8;
    this.destination = context.createMediaStreamDestination();
    this.outputStream = this.destination.stream;
    source.connect(this.gainNode);
    this.gainNode.connect(this.destination);
    this.vadContext = null;
    this.vadDestroy = null;
  }

  setInputStream(stream) {
    this.inputStream = stream;
    if (this.vadDestroy) {
      this.deactivateVad();
      this.activateVad();
    }
  }

  mute() {
    this.outputStream.getTracks().forEach((track) => (track.enabled = false));
    this.gainNode.gain.value = 0;
  }

  unmute() {
    this.outputStream.getTracks().forEach((track) => (track.enabled = true));
    this.gainNode.gain.value = 1;
  }

  activateVad() {
    if (!this.vadDestroy) {
      const context = new AudioContext({
        latencyHint: 'interactive',
      });
      const { destroy } = vad(context, this.inputStream, {
        minNoiseLevel: 0.5,
        maxNoiseLevel: 0.7,
        onVoiceStart: () => {
          this.emit('voice', true);
        },
        onVoiceStop: () => {
          this.emit('voice', false);
        },
        onUpdate: (val) => {
          this.emit('level', val);
        },
      });
      this.vadContext = context;
      this.vadDestroy = destroy;
    }
  }

  deactivateVad() {
    if (this.vadDestroy) {
      this.vadContext.close();
      this.vadDestroy();
      this.vadDestroy = null;
    }
  }

  getOutputStream() {
    return this.outputStream;
  }

  destroy() {
    this.deactivateVad();

    this.removeAllListeners();
    this.inputStream = null;

    if (this.outputStream) {
      this.outputStream = null;
    }

    if (this.context) {
      this.context.close();
      this.gainNode = null;
      this.context = null;
    }

    this.vadDestroy = null;
  }
}

export default AudioManipulator;
