export function debounce(f, t = 200) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      f(...args);
    }, t);
  };
}

export function checkReload() {
  // checks if page is rendered due to reload
  const navigationEntries = performance.getEntriesByType('navigation');
  if (navigationEntries.length > 0) {
    const navigationType = navigationEntries[0].type;
    if (navigationType === 'reload') {
      return true;
    }
  }

  const url = window.location.href;
  const parsedUrl = new URL(url);
  const param = 'reload';
  // Check if URL contains hash
  if (parsedUrl.hash) {
    const [path, queryParams] = parsedUrl.hash.substring(1).split('?');
    if (queryParams) {
      const hashParams = new URLSearchParams(queryParams);
      if (hashParams.has(param) && hashParams.get(param) === 'true') {
        // Remove the reload parameter from the hash
        hashParams.delete(param);
        parsedUrl.hash = hashParams.toString()
          ? `#${path}?${hashParams.toString()}`
          : `#${path}`;
        window.history.replaceState({}, '', parsedUrl.toString());
        return true;
      }
    }
  } else {
    // URL does not contain hash, check the search parameters
    const urlParams = new URLSearchParams(parsedUrl.search);
    if (urlParams.has(param) && urlParams.get(param) === 'true') {
      // Remove the reload parameter from the search
      urlParams.delete(param);
      parsedUrl.search = urlParams.toString();
      window.history.replaceState({}, '', parsedUrl.toString());
      return true;
    }
  }

  return false;
}
