import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import {
  SignalCellular0Bar as NoBars,
  SignalCellular1Bar as OneBar,
  SignalCellular2Bar as TwoBars,
  SignalCellular3Bar as ThreeBars,
  SignalCellular4Bar as FourBars,
} from '@mui/icons-material';

export default function NetworkStatusIcon({
  status = 4,
  showFour = false,
  tooltip,
}) {
  const { t } = useTranslation();
  const Icon = useCallback(() => {
    if (status === 1) return <OneBar color="warning" />;
    else if (status === 2) return <TwoBars color="warning" />;
    else if (status === 3) return <ThreeBars color="success" />;
    else if (status === 4) return showFour && <FourBars color="success" />;
    else return <NoBars color="error" />;
  }, [status, showFour]);

  return (
    <Tooltip title={tooltip || t('Network status')}>
      <div>
        <Icon />
      </div>
    </Tooltip>
  );
}
