import StreamHandler from '../../lib/StreamHandler';

class StreamManager {
  constructor(streamHandler) {
    const width = window.innerWidth;
    const height = window.innerHeight;
    this.isMobile = width <= 780 || height <= 520;
    this.streamHandler = streamHandler;
  }

  async checkPermission() {
    const permission = await this.streamHandler.verifyPermission({
      useAudio: true,
      useVideo: true,
    });
    return permission;
  }

  async getAudioStream(device, token, audioDevice) {
    const stream = await this.streamHandler.getAudioStream({
      device,
      token,
      audioDevice,
      isMobile: this.isMobile,
      throwError: true,
    });
    return stream;
  }

  async getVideoStream(device, token, videoDevice) {
    const stream = await this.streamHandler.getVideoStream({
      device,
      token,
      videoDevice,
      isMobile: this.isMobile,
      throwError: true,
    });
    return stream;
  }
}

export default StreamManager;
