import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Control from './Control';

export default function MuteSelector({
  showTalkingPopover,
  setShowTalkingPopover,
  isMobile,
  nomic,
  muted,
  onToggleMute,
}) {
  const { t } = useTranslation();
  const popoverRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAnchorEl(popoverRef.current);
  }, [showTalkingPopover]);

  return (
    <>
      <Popover
        open={showTalkingPopover}
        onClose={() => setShowTalkingPopover(false)}
        anchorEl={anchorEl}
        disableAutoFocus={true}
        aria-live="polite"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography sx={{ p: 2 }}>
          {t('Your microphone is turned off')}
        </Typography>
      </Popover>

      <Control
        isMobile={isMobile}
        label={t('Toggle microphone')}
        ariaLabel="mic-control"
        onClick={() => onToggleMute()}
        icon={nomic || muted ? 'micOff' : 'micOn'}
        ref={popoverRef}
        disabled={nomic}
      />
    </>
  );
}
