import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography as Text } from '@mui/material';
import Dialog from './Dialog';
import { handleGetUserMediaError } from '../../utils/media';
import logger from '../../utils/logger';

function CheckPermissionsDialog({
  open,
  onClose,
  isMobile,
  onDenied,
  onDismissed,
  onCameraTaken,
  onSystemRejection,
  streamHandler,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (await streamHandler.permissionsRequested()) {
        onClose();
      }
    })();
  }, [onClose]);

  async function requestPermissions({ useAudio = true, useVideo = true }) {
    /**
    If no devices can be found getUserMedia throws an exception.
    This means that if no video device is found, the request for permission to
    access the microphone is not processed either (and vice versa).
 
    Hence, we need a fall back to sequentially ask for video and camera access separately.
    */
    if (!(await streamHandler.permissionsRequested())) {
      try {
        await streamHandler.verifyPermission({
          useAudio,
          useVideo,
        });
      } catch (e) {
        logger.error(e);
        handleGetUserMediaError(e, {
          onSystemRejection,
          onUserDenied: onDenied,
          onUserDismissed: onDismissed,
          onCameraTaken: onCameraTaken,
          catchAll: onDismissed,
        });
        if (e.message === 'Permission denied') {
          return onDenied();
        } else if (e.message === 'Permission dismissed') {
          return onDismissed();
        }
        if (
          useAudio &&
          useVideo &&
          e.message === 'Requested device not found'
        ) {
          // Ask for audio and video separately in sequence
          await requestPermissions({ useAudio: true, useVideo: false });
          await requestPermissions({ useAudio: false, useVideo: true });
        }
      }
    }
    if (useAudio && useVideo) onClose();
  }

  return (
    <Dialog
      open={open}
      title={t('Grant access to camera and microphone')}
      actions={
        <>
          <Button
            variant="contained"
            color="newgreen"
            onClick={requestPermissions}
            id="request-permissions"
          >
            {t('Request permissions')}
          </Button>
          <Button
            variant="outlined"
            color="newgreen"
            onClick={onClose}
            id="skip-permissions"
          >
            {t('Continue without devices')}
          </Button>
        </>
      }
    >
      <Text>
        {t(
          'Before you join the meeting you need to grant the website access to your camera and microphone so that the other meeting participants can see and hear you.'
        )}
      </Text>
    </Dialog>
  );
}

export default CheckPermissionsDialog;
