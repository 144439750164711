import ChromeIcon from '../assets/icons/icon_chrome.svg';
import EdgeIcon from '../assets/icons/icon_edge.svg';
import SafariIcon from '../assets/icons/icon_safari.svg';
import FirefoxIcon from '../assets/icons/icon_firefox.svg';
import OperaIcon from '../assets/icons/icon_opera.svg';

export const browsers = [
  { name: 'Chrome', minVersion: '110', icon: ChromeIcon, info: 'Recommended' },
  { name: 'Edge', minVersion: '110', icon: EdgeIcon, info: 'Recommended' },
  {
    name: 'Safari',
    minVersion: '14',
    icon: SafariIcon,
    info: 'Limited support',
  },
  { name: 'Firefox', minVersion: '110', icon: FirefoxIcon },
  { name: 'Opera', minVersion: '104', icon: OperaIcon },
];
