import { useState, useEffect, Fragment } from 'react';
import Card from '../../components/preflight/Card';
import { DataRow } from '../../components/preflight/DataRow';
import { getAllChecks } from '../../utils/preflight/checks';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from '@mui/material/Button';
import { withRoomContext } from '../../lib/RoomClientContext';

const Preflight = ({ roomClient }) => {
  const [tokenStatus, setTokenStatus] = useState(undefined);
  const [socketStatus, setSocketStatus] = useState(undefined);
  const [permission, setPermission] = useState(undefined);
  const [streamStatus, setStreamStatus] = useState(undefined);

  const [testComplete, setTestComplete] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [errors, setErrors] = useState([]);

  const returnOrClose = () => {
    let func;
    let text;
    if (window.history.length > 1) {
      func = () => window.history.back();
      text = 'Return';
    } else {
      func = () => window.close();
      text = 'Close';
    }
    return (
      <Button size="large" variant="contained" color="primary" onClick={func}>
        {text}
      </Button>
    );
  };

  const checksList = [
    {
      test: 'Validate https connection',
      status: tokenStatus,
    },
    {
      test: 'Validate websocket connection',
      status: socketStatus,
    },
    {
      test: 'Validate microphone and camera access',
      status: permission,
    },
    {
      test: 'Validate audio & video stream',
      status: streamStatus,
    },
  ];

  useEffect(() => {
    const checks = getAllChecks({
      setTokenStatus,
      setSocketStatus,
      setPermission,
      setStreamStatus,
    });

    (async () => {
      let state = {};

      const encounteredErrors = [];

      for (const check of checks) {
        const {
          status,
          state: newState,
          error,
        } = await check.run(state, roomClient);
        check.setStatus(status);
        if (newState) {
          state = { ...state, ...newState };
        }
        if (error) {
          encounteredErrors.push(error);
        }
      }

      // all tests done, we can close the roomClient
      // roomClient.close();
      setTestComplete(true);
      // remove duplicates of encounteredErrors
      const uniqueErrors = Array.from(
        new Set(encounteredErrors.map((a) => a.title))
      ).map((title) => {
        return encounteredErrors.find((a) => a.title === title);
      });
      setErrors(uniqueErrors);
    })();
  }, []);

  const heading = testComplete ? 'Test complete' : 'Test in progress';
  let description;
  if (!testComplete) description = 'Please wait until test result is shown';
  else if (showReport) {
    description =
      errors.length > 0
        ? 'Test shows that you might experience issues, see how you can improve your meeting experience below:'
        : 'Tests complete, all checks passed as expected.';
  }

  return (
    <Card
      heading={heading}
      description={description}
      actions={
        <>
          {testComplete && !showReport && (
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => setShowReport(true)}
            >
              Show report
            </Button>
          )}
          {showReport && errors.length > 0 && (
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
            >
              Retake test
            </Button>
          )}
          {testComplete && returnOrClose()}
        </>
      }
    >
      <table style={{ marginBottom: '1em' }}>
        <tbody>
          {showReport && errors && (
            <>
              {errors.map((error, index) => (
                <Fragment key={index}>
                  <tr>
                    <td>
                      <ErrorOutlineIcon color={error.color} fontSize="large" />
                    </td>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>{error.title}</span>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <span>{error.description}</span>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </>
          )}

          {!showReport &&
            checksList.map((row, index) => (
              <DataRow key={index} test={row.test} status={row.status} />
            ))}
        </tbody>
      </table>
    </Card>
  );
};

const PreflightWrapper = ({ roomClient }) => {
  return <Preflight roomClient={roomClient} />;
};

export default withRoomContext(PreflightWrapper);
