import { createSlice } from '@reduxjs/toolkit';
import { getStorageItem, setStorageItem } from '../../utils/storage';

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    showSidebars: {
      left: getStorageItem(localStorage, 'left', null, false) === 'true',
      right: getStorageItem(localStorage, 'right', null, false) === 'true',
    },
    viewLayout: 'grid',
    dominantSpeaker: null,
    floatShare: true,
    floatSelf: true,
    pinned: {},
    page: 1,
    numPages: 1,
    maxWidth: window.innerWidth,
    maxHeight: window.innerHeight,
  },
  reducers: {
    setShowSidebars: (state, action) => {
      state.showSidebars = {
        ...state.showSidebars,
        [action.payload.side]: action.payload.show,
      };
      setStorageItem(
        localStorage,
        action.payload.side,
        action.payload.show,
        false
      );
    },
    setViewLayout: (state, action) => {
      state.viewLayout = action.payload;
    },
    setDominantSpeaker: (state, action) => {
      state.dominantSpeaker = action.payload;
    },
    setFloatShare: (state, action) => {
      state.floatShare = action.payload;
    },
    setFloatSelf: (state, action) => {
      state.floatSelf = action.payload;
    },
    setPinned: (state, action) => {
      state.pinned = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setNumPages: (state, action) => {
      state.numPages = action.payload;
    },
    setMaxWidth: (state, action) => {
      state.maxWidth = action.payload;
    },
    setMaxHeight: (state, action) => {
      state.maxHeight = action.payload;
    },
  },
});

export const {
  setShowSidebars,
  setViewLayout,
  setDominantSpeaker,
  setFloatShare,
  setFloatSelf,
  setPinned,
  setPage,
  setNumPages,
  setMaxHeight,
  setMaxWidth,
} = layoutSlice.actions;

export default layoutSlice.reducer;
