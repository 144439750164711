import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { languages } from './lang';

i18n.use(detector).use(initReactI18next).init({
  resources: languages,
  fallbackLng: 'sv',
  saveMissing: true,
});

export default i18n;
