import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import styles from './RecordingHeader.module.css';
import { useSelector } from 'react-redux';

export default function RecordingHeader({ showMemberList, isMobile }) {
  const [duration, setDuration] = useState(0);
  const recording = useSelector((state) => state.record.recording);
  const recordingStartTime = useSelector(
    (state) => state.record.recordingStartTime
  );
  useEffect(() => {
    if (!recording) {
      setDuration(0);
      return;
    }
    setDuration(Date.now() - recordingStartTime);
    const interval = setInterval(() => {
      setDuration(Date.now() - recordingStartTime);
    }, 1000);
    return () => clearInterval(interval);
  }, [recording, recordingStartTime]);

  if (!recording) return null;
  const hours = Math.floor(duration / 1000 / 60 / 60);
  const minutes = Math.floor(duration / 1000 / 60) - hours * 60;
  const seconds = Math.floor(duration / 1000) - hours * 60 * 60 - minutes * 60;
  return (
    <div
      className={classnames(styles.container, {
        [styles.hours]: hours > 0,
        [styles.isMobile]: isMobile,
        [styles.showMemberList]: showMemberList,
      })}
    >
      <RadioButtonCheckedIcon className={styles.icon} color="danger" />
      <p className={styles.text}>
        {hours > 0 ? `${hours}:` : ''}
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </p>
    </div>
  );
}
