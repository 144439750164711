import React from 'react';
import { Pagination as MuiPagination, PaginationItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setPage } from '../../store/slices/layoutSlice';

export default function Pagination({ count, isMobile }) {
  const dispatch = useDispatch();

  return (
    <MuiPagination
      count={count}
      siblingCount={1}
      boundaryCount={0}
      color="primary"
      onChange={(_, page) => {
        dispatch(setPage(page));
      }}
      renderItem={(item) => {
        if (
          item.type !== 'start-ellipsis' &&
          item.type !== 'end-ellipsis' &&
          isMobile
            ? item.type !== 'page'
            : item.type !== 'page' || item.selected
        ) {
          return (
            <PaginationItem
              {...item}
              style={{ ...(!item.selected && { backgroundColor: 'white' }) }}
            />
          );
        }
      }}
    />
  );
}
