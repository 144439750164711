export const VIDEO_SIZE = {
  width: 1280,
  height: 720,
};

export const VIDEO_MOBILE_SIZE = {
  width: 853,
  height: 480,
};

export const SHARE_VIDEO_SIZE = {
  width: 1920,
  height: 1080,
};

export const SHARE_VIDEO_MOBILE_SIZE = {
  width: 1280,
  height: 720,
};

export const VIDEO_BITRATE = [75000, 250000, 800000];

export const SHARE_VIDEO_BITRATE = [800000, 1500000];
