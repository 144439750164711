export default {
  log: function (...args) {
    if (window.DEBUG || process.env.NODE_ENV === 'development') {
      console.log(...args);
    }
  },
  error: function (...args) {
    if (window.DEBUG || process.env.NODE_ENV === 'development') {
      console.error(...args);
    }
  },
};
