import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import MuiButton from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import TrashIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import styles from './VideoSettingsEffects.module.css';
import { useDispatch } from 'react-redux';
import { createSnack } from '../../../actions/SnackActions';

import { removeImage, storeImage, getImages } from '../../../utils/db';

const virtualBackgrounds = [
  '/virtual-backgrounds/day.png',
  '/virtual-backgrounds/dusk.png',
  '/virtual-backgrounds/night.png',
  '/virtual-backgrounds/sunrise.png',
  '/virtual-backgrounds/sunset.png',
  '/virtual-backgrounds/twillight.png',
];

function capitalize(s) {
  if (!s.length) return s;
  return s[0].toUpperCase() + s.slice(1);
}

function getVirtualBackgroundName(url) {
  if (!url.length) return url;
  return capitalize(url.split('/').pop().split('.')[0]);
}

function Button({ children, startIcon, label, selected, ...props }) {
  return (
    <div className={classnames(styles.buttonContainer, props.className)}>
      <FormControlLabel
        className={styles.label}
        value="start"
        control={
          <MuiButton
            variant="outlined"
            {...props}
            className={classnames(styles.iconButton, {
              [styles.buttonHover]: selected,
            })}
          >
            {startIcon || children}
          </MuiButton>
        }
        label={label || children}
        labelPlacement="bottom"
      />
    </div>
  );
}

export default function VideoSettingsEffects({
  videoEffect,
  setVideoEffect,
  isMobile,
  allowVideoManipulation,
  usingForcedEffect,
}) {
  const dispatch = useDispatch();
  const [customVirtualBackgrounds, setCustomVirtualBackgrounds] = useState([]);
  const [hover, setHover] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const images = await getImages();
      setCustomVirtualBackgrounds(images);
    })();
  }, []);

  function onUpload(file) {
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        if (e.target.result) {
          const image = e.target.result;
          const id = await storeImage(image);
          if (id) {
            const imageuri = `indexeddb://${id}`;
            setVideoEffect({
              image: imageuri,
              type: 'virtual-background',
            });
            setCustomVirtualBackgrounds((prev) => [
              ...prev,
              { key: id, value: image },
            ]);
          } else {
            createSnack(dispatch, t('Could not use image'), 'error');
          }
        }
      };
      reader.readAsDataURL(file);
    }
  }

  function onRemoveCustomImage(e, key) {
    e.stopPropagation();
    setCustomVirtualBackgrounds((prev) => prev.filter((i) => i.key !== key));
    removeImage(key);
  }

  if (!allowVideoManipulation || usingForcedEffect) {
    return (
      <div
        className={classnames(styles.container, {
          [styles.isMobile]: isMobile,
        })}
        style={{ marginTop: 0 }}
      >
        <p>
          {usingForcedEffect
            ? t('A forced visual effect has been set for your client')
            : t("This browser doesn't support visual effects")}
        </p>
      </div>
    );
  }

  return (
    <div
      className={classnames(styles.container, { [styles.isMobile]: isMobile })}
      style={{ marginTop: 0 }}
    >
      <p>{t('Effects')}</p>
      <div className={styles.buttons}>
        <Button
          selected={!videoEffect}
          color="white"
          onClick={() => setVideoEffect(null)}
          startIcon={<BlurOffIcon />}
        >
          {t('No effect')}
        </Button>
        <Button
          selected={videoEffect?.type === 'blur' && videoEffect?.strength === 4}
          color="white"
          onClick={() => setVideoEffect({ type: 'blur', strength: 4 })}
          startIcon={<BlurOnIcon />}
        >
          {t('Light blur')}
        </Button>
        <Button
          selected={
            videoEffect?.type === 'blur' && videoEffect?.strength === 12
          }
          color="white"
          onClick={() => setVideoEffect({ type: 'blur', strength: 12 })}
          startIcon={<BlurOnIcon />}
        >
          {t('Strong blur')}
        </Button>
        {virtualBackgrounds.map((background, idx) => (
          <Button
            key={background}
            label={t(getVirtualBackgroundName(background))}
            selected={
              videoEffect?.type === 'virtual-background' &&
              videoEffect?.image === background
            }
            color="white"
            onClick={() =>
              setVideoEffect({ type: 'virtual-background', image: background })
            }
            className={styles.virtualBackgroundButton}
          >
            <img
              width={88}
              height={50}
              src={background}
              className={styles.virtualBackground}
              alt={t(getVirtualBackgroundName(background))}
            />
          </Button>
        ))}
        {customVirtualBackgrounds.map((background, idx) => {
          const selected =
            videoEffect?.type === 'virtual-background' &&
            (videoEffect?.image === background.value ||
              videoEffect?.image === `indexeddb://${background.key}`);
          return (
            <Button
              key={background.key}
              label={`${t('Custom')} ${idx + 1}`}
              color="white"
              onClick={() =>
                setVideoEffect({
                  type: 'virtual-background',
                  image: `indexeddb://${background.key}`,
                })
              }
              className={classnames(
                styles.customButton,
                styles.virtualBackgroundButton
              )}
              selected={selected}
              onMouseEnter={() => setHover(background.key)}
              onMouseLeave={() => setHover(null)}
            >
              <img
                width={88}
                height={50}
                src={background.value}
                className={styles.virtualBackground}
                alt={`${t('Custom')} ${idx + 1}`}
              />
              {!selected && hover === background.key ? (
                <IconButton
                  onClick={(e) => onRemoveCustomImage(e, background.key)}
                  className={styles.removeIcon}
                >
                  <TrashIcon color="danger" />
                </IconButton>
              ) : null}
            </Button>
          );
        })}
        <Button
          className={styles.upload}
          color="white"
          component="label"
          label={
            !isMobile
              ? t('Drag or click to upload image from computer')
              : t('Click to upload image')
          }
          onDragEnter={(e) => e.preventDefault()}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            onUpload(file);
          }}
        >
          <AddPhotoAlternateIcon />
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              onUpload(file);
            }}
          />
        </Button>
        <p className={styles.footnote}>
          {t('We recommend JPG/PNG images at 1280x720 resolution.')}
          <br />
          {t('Custom images will not be sent to a remote server.')}
        </p>
      </div>
    </div>
  );
}
