import * as mediasoupClient from 'mediasoup-client';
import SocketTransport from './SocketTransport';
import WindowMessageHandler from './WindowMessageHandler';
import logger from '../utils/logger';
import store from '../store/store';
import {
  ConnectionState,
  addPeer,
  addShareDataProducer,
  setConnectionState,
  setEnableMetricsCollection,
  setIsDuplicatePeer,
  setIsRoomLockEnabled,
  setIsRoomLocked,
  setJoinedRoom,
  setPeers,
  setRoomData,
  setShareData,
  setSharingScreen,
  setShowNPS,
} from '../store/slices/roomSlice';
import {
  loadToken,
  setAdmitted,
  setToken,
  unsetToken,
} from '../store/slices/userSlice';
import {
  ALLOW_TALKING_DETECTION,
  ALLOW_VIDEO_MANIPULATION,
  getDisplayMedia,
} from '../utils/media';
import {
  setDominantSpeaker,
  setPinned,
  setViewLayout,
} from '../store/slices/layoutSlice';
import {
  addMutedPeer,
  addRaisedHand,
  setMembers,
  setPeersMuted,
  setRaisedHands,
} from '../store/slices/memberSlice';
import { checkReload, debounce } from '../utils/functions';
import {
  setRecording,
  setRecordingStartTime,
} from '../store/slices/recordSlice';
import {
  createSnack,
  requestNotificationPermission,
} from '../actions/SnackActions';
import {
  setHandRaised,
  setHideVideo,
  setMuted,
  setShareFullScreen,
  setShowTalkingPopover,
  setVideoLoading,
  toggleMuted,
} from '../store/slices/controlSlice';
import VideoLayouts from '../utils/VideoLayouts';
import { showConfirm } from '../utils/confirm';
import { addChatMessage } from '../store/slices/chatSlice';
import {
  addNewRequest,
  batchAddRequests,
  clearRequests,
  removeRequest,
} from '../store/slices/requestSlice';
import { addMember, removeMember } from '../store/slices/lobbySlice';
import { addNetworkStatus } from '../store/slices/monitoringSlice';
import { track } from '../actions/TrackActions';
import { SOCKET_INIT, SOCKET_REFRESH } from '../utils/constants';
import LRU from 'lru-cache';
import { SHARE_VIDEO_BITRATE, VIDEO_BITRATE } from '../constants';
import VideoManipulator from '../utils/VideoManipulator';
import AudioManipulator from '../utils/AudioManipulator';
import StreamHandler from './StreamHandler';
import { exchangeToken, kickSelf } from '../actions/UserActions';
import {
  setDeviceSettings,
  setDeviceSetup,
  setMicSound,
  setShowSettings,
} from '../store/slices/deviceSlice';
import { createRoom } from '../actions/RoomActions';
import TalkingDetector from '../utils/TalkingDetector';
import { getStorageItem, setStorageItem } from '../utils/storage';
import { getObjectKeysWithDifferences } from '../utils/compareObjects';

const iceServersCache = new LRU({
  ttl: 1000 * 60,
  max: 1,
});

class RoomClient {
  constructor({ videoScope = true }) {
    this._roomToken = null;
    this._roomId = null;
    this._videoScope = videoScope;
    this._closed = false;
    this._socket = null;
    this._consumerTransport = null;
    this._producerTransport = null;
    this._device = null;
    this._kicked = false;
    this._shareProducers = [];
    this._audioProducer = null;
    this._videoProducer = null;
    this._videoManipulator = null;
    this._audioManipulator = null;
    this._streamHandler = new StreamHandler();
    this._members = [];
    this._translator = null;
    this._preShareLayout = null;
    this._videoLayouts = new VideoLayouts();
    this._shareElement = null;
    this._connectionSetup = false;
    this._triggeredTalkingPopover = false;
    this._talkingTimeout = null;
    this._windowMessageHandler = new WindowMessageHandler();
  }

  get socket() {
    return this._socket;
  }

  get device() {
    return this._device;
  }

  set device(device) {
    this._device = device;
  }

  get consumerTransport() {
    return this._consumerTransport;
  }

  set consumerTransport(transport) {
    this._consumerTransport = transport;
  }
  get producerTransport() {
    return this._producerTransport;
  }

  set producerTransport(transport) {
    this._producerTransport = transport;
  }

  get kicked() {
    return this._kicked;
  }

  set kicked(kicked) {
    this._kicked = kicked;
  }

  get videoProducer() {
    return this._videoProducer;
  }

  set videoProducer(producer) {
    this._videoProducer = producer;
  }

  get audioProducer() {
    return this._audioProducer;
  }

  set audioProducer(producer) {
    this._audioProducer = producer;
  }

  get audioManipulator() {
    return this._audioManipulator;
  }

  set audioManipulator(manipulator) {
    this._audioManipulator = manipulator;
  }

  get videoManipulator() {
    return this._videoManipulator;
  }

  set videoManipulator(manipulator) {
    this._videoManipulator = manipulator;
  }

  get streamHandler() {
    return this._streamHandler;
  }

  set streamHandler(streams) {
    this._streamHandler = streams;
  }

  get shareProducers() {
    return this._shareProducers;
  }

  get preShareLayout() {
    return this._preShareLayout;
  }

  set preShareLayout(value) {
    this._preShareLayout = value;
  }

  get videoLayouts() {
    return this._videoLayouts;
  }

  get members() {
    return this._members;
  }

  set members(members) {
    this._members = members;
  }

  get connectionSetup() {
    return this._connectionSetup;
  }

  set connectionSetup(status) {
    this._connectionSetup = status;
  }

  get triggeredTalkingPopover() {
    return this._triggeredTalkingPopover;
  }

  set triggeredTalkingPopover(status) {
    this._triggeredTalkingPopover = status;
  }

  get talkingTimeOut() {
    return this._talkingTimeout;
  }

  set talkingTimeOut(timeOut) {
    this._talkingTimeout = timeOut;
  }

  get closed() {
    return this._closed;
  }

  close() {
    if (this._closed) return;
    logger.log('Going to close RoomClient Object');
    this._closed = true;
    this._socket?.close();
    this._videoProducer?.close();
    this._consumerTransport?.close();
    this._consumerTransport?.removeAllListeners();
    this._producerTransport?.close();
    this._producerTransport?.removeAllListeners();
    (this._shareProducers || []).forEach((p) => p.close());
    this._streamHandler?.destroy();
    this._streamHandler = null;
    this._videoProducer = null;
    this._shareProducers = null;
    this._consumerTransport = null;
    this._producerTransport = null;
    this._device = null;
    this._socket = null;
    this._windowMessageHandler.destroy();
  }

  send(message) {
    if (!this._socket) return;
    if (this._closed) {
      throw new Error("Can't send as RoomClient already closed!");
    }
    this._socket.send(message);
  }

  sendWindowMessage(action, payload) {
    this._windowMessageHandler.send(action, payload);
  }

  async request(event, data) {
    if (!this._socket) return;
    if (this._closed) {
      throw new Error("Can't request as RoomClient already closed!");
    }
    const response = await this._socket.request(event, data);
    return response;
  }

  async init({ state = SOCKET_INIT }) {
    if (this._closed) {
      // We should not init a new socket if roomClient has been closed
      return;
    }

    if (this._socket && this._socket.connected) {
      logger.log(
        'Socket already available and connected! No need to recreate.'
      );
      return;
    }

    if (this._socket && !this._socket.closed) {
      // We should not init a new socket if socket is initiated but yet to be connected
      return;
    }

    await this._initSocket(state);
  }

  async _initSocket(state = SOCKET_INIT) {
    const token = store.getState().user.token;
    logger.log(
      'Going to initialise Socket Transport!',
      state,
      'with room token',
      token
    );
    //  logger.log('Going to initialise Socket Transport!', state);
    this._socket = new SocketTransport(token, state);

    this._socket.on('message', (message) =>
      this._gotMessageFromServer(message)
    );

    await this._socket.connect();
    this._socket.on('connected', () => {
      logger.log('Socket connected in Init socket');
      this._checkIfIceRetartRequired();
    });
  }

  async _checkIfIceRetartRequired() {
    if (this._producerTransport) {
      if (
        this._producerTransport.connectionState === 'failed' ||
        this._producerTransport.connectionState === 'disconnected'
      ) {
        logger.log(
          `Need to restart ICE as transport now failed or disconnected`
        );

        const res = await this.request('restartIce', {
          transportId: this._producerTransport.id,
        });
        logger.log('Ice restart response', res);
        if (res.iceParameters) {
          this._producerTransport.restartIce({
            iceParameters: res.iceParameters,
          });
          logger.log('Producer transport ice Restart successful');
          store.dispatch(setConnectionState(ConnectionState.CONNECTED));
        }
      }
    } else logger.log('Noting to do as producer transports not yet available.');

    if (this._consumerTransport) {
      if (
        this._consumerTransport.connectionState === 'failed' ||
        this._consumerTransport.connectionState === 'disconnected'
      ) {
        logger.log(
          `Need to restart ICE as transport now failed or disconnected`
        );

        const res = await this.request('restartIce', {
          transportId: this._consumerTransport.id,
        });
        logger.log('Ice restart response', res);
        if (res.iceParameters) {
          this._consumerTransport.restartIce({
            iceParameters: res.iceParameters,
          });
          logger.log('Consumer transport ice Restart successful');
          store.dispatch(setConnectionState(ConnectionState.CONNECTED));
        }
      }
    } else logger.log('Noting to do as producer transports not yet available.');
  }

  async startTransports() {
    logger.log('startTransports() | device is', this.device);
    // const connectionState = store.getState().room.connectionState;
    const userId = store.getState().user.userId;
    const { consumerTransport, producerTransport } = await this.setupTransports(
      this._device,
      this._roomId,
      this._socket
    );
    this._consumerTransport = consumerTransport;
    this._producerTransport = producerTransport;
    logger.log('consumer transport is ', this._consumerTransport);
    logger.log('producer transport is ', this._producerTransport);
    producerTransport.on('connectionstatechange', (state) => {
      switch (state) {
        case 'connected':
          logger.log(`TRANSPORT state ${state}`);
          store.dispatch(setConnectionState(ConnectionState.CONNECTED));
          break;
        case 'disconnected':
        case 'failed':
          track(
            'video-frontend',
            {
              error: `producer ${state}`,
              message: { userId },
            },
            this._roomToken
          );
          logger.log(`TRANSPORT state ${state}`);
          // Don't trigger a reconnect if we have already failed

          store.dispatch(setConnectionState(ConnectionState.RECONNECTING));

          break;
        default:
          logger.log('Current producerTransport state', state);
      }
    });
  }
  _gotMessageFromServer = async (message) => {
    if (!this._videoScope) return;
    logger.log('Got message from server', message);

    const userId = store.getState().user.userId;

    switch (message.action) {
      case 'shareProducer':
        if (message.peerId === userId) {
          this.sendWindowMessage(message.action, {
            peerId: message.peerId,
            share: message?.kind === 'video',
          });
        }
        break;
      case 'kicked':
        if (message.peerId === userId) {
          this.sendWindowMessage(message.action, {
            peerId: message.peerId,
          });
        }
        break;
      case 'removePeer':
        this.sendWindowMessage(message.action, {
          peerId: message.peerId,
          properLeave: message.properLeave,
        });
        break;
      default:
        break;
    }

    switch (message.action) {
      case 'newProducer':
        if (!this._producerTransport && !this._consumerTransport) {
          logger.log(
            'No need to do anything for newProducer as transports are not available yet!'
          );
          return;
        }
        await this._handleNewProducer(message);
        break;
      case 'removePeer':
        await this._handlePeerRemoval(message);
        break;
      case 'kicked':
        this._handleKicked();
        break;
      case 'updatedMembersList':
        if (!this._producerTransport && !this._consumerTransport) {
          logger.log(
            'No need to do anything for updateMemberList as transports are not available yet!'
          );
          return;
        }
        this._handleMemberListUpdate(message);
        break;
      case 'addPeer':
        if (!this._producerTransport && !this._consumerTransport) {
          logger.log(
            'No need to do anything for addPeer as transports are not available yet!'
          );
          return;
        }
        this._handlePeerAddition(message);
        break;
      case 'recordingStarted':
        this._handleRecordingStart(message);
        break;
      case 'recordingStopped':
        this._handleRecordingStop(message);
        break;
      case 'peerMuted':
        this._handlePeerMute(message);
        break;
      case 'producerClosed':
        this._handleProducerClose(message);
        break;
      case 'producerPaused':
        this._handleProducerPause(message);
        break;
      case 'producerResumed':
        this._handleProducerResume(message);
        break;
      case 'consumerCount':
        this._handleConsumerCount(message);
        break;
      case 'producerMaxLayers':
        this._handleProducerLayers(message);
        break;
      case 'peerPriorities':
        this._handlePeerPriorities(message);
        break;
      case 'dominantSpeaker':
        this._handleDominantSpeaker(message);
        break;
      case 'raiseHand':
        this._handleHandRaise(message);
        break;
      case 'shareProducer':
        this._handleShareProducer(message);
        break;
      case 'forceMute':
        this._handleForceMute();
        break;
      case 'forceStopScreenShare':
        this._handleForceStopScreenShare();
        break;
      case 'roomClosed':
        this._handleRoomClosed();
        break;
      case 'roomLockUpdated':
        this._handleRoomLockUpdate(message);
        break;
      case 'chat':
        this._handleChatMessage(message);
        break;
      case 'requestJoin':
        this._handleRequestJoin(message);
        break;
      case 'requestDenied':
        this._handleRequestDeny(message);
        break;
      case 'usersEnteredLobby':
        this._handleUserLobbyEntry(message);
        break;
      case 'usersLeftLobby':
        this._handleUserLobbyLeave(message);
        break;
      case 'peerNetworkStatus':
        this._handlePeerNetWorkStatus(message);
        break;
      case 'requestAccepted':
        this._handleRequestAccept(message);
        break;
      case 'connectionTimedOut':
        const text = `You've lost connection to the meeting. Click the 'Rejoin' button to rejoin`;
        createSnack(store.dispatch, this._translator(text), 'info', {
          allowNotification: true,
          notificationText: text,
          customSnack: 'timed-Out',
        });
        this.close();
        break;
      case 'duplicatePeer':
        logger.log(
          'This is a duplicate request while 1st one is active! Therefore close this request!'
        );
        store.dispatch(setIsDuplicatePeer(true));
        break;
      case 'closeThisConnection':
        logger.log(
          'This is a close connection request where the 2nd request wants to join room! Therefore close this request!'
        );
        this.leaveRoom();
        this._navigate('/duplicate');
        break;
      case 'usersInQueue':
        // This is handled analogously as if it were a users that entered the lobby
        this._handleUserLobbyEntry(message);
        break;
      default:
        logger.error(`Unknown message with action:${message.action}`);
    }
  };

  _handleNewProducer = async (producer) => {
    const userId = store.getState().user.userId;

    if (producer.peerId === userId) return;
    // Guard against newProducer event triggers right at the same time as getStatus
    if (
      Object.values(store.getState().room.peers).find(
        (p) => p[producer.kind]?.producer?.id === producer.id
      )
    )
      return;
    logger.log('New producer', producer, userId);
    logger.log('All available updatedPeers are:', store.getState().room.peers);
    const { stream, consumer } = await this.consume(producer.id);
    if (
      store.getState().room.peers[producer.peerId] &&
      store.getState().room.peers[producer.peerId][producer.kind]
    ) {
      logger.log(
        `Peer already exists for this producer with id ${producer.peerId} of kind ${producer.kind}`
      );
      logger.log(
        'After consume All available updatedPeers are:',
        store.getState().room.peers
      );
      let updatedPeers = { ...store.getState().room.peers };
      updatedPeers = {
        ...updatedPeers,
        [producer.peerId]: {
          ...updatedPeers[producer.peerId],
          [producer.kind]: {
            ...updatedPeers[producer.peerId][producer.kind],
            stream,
            producer,
            consumer,
          },
        },
      };
      store.dispatch(setPeers(updatedPeers));
    } else {
      logger.log(
        `Going to add a new producer as peer with id ${producer.peerId} of kind ${producer.kind} doesnot exist`
      );
      let newProducer = {
        stream,
        producer,
        consumer,
        show: true,
        peerId: producer.peerId,
      };
      logger.log('new producer is:', newProducer);
      store.dispatch(addPeer({ data: newProducer, kind: producer.kind }));
    }
  };

  _handlePeerRemoval = async ({ peerId, setAsGhost = false }) => {
    const userId = store.getState().user.userId;
    const pinned = store.getState().layout.pinned;
    if (peerId === userId) return;
    if (setAsGhost) {
      this._updateMembersList();
      return;
    }
    let updatedPeers = { ...store.getState().room.peers };
    for (const peerId in updatedPeers) {
      let peer = updatedPeers[peerId];
      if (peer.peerId === peerId) {
        if (peer['audio']) {
          peer['audio'].consumer.close();
          peer['audio'].stream.getTracks().forEach((track) => track.stop());
        }
        if (peer['video']) {
          peer['video'].consumer.close();
          peer['video'].stream.getTracks().forEach((track) => track.stop());
        }
      }
    }
    logger.log('Peerremoval peers are', updatedPeers);
    // const updatedPeers = peers.filter((peer) => peer.peerId !== peerId);

    delete updatedPeers[peerId];
    logger.log('Peerremoval Updated peers are', updatedPeers);
    store.dispatch(setPeers(updatedPeers));
    this._updateMembersList();
    if (pinned[peerId]) {
      store.dispatch(
        setPinned((p) => {
          const newPinned = { ...p };
          delete newPinned[peerId];
          return newPinned;
        })
      );
    }
  };

  _handleKicked = () => {
    this._kicked = true;
    this.leaveRoom();
    store.dispatch(setShowNPS('kicked'));
    store.dispatch(setJoinedRoom(false));
  };

  _handlePeerAddition = (data) => {
    const peers = store.getState().room.peers;
    // let peerAlreadyAvailable = peers.find(
    //   (peer) => peer.peerId === data.peerId
    // );
    let peerAlreadyAvailable = peers[data.peerId];
    logger.log('Peer already available is', peerAlreadyAvailable);
    if (peerAlreadyAvailable) {
      // May be the case of manual browser refresh by this peerId.
      logger.log(
        `This peer: ${data.peerId} is already available. No need to add again.`
      );
      // Delete audio and video consumers for existing peer for cases of dulicate peer joining from a second device with no cemara / mic

      let updatedPeers = { ...store.getState().room.peers };
      let peerObject = { ...updatedPeers[data.peerId] };
      logger.log('peerObject is', peerObject);
      delete peerObject.video;
      delete peerObject.audio;
      updatedPeers[data.peerId] = peerObject;
      store.dispatch(setPeers(updatedPeers));
    } else {
      logger.log('This peer is not available. Need to add to peers.');
      this._updateMembersList();
    }
  };

  _handleMemberListUpdate = () => {
    this._updateMembersList();
  };

  _handleRecordingStart = ({ ownerId, createdAt }) => {
    store.dispatch(setRecording(ownerId));
    store.dispatch(setRecordingStartTime(createdAt));
  };

  _handleRecordingStop = () => {
    store.dispatch(setRecording(null));
    store.dispatch(setRecordingStartTime(null));
  };

  _handlePeerMute = ({ peerId, muted }) => {
    store.dispatch(addMutedPeer({ peerId, muted }));
  };

  _handleProducerClose = async ({ peerId, producerId }) => {
    const userId = store.getState().user.userId;
    const peers = store.getState().room.peers;
    logger.log('producer closed', peerId, producerId);
    let updatedPeers = { ...peers };
    logger.log('peers are', updatedPeers);
    logger.log(
      'Object propert descriptor',
      Object.getOwnPropertyDescriptor(updatedPeers, 'show')
    );
    if (peerId === userId) return;
    if (
      updatedPeers[peerId] &&
      updatedPeers[peerId]['audio'] &&
      updatedPeers[peerId]['audio'].producer.id === producerId
    ) {
      updatedPeers[peerId]['audio'].stream
        .getTracks()
        .forEach((track) => track.stop());
      updatedPeers[peerId]['audio'].consumer.close();
      let peerObject = { ...updatedPeers[peerId] };
      logger.log('peerObject is', peerObject);
      delete peerObject.audio;
      updatedPeers[peerId] = peerObject;
    } else if (
      updatedPeers[peerId] &&
      updatedPeers[peerId]['video'] &&
      updatedPeers[peerId]['video'].producer.id === producerId
    ) {
      updatedPeers[peerId]['video'].stream
        .getTracks()
        .forEach((track) => track.stop());
      updatedPeers[peerId]['video'].consumer.close();
      let peerObject = { ...updatedPeers[peerId] };
      logger.log('peerObject is', peerObject);
      delete peerObject.video;
      updatedPeers[peerId] = peerObject;
    }

    logger.log('updatedpeers are', updatedPeers);
    store.dispatch(setPeers(updatedPeers));
  };

  _handleProducerPause = ({ peerId, producerId, kind }) => {
    const userId = store.getState().user.userId;
    const peers = store.getState().room.peers;
    if (peerId === userId) return;
    if (kind === 'audio') {
      return;
    }
    logger.log('producer paused', peerId, producerId);
    let updatedPeers = { ...peers };
    if (
      updatedPeers[peerId] &&
      updatedPeers[peerId]['video'] &&
      updatedPeers[peerId]['video'].producer.id === producerId
    ) {
      updatedPeers[peerId]['video'].consumer.pause();
      // updatedPeers[peerId]['video'].show = false;
      let videoProperties = { ...updatedPeers[peerId].video };
      logger.log('video properties:', videoProperties);
      videoProperties.show = false;
      updatedPeers = {
        ...updatedPeers,
        [peerId]: { ...updatedPeers[peerId], video: videoProperties },
      };
      logger.log('Updated peers in producer pause:', updatedPeers);
      store.dispatch(setPeers(updatedPeers));
    }
    // logger.log('updatedPeers is', updatedPeers);
  };

  _handleProducerResume = ({ peerId, producerId, kind }) => {
    const userId = store.getState().user.userId;
    const peers = store.getState().room.peers;
    if (peerId === userId) return;
    if (kind === 'audio') {
      return;
    }
    logger.log('producer resumed', peerId, producerId);
    let updatedPeers = { ...peers };
    logger.log('Updated peers', updatedPeers);
    if (
      updatedPeers[peerId] &&
      updatedPeers[peerId]['video'] &&
      updatedPeers[peerId]['video'].producer.id === producerId
    ) {
      updatedPeers[peerId]['video'].consumer.resume();
      let videoProperties = { ...updatedPeers[peerId].video };
      logger.log('video properties:', videoProperties);
      videoProperties.show = true;
      updatedPeers = {
        ...updatedPeers,
        [peerId]: { ...updatedPeers[peerId], video: videoProperties },
      };
      logger.log('Updated peers in producer resume:', updatedPeers);
      store.dispatch(setPeers(updatedPeers));
    }
  };

  _handleConsumerCount = async ({ peerId, producerId, count, share }) => {
    let producer;
    const userId = store.getState().user.userId;
    if (share) {
      producer = (this._shareProducers || []).find((x) => x.id === producerId);
    } else {
      producer = this._videoProducer;
    }
    logger.log('CONSUMER COUNT', {
      peerId,
      userId,
      producerId,
      currProducer: producer?.id,
      count,
      share,
    });
    if (peerId !== userId || producerId !== producer?.id) {
      return;
    }

    // Seems to be some issue with pausing share producers, so we skip it.
    // Most likely this producer should never be paused anyway
    if (count === 0 && !producer?.paused) {
      logger.log('PAUSING SELF VIDEO', producerId, share);
      await this._socket.request('pauseProducer', {
        producerId,
      });
      producer?.pause();
    } else if (count > 0 && producer?.paused) {
      logger.log('RESUMING SELF VIDEO', producerId, share);
      await this._socket.request('resumeProducer', {
        producerId,
      });
      producer?.resume();
      let updatedPeers = { ...store.getState().room.peers };
      if (
        updatedPeers[peerId] &&
        updatedPeers[peerId]['video'] &&
        updatedPeers[peerId]['video'].producer.id === producerId
      ) {
        updatedPeers[peerId]['video'].show = true;
        store.dispatch(setPeers(updatedPeers));
      }
    }
  };

  _handleProducerLayers = ({ peerId, producerId, maxSpatialLayer }) => {
    const userId = store.getState().user.userId;
    if (peerId !== userId || producerId !== this._videoProducer?.id) {
      return;
    }
    if (
      typeof maxSpatialLayer === 'number' &&
      maxSpatialLayer >= 0 &&
      maxSpatialLayer <= 2
    ) {
      logger.log(
        'setting producer max spatial layers',
        producerId,
        maxSpatialLayer
      );
      this._videoProducer.setMaxSpatialLayer(maxSpatialLayer);
    }
  };

  _handlePeerPriorities = (priorities) => {
    logger.log('Got priorities', priorities);
    // store.dispatch(setPeerPriorities(priorities));
  };

  _handleDominantSpeaker = (data) => {
    logger.log('Got dominant speaker data', data);
    if (!data) {
      store.dispatch(setDominantSpeaker(null));
    } else {
      const { peerId, producerId } = data;
      store.dispatch(setDominantSpeaker({ peerId, producerId }));
    }
  };

  _handleHandRaise = ({ peerId, raised, forcedBy }) => {
    const userId = store.getState().user.userId;
    if (peerId !== userId && forcedBy !== userId) {
      const name = this._members.find((m) => m.id === peerId)?.userData?.name;
      const text = `${name || this._translator('Unknown')} ${
        raised ? this._translator('raised') : this._translator('lowered')
      } ${this._translator('their hand')}.`;
      const notificationText = text + ' 🤚';
      createSnack(store.dispatch, text, 'info', {
        allowNotification: true,
        notificationText,
        customSnack: 'raised-hand',
      });
    }
    if (peerId === userId && forcedBy) {
      store.dispatch(setHandRaised(false));
      createSnack(
        store.dispatch,
        this._translator('Hand lowered by moderator'),
        'info'
      );
    } else {
      store.dispatch(addRaisedHand({ peerId, raised }));
    }
  };

  _handleShareProducer = async ({ producerId, peerId, kind }) => {
    const userId = store.getState().user.userId;
    const shareData = store.getState().room.shareData;
    const viewLayout = store.getState().layout.viewLayout;
    logger.log('SHARE PRODUCER', { peerId, producerId, kind });
    if (!peerId || peerId === userId) return;
    if (!producerId) {
      (shareData?.producers || []).map((x) => {
        x.consumer?.close();
      });
      store.dispatch(setShareData(null));
      store.dispatch(setShareFullScreen(false));
      store.dispatch(setSharingScreen(null));
      if (
        this._preShareLayout === 'grid' ||
        this._preShareLayout === 'spotlight'
      ) {
        store.dispatch(setViewLayout(this._preShareLayout));
        this._preShareLayout = null;
      }
      return;
    }
    const { stream, consumer } = await this.consume(producerId);
    store.dispatch(
      addShareDataProducer({
        producer: { stream, producerId, consumer, kind },
        peerId,
      })
    );
    store.dispatch(setSharingScreen('other'));
    if (!this._preShareLayout) {
      this._preShareLayout = viewLayout;
    }
    store.dispatch(setViewLayout('spotlight'));
  };

  _handleForceMute = () => {
    store.dispatch(setMuted(true));
    createSnack(
      store.dispatch,
      this._translator('You have been muted by the moderator'),
      'warning',
      {
        allowNotification: true,
      }
    );
  };

  _handleForceStopScreenShare = () => {
    this.onToggleScreenShare(true);
    createSnack(
      store.dispatch,
      this._translator('Your shared screen has been stopped by the moderator'),
      'warning',
      { allowNotification: true }
    );
  };

  _handleRoomClosed = () => {
    this._kicked = true;
    store.dispatch(setShowNPS('closed'));
  };

  _handleRoomLockUpdate = ({ isActive }) => {
    store.dispatch(setIsRoomLocked(isActive));
    // Everybody is admitted when unlocked, so clear all requests
    if (isActive === false) {
      store.dispatch(clearRequests());
    }
  };

  _handleChatMessage = (msg) => {
    const userId = store.getState().user.userId;
    const showSideBars = store.getState().layout.showSidebars;
    const open = showSideBars['right'];
    store.dispatch(addChatMessage({ message: msg, userId }));
    if (msg.peerId !== userId && !open) {
      createSnack(store.dispatch, msg, 'info', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        customSnack: 'chat',
      });
    }
  };

  _handleRequestJoin = (request) => {
    const roomLocked = store.getState().room.isRoomLocked;
    if (!roomLocked) return;
    // Only consider requests to join if room is locked
    store.dispatch(addNewRequest(request));
    const { peerId } = request;
    const payload = { token: this._roomToken, roomId: this._roomId, peerId };
    createSnack(
      store.dispatch,
      this._translator('{{name}} wants to join your room', {
        name: request.name,
      }),
      'info',
      { customSnack: 'request', waitTime: 20000, payload }
    );
  };

  _handleRequestDeny = (request) => {
    store.dispatch(removeRequest(request));
  };

  _handleUserLobbyEntry = async ({ users, alertOthers }) => {
    const selfId = store.getState().user.userId;
    const newUsers = users.filter((x) => x.userId !== selfId);

    newUsers.forEach((user) =>
      store.dispatch(addMember({ user, alertOthers }))
    );

    if (alertOthers) {
      if (newUsers.length === 1) {
        createSnack(
          store.dispatch,
          `${
            newUsers[0].userData?.name || this._translator('Unknown')
          } ${this._translator('joined the lobby')}`,
          'info'
        );
      } else if (newUsers.length > 0) {
        createSnack(
          store.dispatch,
          `${newUsers.length} ${this._translator('users joined the lobby')}`,
          'info'
        );
      }
    }
  };

  _handleUserLobbyLeave = async ({ userIds, alertOthers }) => {
    const selfId = store.getState().user.userId;
    const lobby = store.getState().lobby.lobby;
    const leftUsers = userIds.filter((x) => x !== selfId);

    if (alertOthers) {
      if (leftUsers.length === 1) {
        const leftUser = lobby[leftUsers[0]];
        createSnack(
          store.dispatch,
          `${
            leftUser?.userData?.name || this._translator('Unknown')
          } ${this._translator('left the lobby')}`,
          'info'
        );
      } else if (leftUsers.length > 0) {
        createSnack(
          store.dispatch,
          `${leftUsers.length} ${this._translator('users left the lobby')}`,
          'info'
        );
      }
    }

    leftUsers.forEach((userId) => store.dispatch(removeMember(userId)));
  };

  _handlePeerNetWorkStatus = ({ peerId, status }) => {
    store.dispatch(addNetworkStatus({ [peerId]: status }));
  };

  _handleRequestAccept = () => {
    store.dispatch(setAdmitted(true));
  };

  onToggleScreenShare = async (forceStop = false, file = null) => {
    const userId = store.getState().user.userId;
    const sharingScreen = store.getState().room.sharingScreen;
    const role = store.getState().user.role;
    const shareData = store.getState().room.shareData;
    const maxWidth = store.getState().layout.maxWidth;
    const maxHeight = store.getState().layout.maxHeight;
    const isMobile = maxWidth <= 780 || maxHeight <= 520;
    const isAdmin = role === 'admin' || role === 'moderator';
    if (sharingScreen === 'other' && !isAdmin) {
      return; // Someone else is sharing
    } else if (sharingScreen === 'other' && isAdmin && !forceStop) {
      // Stop other screen share
      const confirmed = await showConfirm({
        title: this._translator('Are you sure?'),
        message: this._translator('This will stop shared screens'),
      });
      if (confirmed) {
        this.send({
          action: 'forceStopScreenShare',
          peerId: shareData.peerId,
        });
      }
    } else if (sharingScreen === 'self' || forceStop) {
      const oks = await Promise.all(
        this._shareProducers.map(async (producer) => {
          if (!producer) return;
          const res = await this._socket.request('closeProducer', {
            producerId: producer.id,
          });
          return res.ok;
        })
      );
      if (oks.every((ok) => ok)) {
        this._shareProducers.forEach((producer) => {
          producer.close();
        });
        this._shareProducers = [];
        if (shareData.stream) {
          shareData.stream.getTracks().forEach((track) => track.stop());
        }
        store.dispatch(setShareData(null));
        store.dispatch(setShareFullScreen(false));
        store.dispatch(setSharingScreen(null));
        if (this._shareElement) {
          this._shareElement.srcObject = null;
          this._shareElement.remove();
          this._shareElement = null;
        }
      } else {
        logger.error('Failed to close share producer');
      }
    } else {
      if (!this._device) return;
      let stream;
      let video;
      if (file) {
        if (file.type.startsWith('audio/')) {
          video = document.createElement('audio');
        } else {
          video = document.createElement('video');
        }
        video.src = URL.createObjectURL(file);
        video.controls = true;
        video.loop = true;
        video.play();
        if (this._shareElement) {
          this._shareElement.srcObject = null;
          this._shareElement.remove();
        }
        this._shareElement = video;
        await new Promise((resolve) => {
          video.onplaying = resolve;
        });
        video.captureStream = video.captureStream || video.mozCaptureStream;
        stream = video.captureStream();
      } else {
        stream = await getDisplayMedia(this._device, this._roomToken, isMobile);
      }
      if (!stream) return;
      const { videoProducer, audioProducer } = await this.startShareProducer(
        stream,
        this._producerTransport,
        this._device
      );
      if (!this._shareProducers) {
        this._shareProducers = [];
      }
      if (videoProducer) {
        this._shareProducers.push(videoProducer);
      }
      if (audioProducer) {
        this._shareProducers.push(audioProducer);
      }
      store.dispatch(setSharingScreen('self'));
      const track = stream.getVideoTracks()?.[0];
      const videostream = new MediaStream();
      if (track) {
        videostream.addTrack(track);
      }
      store.dispatch(
        setShareData({
          producers: [
            videoProducer
              ? {
                  stream: videostream,
                  producerId: videoProducer.id,
                  kind: videoProducer.kind,
                  video,
                }
              : null,
            !videoProducer && audioProducer
              ? {
                  stream: null,
                  producerId: audioProducer.id,
                  kind: 'video',
                  video,
                }
              : null,
          ].filter((x) => x),
          peerId: userId,
        })
      );
      if (track) {
        track.onended = () => {
          this.onToggleScreenShare(true);
        };
      }
    }
  };

  leaveRoom = () => {
    logger.log('LEAVE ROOM');
    store.dispatch(setConnectionState(ConnectionState.CLOSED));
    this.audioProducer?.close();
    (store.getState().room.shareData?.producers || []).forEach((p) =>
      p.consumer?.close()
    );
    this.audioProducer = null;
    this.close();
    store.dispatch(setPeers([]));
    store.dispatch(setShareData(null));
    store.dispatch(setShareFullScreen(false));
    store.dispatch(setSharingScreen(null));
    store.dispatch(setMuted(false));
    store.dispatch(setHideVideo(false));
    store.dispatch(setPeersMuted({}));
    store.dispatch(setDominantSpeaker(null));
    store.dispatch(setRecording(false));
    // store.dispatch(unsetToken());
    if (this.videoManipulator) {
      this.videoManipulator.destroy();
      this.videoManipulator = null;
    }
    if (this.audioManipulator) {
      this.audioManipulator.destroy();
      this.audioManipulator = null;
    }
    if (this.streamHandler) {
      this.streamHandler.destroy();
      this.streamHandler = null;
    }
  };

  updateMembersList = debounce(this._updateMembersList, 1000);

  async _updateMembersList() {
    if (!this?._socket) return;
    const members = await this._socket.request('getMembersList', this._roomId);
    logger.log('Members list', members);
    store.dispatch(setMembers(members.filter((x) => x.joined || x.isGhost)));
  }

  async acceptRequest({ roomId, peerId }) {
    await this._socket.request('acceptRequest', { roomId, peerId });
  }

  async denyRequest({ roomId, peerId }) {
    await this._socket.request('denyRequest', { roomId, peerId });
  }

  joinMeeting() {
    this.send({ action: 'meetingJoined' });
  }

  getUsersInQueue() {
    this.send({ action: 'getUsersInQueue', roomId: this._roomId });
  }

  cancelJoinRequest() {
    logger.log(
      'Going to cancel join request for duplicate join request by peer'
    );
    this.close();
    this._navigate('/duplicate');
  }

  joinFromHereRequest() {
    logger.log(
      'Going to cancel join request for duplicate join request by peer'
    );
    this.send({ action: 'closeFirstConnection' });
    setTimeout(window.location.reload(), 1500);
  }

  async toggleAudio() {
    if (!store.getState().control.muted) {
      await this._unMute();
    } else {
      await this.mute();
    }
  }

  async mute() {
    await this.request('togglePeerMuted', {
      producerId: this.audioProducer?.id,
      muted: true,
    });
    if (this.audioManipulator) {
      this.audioManipulator.mute();
    }
  }

  async _unMute() {
    this._triggeredTalkingPopover = false;
    clearTimeout(this._talkingTimeout);
    store.dispatch(setShowTalkingPopover(false));
    await this.request('togglePeerMuted', {
      producerId: this.audioProducer.id,
      muted: false,
    });
    if (this.audioManipulator) {
      this.audioManipulator.unmute();
    }
  }

  async toggleVideo() {
    const maxWidth = store.getState().layout.maxWidth;
    const maxHeight = store.getState().layout.maxHeight;
    const isMobile = maxWidth <= 780 || maxHeight <= 520;
    if (!store.getState().control.hideVideo) {
      if (this.videoProducer) return;
      this.videoManipulator?.destroy();
      this.videoManipulator = null;
      const stream = await this.streamHandler.getVideoStream({
        device: this.device,
        token: this._roomToken,
        videoDevice: store.getState().device.deviceSettings.videoDevice,
        isMobile,
        producing: true,
      });

      await this.startVideoProducer(
        stream,
        store.getState().device.deviceSettings,
        store.getState().user.clientOptions
      );
    } else {
      this.streamHandler.stopVideoTracks(
        store.getState().device.deviceSettings.videoDevice,
        true
      );
      if (!this.videoProducer) return;
      this.videoProducer.close();
      await this.request('closeProducer', {
        producerId: this.videoProducer.id,
      });
      this.videoProducer = null;
      this.videoManipulator?.destroy();
      this.videoManipulator = null;
    }
  }

  async getIceServers() {
    if (iceServersCache.has('iceServers')) {
      return iceServersCache.get('iceServers');
    }

    if (!window.__SHOULD_FETCH_ICE_SERVERS__) {
      return [
        {
          urls: `turn:${window.__TURN_ADDR__}:${window.__TURN_PORT__}?transport=udp`,
          username: window.__TURN_USER__,
          credential: window.__TURN_PASS__,
        },
      ];
    }

    let iceServers = await this.socket.request('getIceServers');

    iceServers = iceServers.map((server) => ({
      urls: server.url,
      ...server,
    }));

    delete iceServers.url;

    iceServersCache.set('iceServers', iceServers);

    return iceServers;
  }

  async setupProducerTransport() {
    const producerData = await this.socket.request('createProducerTransport', {
      rtpCapabilities: this.device.rtpCapabilities,
    });

    logger.log('producerData', producerData);

    const transport = window.__ENABLE_TURN__
      ? this.device.createSendTransport({
          ...producerData,
          iceServers: await this.getIceServers(),
          iceTransportPolicy: 'relay',
        })
      : this.device.createSendTransport(producerData);

    transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
      logger.log('producer transport connect');
      this.socket
        .request('connectProducerTransport', {
          transportId: transport.id,
          dtlsParameters,
        })
        .then(callback)
        .catch(errback);
    });

    transport.on(
      'produce',
      async ({ kind, rtpParameters, appData }, callback, errback) => {
        logger.log('producer transport produce');
        const { id } = await this.socket.request('produce', {
          transportId: transport.id,
          kind,
          rtpParameters,
          appData,
        });
        logger.log('producer transport produced', id);
        callback({ id });
      }
    );

    transport.on('connectionstatechange', (state) => {
      logger.log('produce state changed', state);
    });
    return transport;
  }

  async setupConsumerTransport() {
    const data = await this.socket.request('createConsumerTransport', {});

    const transport = window.__ENABLE_TURN__
      ? this.device.createRecvTransport({
          ...data,
          iceServers: await this.getIceServers(),
          iceTransportPolicy: 'relay',
        })
      : this.device.createRecvTransport(data);

    transport.on('connect', ({ dtlsParameters }, callback, errback) => {
      logger.log('consume connect');
      this.socket
        .request('connectConsumerTransport', {
          transportId: transport.id,
          dtlsParameters,
        })
        .then(callback)
        .catch(errback);
    });

    transport.on('connectionstatechange', async (state) => {
      logger.log('consume state change', state);
    });
    return transport;
  }

  async consume(producerId) {
    const { rtpCapabilities } = this.device;
    const data = await this.socket.request('consume', {
      producerId,
      transportId: this._consumerTransport.id,
      rtpCapabilities,
    });
    const { id, kind, rtpParameters } = data;
    const consumer = await this._consumerTransport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions: {},
    });
    const stream = new MediaStream();
    stream.addTrack(consumer.track);
    // Video resume is handled by PeerView
    if (kind !== 'video') {
      await this.socket.request('resumeConsumer', {
        consumerId: consumer.id,
      });
    }
    return { stream, consumer };
  }

  async startProducer({ videoTrack, audioTrack }) {
    let videoProducer;
    let audioProducer;
    const ps = [];

    const firstVideoCodec = this.device.rtpCapabilities.codecs.find(
      (c) => c.kind === 'video'
    );

    const isAndroid = /(android)/i.test(navigator.userAgent);
    const vp9 = firstVideoCodec.mimeType.toLowerCase().includes('vp9');
    if (vp9) {
      logger.log('using vp9');
    }

    // Chromium on Android has an issue when combining captureStream and vp8
    //  We cannot use scaleResolutionDownBy on android
    //  See https://bugs.chromium.org/p/chromium/issues/detail?id=1359707
    if (videoTrack) {
      logger.log('send video produce');
      const p = this.producerTransport
        .produce({
          track: videoTrack,
          encodings: vp9
            ? [{ scalabilityMode: 'L3T3_KEY', maxBitrate: VIDEO_BITRATE[0] }]
            : isAndroid
            ? [
                { maxBitrate: VIDEO_BITRATE[0], scalabilityMode: 'L1T2' },
                { maxBitrate: VIDEO_BITRATE[1], scalabilityMode: 'L1T2' },
                { maxBitrate: VIDEO_BITRATE[2], scalabilityMode: 'L1T2' },
              ]
            : [
                {
                  scaleResolutionDownBy: 4,
                  maxBitrate: VIDEO_BITRATE[0],
                  scalabilityMode: 'L1T2',
                },
                {
                  scaleResolutionDownBy: 2,
                  maxBitrate: VIDEO_BITRATE[1],
                  scalabilityMode: 'L1T2',
                },
                {
                  scaleResolutionDownBy: 1,
                  maxBitrate: VIDEO_BITRATE[2],
                  scalabilityMode: 'L1T2',
                },
              ],
          codecOptions: {
            videoGoogleStartBitrate: 1000,
          },
          disableTrackOnPause: false,
          //zeroRtpOnPause: true, // REMOVE ME only for debugging data transfers
        })
        .then((producer) => {
          videoProducer = producer;
          videoProducer.pause();
          logger.log('video produce done', videoProducer.id);
        });
      ps.push(p);
    }

    // AUDIO
    if (audioTrack) {
      logger.log('send audio produce');
      const p = this.producerTransport
        .produce({
          track: audioTrack,
          disableTrackOnPause: true,
          codecOptions: {
            opusStereo: true,
            opusDtx: false, // TODO we want this to be 1, but for recording it needs to be 0, otherwise ffmpeg shrinks audio on silence
            opusFec: true,
            opusNack: true,
          },
        })
        .then((producer) => {
          audioProducer = producer;
          logger.log('audio produce done', audioProducer.id);
        });
      ps.push(p);
    }

    await Promise.all(ps);

    return { videoProducer, audioProducer };
  }

  async startVideoProducer(stream, deviceSettings, clientOptions = {}) {
    const videostream = new MediaStream();
    const videotracks = stream.getVideoTracks();
    if (!videotracks.length) return {};

    videostream.addTrack(videotracks[0]);
    const videoEffect = deviceSettings?.videoEffect;

    const videoManipulator = new VideoManipulator(
      videostream,
      !ALLOW_VIDEO_MANIPULATION && !clientOptions?.forceVirtualBackgroundUrl
    );

    if (clientOptions?.forceVirtualBackgroundUrl) {
      videoManipulator.setEffect(
        {
          type: 'virtual-background',
          image: clientOptions.forceVirtualBackgroundUrl,
        },
        store.dispatch
      );
    } else if (videoEffect) {
      videoManipulator.setEffect(deviceSettings.videoEffect, store.dispatch);
    }

    const videoTrack = videoManipulator.getOutputStream().getVideoTracks()[0];

    let videoProducer;
    if (this.videoProducer) {
      this.videoProducer.replaceTrack({
        track: videoTrack,
      });
      videoProducer = this.videoProducer;
    } else {
      this.videoProducer = await this.startProducer({ videoTrack });
      videoProducer = this.videoProducer.videoProducer;
    }
    this._videoProducer = videoProducer;
    this._videoManipulator = videoManipulator;
  }

  async startAudioProducer(stream, deviceSettings) {
    const audiotracks = stream.getAudioTracks();
    let audioTrack;
    if (!audiotracks.length) return {};

    const audiostream = new MediaStream();
    audiostream.addTrack(audiotracks[0]);
    const audioManipulator = new AudioManipulator(
      audiostream,
      deviceSettings?.audioManipulation
    );
    await audioManipulator.init();
    audioTrack = audioManipulator.getOutputStream().getAudioTracks()[0];

    let audioProducer;
    if (this.audioProducer) {
      this.audioProducer.replaceTrack({
        track: audioTrack,
      });
      audioProducer = this.audioProducer;
    } else {
      this.audioProducer = await this.startProducer({
        audioTrack,
      });

      audioProducer = this.audioProducer.audioProducer;
    }

    this._audioManipulator = audioManipulator;
    this._audioProducer = audioProducer;
  }

  async startShareProducer(stream, producerTransport, device) {
    const videoTracks = stream.getVideoTracks();

    let videoProducer;

    if (videoTracks.length) {
      const track = videoTracks[0];

      const firstVideoCodec = device.rtpCapabilities.codecs.find(
        (c) => c.kind === 'video'
      );

      const vp9 = firstVideoCodec.mimeType.toLowerCase().includes('vp9');
      videoProducer = await producerTransport.produce({
        track,
        encodings: vp9
          ? [
              {
                dtx: true,
                maxBitrate: SHARE_VIDEO_BITRATE[1],
                scalabilityMode: 'L3T3',
              },
            ]
          : [
              {
                dtx: true,
                scaleResolutionDownBy: 2,
                maxBitrate: SHARE_VIDEO_BITRATE[0],
                scalabilityMode: 'L1T2',
              },
              {
                dtx: true,
                scaleResolutionDownBy: 1,
                maxBitrate: SHARE_VIDEO_BITRATE[1],
                scalabilityMode: 'L1T2',
              },
            ],

        codecOptions: {
          videoGoogleStartBitrate: 1000,
        },
        appData: {
          share: true,
        },
        disableTrackOnPause: false,
      });
      videoProducer.pause();
      logger.log('video produce done');
    }

    const audioTracks = stream.getAudioTracks();

    let audioProducer;

    if (audioTracks.length) {
      const track = audioTracks[0];

      audioProducer = await producerTransport.produce({
        track,
        codecOptions: {
          opusStereo: true,
          opusDtx: false, // TODO we want this to be 1, but for recording it needs to be 0, otherwise ffmpeg shrinks audio on silence,
          opusFec: true,
          opusNack: true,
        },
        appData: {
          share: true,
        },
        disableTrackOnPause: false,
      });
      logger.log('audio produce done');
    }

    return { videoProducer, audioProducer };
  }

  async setupDevice() {
    const routerRtpCapabilities = await this.socket.request(
      'getRouterRtpCapabilities',
      {}
    );
    if (!routerRtpCapabilities) return null;

    const device = new mediasoupClient.Device({
      iceServers: await this.getIceServers(),
    });
    await device.load({ routerRtpCapabilities });
    this._device = device;
    return device;
  }

  async setupTransports() {
    const consumerTransport = await this.setupConsumerTransport();

    if (!consumerTransport) {
      throw new Error('Consumer transport undefined');
    }

    const producerTransport = await this.setupProducerTransport();

    if (!producerTransport) {
      throw new Error('Producer transport undefined');
    }

    logger.log('Producer transport created', producerTransport);
    logger.log('consumer transport created', consumerTransport);
    return { consumerTransport, producerTransport };
  }

  fetchToken({ queryTokenId, roomId, navigate, t }) {
    logger.log(
      `Going to fetch token for tokenId ${queryTokenId}, roomId ${roomId}`
    );
    this._roomId = roomId;
    this._translator = t;
    this._navigate = navigate;
    if (!queryTokenId && roomId) {
      store.dispatch(loadToken(roomId));
    }
  }

  async configureToken(query, setQuery) {
    const queryTokenId = query.get('tokenid');
    const isDev = this._roomId.startsWith('dev:');
    const token = store.getState().user.token;
    logger.log(
      `Going to configure token for tokenId ${queryTokenId}, roomId ${this._roomId}, isDev:${isDev}, token:${token}`
    );
    if (!token && !queryTokenId && !isDev) {
      this._navigate('/unauthorized');
      throw new Error('No token');
    }

    if ((isDev && !token) || queryTokenId) {
      logger.log('Going to create a new token');
      let newToken;
      try {
        newToken = await exchangeToken(
          isDev ? queryTokenId || this._roomId : queryTokenId,
          store.dispatch
        );
      } catch (err) {
        const unauthorizedStatuses = [401, 400, 403];
        if (unauthorizedStatuses.includes(err?.response?.status)) {
          this._navigate('/unauthorized');
          throw new Error(
            `User not admitted due to ${err?.response?.status}: ${err.message}`
          );
        } else {
          store.dispatch(setConnectionState(ConnectionState.FAILED));
        }
        throw new Error(`No token: ${err.message} (${err?.response?.status})`);
      }
      store.dispatch(setToken({ token: newToken, roomId: this._roomId }));
      this._roomToken = newToken;
      logger.log('Room token is', this._roomToken);
      query.delete('tokenid');
      setQuery(query);
    }
  }

  async initializeSocketConnection() {
    const userId = store.getState().user.userId;
    const deviceSetup = store.getState().device.deviceSetup;
    logger.log(
      `Going to initialize socket connection for userId ${userId}, roomId ${this._roomId}`
    );
    if (this._roomToken && !userId) {
      store.dispatch(unsetToken());
      // No userId means the token is invalid
      this._navigate('/unauthorized');
      throw new Error('No userId');
    }

    // Check if the view is entered for the first time or if it's a reload
    const reload = checkReload();

    try {
      await this.init({
        state: reload ? SOCKET_REFRESH : SOCKET_INIT,
      });
    } catch (err) {
      if (err.message === 'Unauthorized') {
        // This message is returned when the token fails verification
        // This will be tracked from the backend, so no need to do it here too
        store.dispatch(unsetToken());
        this._navigate('/unauthorized');
        throw new Error('Token verification failed');
      }
      throw new Error(`Error creating socket: ${err.message}`);
    }

    // Create room
    await this.createAndJoinRoom();

    // Check if this was a reconnection
    let reconnected = await new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error('Checking for reconnection timed out'));
      }, 5 * 1000); // allow 5 seconds for checking for reconnection

      this.request('checkReconnectedPeer').then((res) => {
        logger.log('check reconnected peer!', res);
        clearTimeout(timeout); // Clear the timeout since the callback was reached
        resolve(res);
      });
    });
    if (typeof reconnected !== 'boolean') reconnected = false;
    if (reconnected) {
      store.dispatch(setDeviceSetup(true));
      this.getUsersInQueue();
    }
    logger.log(`device setup ${deviceSetup}, reconnected ${reconnected}`);
    if (!deviceSetup && !reconnected) {
      store.dispatch(setShowSettings(true));
    }

    // setup device
    await this.manageDevice();
  }

  async createAndJoinRoom() {
    const reload = checkReload();
    if (reload) {
      logger.log('Browser reload. Join room and Return...');
      const joinedRes = await this.joinRoomPromise();
      store.dispatch(setJoinedRoom(joinedRes.ok));
      return;
    }
    const isDev = this._roomId.startsWith('dev:');
    function bypassRoomLock(roomLock) {
      const { enabled, isActive, mayBypass } = roomLock;
      return !enabled || !isActive || mayBypass;
    }

    try {
      const res = await createRoom(
        this._roomId,
        this._roomToken,
        store.dispatch
      );
      if (res?.data?.roomLock) {
        const { enabled, isActive, pendingRequestPeerIds } =
          res.data.roomLock || {};

        if (!bypassRoomLock(res.data.roomLock || {})) {
          this._navigate(`/video/${this._roomId}/waiting-room`);
          throw new Error('Room is locked');
        }

        store.dispatch(setIsRoomLockEnabled(enabled));
        if (enabled) {
          store.dispatch(setIsRoomLocked(isActive));
          if (isActive && pendingRequestPeerIds?.length) {
            store.dispatch(
              batchAddRequests(
                pendingRequestPeerIds.map((peerId) => ({ peerId }))
              )
            );
          }
        }
      }
    } catch (err) {
      const { status, data } = err?.response || {};
      logger.log('Response status is', err?.response);
      if (status === 401) {
        if (isDev) {
          // Clear token
          store.dispatch(unsetToken());
          // This is only used in development, where it is ok to have a bruter handling of state changing
          store.dispatch(setConnectionState(ConnectionState.UNINITIALIZED));
        } else {
          this._navigate('/unauthorized');
          throw new Error('Unauthorized token');
        }
      }

      // 409 means the room already exists, so that is acceptable
      if (status !== 409) {
        // This will typically happen if the network or server is down
        throw new Error('Invalid response', err.response.status);
      }

      const { enabled, isActive } = data?.roomLock || {};
      setIsRoomLockEnabled(enabled);
      store.dispatch(setIsRoomLocked(isActive));
      if (!bypassRoomLock(data.roomLock || {})) {
        this._navigate(`/video/${this._roomId}/waiting-room`);
        throw new Error('Room is locked');
      }
    }

    // emit joinRoom
    // will be caught in outer try/catch if there are errors
    const joinedRes = await this.joinRoomPromise();
    store.dispatch(setJoinedRoom(joinedRes.ok));
  }

  joinRoomPromise() {
    return new Promise((resolve, reject) => {
      this.request('joinRoom', this._roomId).then((joinedRes) => {
        logger.log('JOIN ROOM RES', joinedRes);

        if (!joinedRes.ok) {
          if (joinedRes.code === 529) {
            this._navigate('/full');
            reject(joinedRes); // Reject the Promise in case of an error
          } else {
            track(
              'client-error',
              { error: 'Invalid joinedRes', joinedRes },
              this._roomToken
            );
            reject(joinedRes); // Reject the Promise in case of an error
          }
        }

        resolve(joinedRes); // Resolve the Promise with joinedRes
      });
    });
  }

  async manageDevice() {
    const device = await this.setupDevice();
    if (!device) {
      track('client-error', { error: 'no device' }, this._roomToken);
      logger.error('NO DEVICE');
      createSnack(
        store.dispatch,
        this._translator(
          'Error setting up device, please refresh page and try again'
        ),
        'error'
      );
      throw new Error('No device');
    }
    logger.log('Got device');
  }

  async setupConnection() {
    const userId = store.getState().user.userId;
    if (this.connectionSetup) {
      logger.log('Connection already setup');
      return;
    }
    await this.startTransports();

    this.updateMembersList();

    // start streams
    await this.startStreams();

    // Fetch statusData before adding listeners to guard against peers joining at the same time
    const statusData = await this.request('getStatus', this._roomId);
    // Handle statusData
    if (!statusData) {
      throw new Error('No status data');
    }
    logger.log('STATUS DATA IS', userId, statusData);
    this.handleStatus(statusData);

    // Request new consumer counts when everything is setup
    // roomClient.request('requestConsumerCount');
    this.connectionSetup = true;
  }

  async startStreams() {
    const deviceSettings = store.getState().device.deviceSettings;
    const maxWidth = store.getState().layout.maxWidth;
    const maxHeight = store.getState().layout.maxHeight;
    const isMobile = maxWidth <= 780 || maxHeight <= 520;
    const hideVideo = store.getState().control.hideVideo;
    const clientOptions = store.getState().user.clientOptions;
    if (!this.videoProducer && deviceSettings.videoDevice !== 'nocam') {
      logger.log('Inside start streams, device is', this.device);
      const videoStream = await this.streamHandler.getVideoStream({
        device: this.device,
        token: this._roomToken,
        videoDevice: deviceSettings.videoDevice,
        isMobile,
        producing: true,
      });
      if (videoStream) {
        const talkingDetector = new TalkingDetector(
          videoStream,
          !isMobile && ALLOW_TALKING_DETECTION
        );
        await talkingDetector.load();
        this._talkingDetector = talkingDetector;
        if (!hideVideo) {
          logger.log('Going to start video producer');

          await this.startVideoProducer(
            videoStream,
            deviceSettings,
            clientOptions
          );

          this.onToggleVideo();
        } else {
          this.streamHandler.stopVideoTracks(deviceSettings.videoDevice, true);
        }
      } else {
        createSnack(
          store.dispatch,
          this._translator('Could not access camera'),
          'error'
        );
      }
    }

    if (deviceSettings.audioDevice === 'nomic') {
      // forcefully muted if no mic
      store.dispatch(setMuted(true));
      await this.mute();
    }
    if (!this.audioProducer && deviceSettings.audioDevice !== 'nomic') {
      const audioStream = await this.streamHandler.getAudioStream({
        device: this.device,
        token: this._roomToken,
        audioDevice: deviceSettings.audioDevice,
        isMobile,
        producing: true,
      });
      if (audioStream) {
        if (deviceSettings.audioDevice !== 'nomic') {
          await this.startAudioProducer(audioStream, deviceSettings);

          await this.toggleAudio();
          this.addAudioListener();
        }
      } else {
        createSnack(
          store.dispatch,
          this._translator('Could not access microphone'),
          'error'
        );
      }
    }
  }

  async handleStatus(statusData) {
    const userId = store.getState().user.userId;
    const clientOptions = store.getState().user.clientOptions;
    statusData.peers.forEach((peer) => {
      if (peer.id === userId) return;
      logger.log('Going to add existing peer', peer);
      peer.producers.forEach(async (producer) => {
        // Guard against newProducer event triggers right at the same time as getStatus

        if (
          store.getState().room.peers[peer.id] &&
          (store.getState().room.peers[peer.id]['audio']?.producer?.id ===
            producer.id ||
            store.getState().room.peers[peer.id]['video']?.producer?.id ===
              producer.id)
        ) {
          logger.log(
            "Don't do anything to avoid a race condition in handle status!"
          );
          return;
        }
        const { stream, consumer } = await this.consume(producer.id);
        let newProducer = {
          stream,
          producer,
          consumer,
          show: true,
          peerId: peer.id,
        };
        store.dispatch(addPeer({ data: newProducer, kind: producer.kind }));
      });
    });
    store.dispatch(removeMember(userId));
    store.dispatch(setRaisedHands(statusData?.raisedHands || {}));
    store.dispatch(setRoomData({ ...statusData?.options }));
    store.dispatch(setEnableMetricsCollection(!!statusData?.metricsCollection));
    store.dispatch(setPeersMuted(statusData?.peersMuted));
    store.dispatch(setRecording(statusData?.recording?.owner));
    store.dispatch(setRecordingStartTime(statusData?.recording?.createdAt));
    const { roomLock } = statusData;
    if (roomLock) {
      store.dispatch(setIsRoomLockEnabled(roomLock?.enabled));
      store.dispatch(setIsRoomLocked(roomLock?.isActive));
    }

    if (statusData?.peersMuted?.[userId] || clientOptions?.startAsMuted) {
      store.dispatch(setMuted(true));
    }

    store.dispatch(setDominantSpeaker(statusData.dominantSpeaker));
    if (statusData.shareData) {
      const consumers = await Promise.all(
        statusData.shareData.producers.map(async (producer) => {
          const { stream, consumer } = await this.consume(producer.id);
          return { producer, stream, consumer };
        })
      );

      store.dispatch(
        setShareData({
          producers: consumers.map((x) => {
            return {
              stream: x.stream,
              producerId: x.producer.id,
              kind: x.producer.kind,
              consumer: x.consumer,
            };
          }),
          peerId: statusData.shareData.peerId,
        })
      );
      store.dispatch(setSharingScreen('other'));
      this.preShareLayout = store.getState().layout.viewLayout;
      store.dispatch(setViewLayout('spotlight'));
    }
  }

  async onToggleVideo() {
    const hideVideo = store.getState().control.hideVideo;
    store.dispatch(setVideoLoading(true));
    await this.toggleVideo();
    store.dispatch(setVideoLoading(false));
    await track('hideVideoToggled', { hideVideo }, this._roomToken);
  }

  addAudioListener() {
    this.audioManipulator.activateVad();
    this.audioManipulator.removeAllListeners('voice');
    this.audioManipulator.on('voice', (val) => {
      store.dispatch(setMicSound(val));
    });
  }

  async onPeerMute(peer) {
    const userId = store.getState().user.userId;
    if (peer.id === userId) {
      store.dispatch(toggleMuted());
    } else {
      const ok = await showConfirm({
        title: this._translator('Are you sure?'),
        message: this._translator(
          'This will mute the user for all participants. Only the user can turn on the microphone again.'
        ),
      });
      if (ok) {
        this.send({ action: 'forceMute', peerId: peer.id });
      }
    }
  }

  async onNewDeviceSettings(settings) {
    logger.log('Going to check new device settings', settings);
    const token = store.getState().user.token;
    const deviceSetup = store.getState().device.deviceSetup;
    const maxWidth = store.getState().layout.maxWidth;
    const maxHeight = store.getState().layout.maxHeight;
    const isMobile = maxWidth <= 780 || maxHeight <= 520;
    const connectionState = store.getState().room.connectionState;
    const clientOptions = store.getState().user.clientOptions;
    const prevSettings = getStorageItem(localStorage, 'deviceSettings', {});

    const differingKeys = getObjectKeysWithDifferences(settings, prevSettings);
    if (differingKeys.length === 0) return null;

    const audioKeys = ['audioDevice', 'audioManipulation'];
    const audioChanges = audioKeys.some((item) => differingKeys.includes(item));

    const videoKeys = ['videoDevice', 'videoEffect'];
    const videoChanges = videoKeys.some((item) => differingKeys.includes(item));

    let videoEffect;
    if (settings?.videoEffect)
      videoEffect = JSON.parse(JSON.stringify(settings.videoEffect));
    if (differingKeys.includes('videoEffect')) {
      if (settings?.videoEffect) {
        await track('videoEffectApplied', { videoEffect }, token);
      } else if (prevSettings?.videoEffect) {
        await track('videoEffectCleared', {}, token);
      }
    }

    setStorageItem(localStorage, 'deviceSettings', {
      videoEffect,
      videoDevice: settings.videoDevice,
      audioDevice: settings.audioDevice,
      outputDevice: settings.outputDevice,
      audioManipulation: settings.audioManipulation,
    });

    store.dispatch(setDeviceSettings(settings));

    /* If connectionState has not reached CONNECTED and deviceSetup it is to
     early to start streams so we will exit early */
    if (connectionState !== ConnectionState.CONNECTED || !deviceSetup) return;

    const videoStream = await this.streamHandler.getVideoStream({
      device: this.device,
      token,
      videoDevice: settings.videoDevice,
      isMobile,
      producing: true,
    });

    if (!videoStream) {
      this.videoManipulator?.destroy();
      this.videoManipulator = null;
      if (this.videoProducer) {
        this.videoProducer?.close();
        await this.request('closeProducer', {
          producerId: this.videoProducer.id,
        });
        this.videoProducer = null;
      }
    }

    if (videoStream && settings.videoDevice && videoChanges) {
      if (this.videoManipulator) {
        this.videoManipulator.destroy();
        this.videoManipulator = null;
      }
      if (this.videoProducer) {
        this.videoProducer?.close();
        await this.request('closeProducer', {
          producerId: this.videoProducer.id,
        });
        this.videoProducer = null;
      }
      if (settings.videoDevice !== 'nocam') {
        await this.startVideoProducer(videoStream, settings, clientOptions);

        //  setRenderIdx((idx) => idx + 1);
      } else {
        if (this.videoProducer) {
          this.videoProducer.close();
          await this.request('closeProducer', {
            producerId: this.videoProducer.id,
          });
          this.videoProducer = null;
        }
      }
    }
    const audioStream = await this.streamHandler.getAudioStream({
      device: this.device,
      token,
      audioDevice: settings.audioDevice,
      isMobile,
      producing: true,
    });
    if (!audioStream) {
      this.audioManipulator?.destroy();
      this.audioManipulator = null;
      if (this.audioProducer) {
        this.audioProducer?.close();
        await this.request('closeProducer', {
          producerId: this.audioProducer.id,
        });
        this.audioProducer = null;
      }
    }
    if (audioStream && settings.audioDevice && audioChanges) {
      if (this.audioManipulator) {
        this.audioManipulator.destroy();
        this.audioManipulator = null;
      }
      if (settings.audioDevice !== 'nomic') {
        await this.startAudioProducer(audioStream, settings);
        await this.toggleAudio();
        this.addAudioListener();
      } else {
        if (this.audioProducer) {
          this.audioProducer.close();
          await this.request('closeProducer', {
            producerId: this.audioProducer.id,
          });
          this.audioProducer = null;
        }
      }
    }
  }

  async onCloseSettings(newSettings) {
    if (newSettings) {
      await this.onNewDeviceSettings(newSettings);
    }
    this.streamHandler.cleanup();
    requestNotificationPermission();
    store.dispatch(setShowSettings(false));
    setTimeout(() => {
      store.dispatch(setDeviceSetup(true));
    }, 200);
  }

  onRaiseHand() {
    const handRaised = store.getState().control.handRaised;
    this.send({ action: 'raiseHand', status: !handRaised });
    store.dispatch(setHandRaised(!handRaised));
  }

  onToggleRecording({ transcribe, playback } = {}) {
    const recording = store.getState().record.recording;
    const userId = store.getState().user.userId;
    if (recording && recording === userId) {
      this.send({ action: 'stopRecording' });
      store.dispatch(setRecording(null));
      store.dispatch(setRecordingStartTime(null));
    } else {
      if (transcribe || playback) {
        this.send({
          action: 'startRecording',
          options: {
            transcribe,
            playback,
          },
        });
        store.dispatch(setRecording(userId));
        store.dispatch(setRecordingStartTime(Date.now()));
      }
    }
  }

  onNPSClose() {
    const showNPS = store.getState().room.showNPS;
    let navLocation = '/bye';
    if (showNPS === 'kicked') {
      navLocation = '/kicked';
    } else if (showNPS === 'closed') {
      navLocation = '/closed';
    }
    store.dispatch(setShowNPS(false));
    store.dispatch(setJoinedRoom(false));
    window.close();
    this._navigate(navLocation);
  }

  async onLeave() {
    const token = store.getState().user.token;
    const userId = store.getState().user.userId;
    this.kicked = true;
    await kickSelf(token, store.dispatch);
    store.dispatch(setJoinedRoom(false));
    this.leaveRoom();
    store.dispatch(setShowNPS(true));
    this.sendWindowMessage('leaveRoom', { peerId: userId });
  }
}

export default RoomClient;
