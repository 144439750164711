import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RecordingDialog from '../video/RecordingDialog';
import Control from './Control';

export default function RecordingSelector({
  onToggleRecording,
  isMobile,
  recording,
  isSelfRecording,
}) {
  const { t } = useTranslation();
  const [recordingDialogOpen, setRecordingDialogOpen] = useState(false);

  function onClose(params) {
    if (!params) {
      setRecordingDialogOpen(false);
      return;
    }
    const { transcribe, playback } = params;
    setRecordingDialogOpen(false);
    if (recording || transcribe || playback) {
      onToggleRecording({ transcribe, playback });
    }
  }

  function onClick() {
    setRecordingDialogOpen(!recordingDialogOpen);
  }

  return (
    <>
      <RecordingDialog
        isMobile={isMobile}
        open={recordingDialogOpen}
        onClose={onClose}
        recording={recording}
      />
      <Control
        isMobile={isMobile}
        label={t('Record')}
        ariaLabel="share"
        onClick={onClick}
        icon={recording ? 'record' : 'recordOutlined'}
        color={recording ? 'danger' : 'white'}
        disabled={recording && !isSelfRecording}
      />
    </>
  );
}
