import { BehaviorSubject } from 'rxjs';

const subject$ = new BehaviorSubject(null);

export function showConfirm({ message, title }) {
  return new Promise((resolve, reject) => {
    function cb(res) {
      subject$.next(null);
      resolve(res);
    }
    subject$.next({ message, title, cb });
  });
}

export function getSubject() {
  return subject$;
}
