import { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Home from './Home';
import Preflight from './Preflight';
import './/Hero.css';
import RoomContext from '../../lib/RoomClientContext';
import RoomClient from '../../lib/RoomClient';

const theme = createTheme({
  palette: {
    primary: {
      main: '#88DECB',
    },
    secondary: {
      main: '#E3D7FF',
    },
  },
});

function PreflightHero() {
  const [currentStep, setCurrentStep] = useState(0);
  // preflight uses a smaller scope of the RoomClient
  const roomClient = new RoomClient({ videoScope: false });
  return (
    <ThemeProvider theme={theme}>
      <div className="preflight">
        {currentStep === 0 ? (
          <Home nextStep={() => setCurrentStep(1)} />
        ) : (
          <RoomContext.Provider value={roomClient}>
            <Preflight />
          </RoomContext.Provider>
        )}
      </div>
    </ThemeProvider>
  );
}

export default PreflightHero;
