import axios from 'axios';
import * as Sentry from '@sentry/react';
import { createSnack } from '../actions/SnackActions';
import i18next from '../i18n';

const ROOT_URL = '/api';
const CANCEL_TOKENS = {};

export function request(params, token, dispatch, skipSnack = false) {
  const url = ROOT_URL + params.url;
  let headers = {};
  if (token) {
    headers = {
      ...(params.headers || {}),
      Authorization: `Bearer ${token}`,
    };
  }

  return axios({
    ...params,
    url,
    headers,
  }).catch((e) => {
    if (!axios.isCancel(e)) {
      const res = e.response;
      if (res?.status === 401) {
        if (dispatch) {
          createSnack(dispatch, i18next.t('You are not authorized'), 'error');
        }
      } else if (res?.status === 400 || res?.status === 403) {
        const errMsg = res?.data?.error?.details;

        if (typeof errMsg === 'string') createSnack(`Fel: ${errMsg}`);
        else if (dispatch)
          createSnack(dispatch, i18next.t('An error occurred'));
      } else if (!skipSnack) {
        if (dispatch) {
          createSnack(dispatch, i18next.t('An error occurred'));
        }
      }

      try {
        e.message += `: ${url}`;
        throw e;
      } catch (err) {
        Sentry.withScope((scope) => {
          scope.setFingerprint(['{{ url.split(":")[0]]) }}']);
          Sentry.captureException(err);
        });
        throw err;
      }
    }
  });
}

export function get(
  url,
  { token, skipSnack, ...params } = {},
  dispatch,
  cancelToken = null
) {
  return request(
    {
      url,
      method: 'GET',
      cancelToken,
      ...params,
    },
    token,
    dispatch,
    skipSnack
  );
}

export function post(
  url,
  data,
  dispatch,
  { token, skipSnack, ...params } = {}
) {
  return request(
    {
      url,
      data,
      method: 'POST',
      ...params,
    },
    token,
    dispatch,
    skipSnack
  );
}

export function put(url, data, dispatch, { token, skipSnack, ...params } = {}) {
  return request(
    {
      url,
      data,
      method: 'PUT',
      ...params,
    },
    token,
    dispatch,
    skipSnack
  );
}

export function patch(
  url,
  data,
  dispatch,
  { token, skipSnack, ...params } = {}
) {
  return request(
    {
      url,
      data,
      method: 'PATCH',
      ...params,
    },
    token,
    dispatch,
    skipSnack
  );
}

export function del(url, data, dispatch, { token, skipSnack, ...params } = {}) {
  return request(
    {
      url,
      data,
      method: 'DELETE',
      ...params,
    },
    token,
    dispatch,
    skipSnack
  );
}

export function addCtCheck(key) {
  const t = axios.CancelToken.source();
  CANCEL_TOKENS[key] = t;
  return t.token;
}

export function ctExists(key) {
  return key in CANCEL_TOKENS;
}

export function cancelCalls(key) {
  const ct = CANCEL_TOKENS?.[key];
  if (!ct) return false;
  ct.cancel(`${key}: anrop avbrutet`);
  delete CANCEL_TOKENS[key];
  return true;
}
