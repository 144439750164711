import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import styles from './VideoSettingsDialog.module.css';
import { languages } from '../../../lang';

export default function VideoSettingsLanguage() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={styles.selectContainer}>
        <div className={styles.selectLabel}>{t('Language')}</div>
        <Select
          value={i18n.language}
          onChange={(e) => i18n.changeLanguage(e.target.value)}
          className={styles.select}
        >
          {Object.entries(languages).map(([key, language]) => (
            <MenuItem key={key} value={key}>
              {t(language.name)}
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  );
}
