import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './RecordingDialog.module.css';
function RecordingDialog({ isMobile, open, onClose, recording }) {
  const { t } = useTranslation();
  const [transcribe, setTranscribe] = useState(false);
  const [playback, setPlayback] = useState(true);

  return (
    <Dialog
      PaperProps={{
        className: classnames(styles.dialog, { [styles.isMobile]: isMobile }),
      }}
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle>
        {recording ? t('Stop recording') : t('Start recording')}
      </DialogTitle>
      <DialogContent>
        <div className={styles.container}>
          <div className={styles.content}>
            {recording ? (
              <p>{t('Are you sure?')}</p>
            ) : (
              <>
                <p>
                  {t(
                    'Before you start a recording, make sure that all participants have given their consent to be recorded.'
                  )}
                </p>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={playback}
                      onChange={(e) => setPlayback(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={t('Record')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={transcribe}
                      onChange={(e) => setTranscribe(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={t('Start a transcript')}
                />
              </>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="white"
          className={styles.button}
          onClick={() => onClose()}
        >
          {t('Cancel')}
        </Button>
        <Button
          className={styles.button}
          variant="contained"
          color="white"
          onClick={() => onClose({ playback, transcribe })}
          disabled={false}
          autoFocus
        >
          {recording ? t('Confirm') : t('Start')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RecordingDialog;
