import React, { useEffect } from 'react';
import classnames from 'classnames';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { clearLast } from '../store/slices/snackSlice';
import styles from './SnackBar.module.css';
import { Links } from './chat/Chat';
import { removeRequest } from '../store/slices/requestSlice';
import { clearOldSnacks } from '../store/slices/snackSlice';
import { IconButton } from '@mui/material';
import { Icon } from '../components/Icons';
import { Check as AllowIcon, Close as DenyIcon } from '@mui/icons-material';
import { acceptRequest, denyRequest } from '../actions/RequestActions';
import { setShowSidebars } from '../store/slices/layoutSlice';
import { withRoomContext } from '../lib/RoomClientContext';

function SnackbarComponent({ leftCollapse, roomClient }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snacks = useSelector((state) => state.snack.snacks);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(clearOldSnacks());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  function createSnack(snack, index, origin) {
    let action;
    let message = snack.msg;

    if (snack.customSnack === 'chat') {
      message = (
        <div style={{ maxWidth: 200 }}>
          <p style={{ margin: 0 }}>{snack.msg.sender || t('Unknown')}</p>
          <p style={{ wordWrap: 'break-word', margin: 0, fontWeight: '300' }}>
            <Links text={snack.msg.msg} maxLength={200} />
          </p>
        </div>
      );
      action = (
        <Button
          variant="contained"
          color="white"
          onClick={() => {
            dispatch(setShowSidebars({ side: 'right', show: true }));
            dispatch(clearLast());
          }}
        >
          {t('Respond')}
        </Button>
      );
    } else if (snack.customSnack === 'request') {
      action = (
        <>
          <IconButton
            size="small"
            color="success"
            aria-label="allow"
            onClick={() => {
              acceptRequest(snack.payload, roomClient);
              dispatch(removeRequest(snack.payload.peerId));
              dispatch(clearLast());
            }}
          >
            <AllowIcon />
          </IconButton>
          <IconButton
            size="small"
            color="error"
            aria-label="deny"
            onClick={() => {
              denyRequest(snack.payload, roomClient);
              dispatch(removeRequest(snack.payload.peerId));
              dispatch(clearLast());
            }}
          >
            <DenyIcon />
          </IconButton>
        </>
      );
    } else if (snack.customSnack === 'raised-hand') {
      message = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{snack.msg}</span>
          <Icon name="raisedHand" style={{ marginLeft: '0.6rem' }} />
        </div>
      );
    }

    return (
      <Snackbar
        className={classnames(styles.snackbar, {
          [styles.right]: origin.horizontal === 'right',
          [styles.leftCollapse]: leftCollapse,
        })}
        key={index}
        anchorOrigin={origin}
        open={true}
        message={message}
        action={action}
      />
    );
  }

  if (!snacks?.length) return null;
  const left = snacks
    .filter((s) => !s.anchorOrigin || s.anchorOrigin.horizontal === 'left')
    .slice(-1);
  const right = snacks
    .filter((s) => s.anchorOrigin?.horizontal === 'right')
    .slice(-1);
  return (
    <>
      {left.map((snack, index) =>
        createSnack(snack, index, { vertical: 'top', horizontal: 'left' })
      )}
      {right.map((snack, index) =>
        createSnack(snack, index, { vertical: 'top', horizontal: 'right' })
      )}
    </>
  );
}

export default withRoomContext(SnackbarComponent);
