import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  Typography as Text,
} from '@mui/material';
import { Check as AllowIcon, Close as DenyIcon } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { removeRequest } from '../../store/slices/requestSlice';
import { withRoomContext } from '../../lib/RoomClientContext';

function RequestList({ roomId, roomClient }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const requests = useSelector((state) => state.request.requests);

  const Actions = useCallback(
    ({ onAccept, onDeny }) => (
      <>
        <IconButton
          size="small"
          color="success"
          aria-label="allow"
          onClick={onAccept}
        >
          <AllowIcon />
        </IconButton>
        <IconButton
          size="small"
          color="error"
          aria-label="deny"
          onClick={onDeny}
        >
          <DenyIcon />
        </IconButton>
      </>
    ),
    [t]
  );

  const open = (e) => setAnchorEl(e.currentTarget);
  const close = () => setAnchorEl(null);

  const menuSx = {
    minWidth: 300,
  };

  const badgeSx = {
    '& .MuiBadge-badge': {
      bottom: -16,
      top: 'unset',
    },
  };

  return (
    <>
      <Badge
        badgeContent={requests.length}
        color="primary"
        invisible={requests.length < -1}
        sx={badgeSx}
      >
        <Button color="white" onClick={open}>
          {t('Requests')}
        </Button>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={close}
        sx={menuSx}
      >
        {requests.length > 0 ? (
          <List>
            {requests.map((request) => (
              <ListItem
                key={request.peerId}
                secondaryAction={
                  <Actions
                    request={request}
                    onAccept={() => {
                      roomClient.acceptRequest({
                        roomId,
                        peerId: request.peerId,
                      });

                      dispatch(removeRequest(request.peerId));
                    }}
                    onDeny={() => {
                      roomClient.denyRequest({
                        roomId,
                        peerId: request.peerId,
                      });
                      dispatch(removeRequest(request.peerId));
                    }}
                  />
                }
              >
                <ListItemText
                  primary={t('{{name}} wants to join your room', {
                    name: request?.name,
                  })}
                  sx={{ pr: 4.5 }}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Text sx={{ px: 1 }}>{t('No requests')}</Text>
        )}
      </Menu>
    </>
  );
}

export default withRoomContext(RequestList);
