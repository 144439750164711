import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './BrowserNotSupported.module.css';
import { browsers } from '../../utils/browserVersions';

function SupportedBrowserList() {
  const { t } = useTranslation();

  return (
    <div className={styles.contentWr}>
      {browsers.map((browser, index) => (
        <div className={styles.content} key={index}>
          <div>
            <img
              src={browser.icon}
              alt={`${browser.name} Icon`}
              width={24}
              height={24}
            />
            <p>
              {browser.name} {browser.info && <span>({t(browser.info)})</span>}
            </p>
          </div>
          <p>
            {t('Version')} {browser.minVersion} {t('or higher')}
          </p>
        </div>
      ))}
    </div>
  );
}

export default SupportedBrowserList;
