import React, { useState, useEffect, useRef, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icons';
import { useDispatch, useSelector } from 'react-redux';
import { setChat, addChatMessage } from '../../store/slices/chatSlice';
import { createSnack } from '../../actions/SnackActions';

import classnames from 'classnames';
import styles from './Chat.module.css';
import { withRoomContext } from '../../lib/RoomClientContext';

// Return HH:mm
function getTime(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
}

export function Links({ text, maxLength }) {
  const words = text.split(' ');
  let elements = [];
  let length = 0;
  for (let i = 0; i < words.length; i++) {
    const word =
      maxLength && words[i].length + length > maxLength
        ? `${words[i].substring(0, maxLength - length)}...`
        : words[i];
    const del = i === 0 ? '' : ' ';
    if (word.startsWith('http')) {
      elements.push(
        <>
          {del}
          <a key={i} href={words[i]} target="_blank" rel="noreferrer">
            {word}
          </a>
        </>
      );
    } else {
      elements.push(
        <span key={i}>
          {del}
          {word}
        </span>
      );
    }
    length += word.length;
    if (maxLength && length >= maxLength) {
      break;
    }
  }

  return <>{elements}</>;
}

function ChatRow({ userId, row, t }) {
  const isSelf = userId === row.peerId;
  return (
    <div
      className={classnames(styles.chatRow, { [styles.chatRowSelf]: isSelf })}
    >
      <div className={styles.chatRowName}>
        <p>
          <b>{row.peerId === userId ? t('You') : row.sender || t('Unknown')}</b>
          <span>
            {t('at')} {getTime(row.time)}
          </span>
        </p>
      </div>
      <div className={styles.chatRowMessage}>
        <p>
          <Links text={row.msg} />
        </p>
      </div>
    </div>
  );
}

function Chat({ open, roomId, onClose, roomClient }) {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const chat = useSelector((state) => state.chat.chat);
  const members = useSelector((state) => state.member.members);
  const { t } = useTranslation();
  const chatRef = useRef();
  const inputRef = useRef();
  const [message, setMessage] = useState('');

  const membersMap = useMemo(() => {
    return members.reduce((acc, member) => {
      acc[member.id] = member;
      return acc;
    }, {});
  }, [members]);

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    (async () => {
      const chat = await roomClient.request('getChat', roomId);
      if (chat) {
        dispatch(setChat(chat));
      } else {
        dispatch(setChat([]));
      }
    })();

    return () => {};
  }, [roomId, open]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [chat]);

  async function submit(e) {
    e.preventDefault();
    if (message) {
      const sender = membersMap[userId]?.userData?.name;
      await roomClient.request('chat', { msg: message, sender });
      setMessage('');
    }
  }

  return (
    <div className={styles.container}>
      <ListItemButton className={styles.header} onClick={onClose}>
        <ListItemIcon className={styles.icon}>
          <Icon width={28} height={28} name="message" />
        </ListItemIcon>
        <ListItemText className={styles.text} primary={t('Chat')} />
        <ListItemIcon style={{ marginLeft: 'auto' }} className={styles.icon}>
          <Icon width={32} height={32} name="cross" />
        </ListItemIcon>
      </ListItemButton>
      <div ref={chatRef} className={styles.chat}>
        {chat.map((row, i) => (
          <ChatRow key={i} row={row} userId={userId} t={t} />
        ))}
      </div>
      <form className={styles.form} onSubmit={submit}>
        <TextField
          inputRef={inputRef}
          id="chat-text-input"
          className={styles.formInput}
          variant="filled"
          placeholder={t('Write a message')}
          value={message}
          autoComplete="off"
          onChange={(e) => setMessage(e.target.value)}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  id="chat-submit-button"
                  className={styles.formButton}
                  type="submit"
                >
                  <Icon name="send" color="gray" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </div>
  );
}

export default withRoomContext(Chat);
