import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Icon } from './Icons';
import { stringAvatar } from '../utils/avatar';
import { useSelector, useDispatch } from 'react-redux';
import { createSnack } from '../actions/SnackActions';
import { showConfirm } from '../utils/confirm';
import styles from './Lobby.module.css';
import {
  addMember,
  addToRequested,
  removeMember,
  setRoomMembers,
} from '../store/slices/lobbySlice';
import { withRoomContext } from '../lib/RoomClientContext';

function Lobby({ show, roomId, selfId, members, roomClient }) {
  const dispatch = useDispatch();
  const lobby = useSelector((state) => state.lobby.lobby);
  const requested = useSelector((state) => state.lobby.requested);
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const membersMap = useMemo(() => {
    return members.reduce((acc, member) => {
      acc[member.id] = member;
      return acc;
    }, {});
  }, [members]);

  useEffect(() => {
    dispatch(setRoomMembers(membersMap));
  }, [membersMap]);

  async function onAddLobbyMember(memberId) {
    const memberName = lobby[memberId].userData?.name || t('Unknown');
    const ok = await showConfirm({
      title: t('Are you sure?'),
      message: `${t('Are you sure you want to add')} ${memberName} ${t(
        'to the meeting?'
      )}`,
    });
    if (ok) {
      roomClient.send({ action: 'requestUserJoin', userId: memberId });
      dispatch(addToRequested(memberId));
    }
  }

  if (!show) return;

  const waiting = Object.values(lobby || {}).filter(
    (member) =>
      member?.userData?.name?.toLowerCase().includes(search.toLowerCase()) &&
      requested[member.userId] !== true &&
      !membersMap[member.userId]
  );

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <TextField
          color="white"
          id="search"
          placeholder={t('Search')}
          variant="outlined"
          value={search}
          sx={{ width: '100%' }}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon style={{ fill: 'rgba(0, 0, 0, 0.54)' }} name="search" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <List className={styles.list}>
        {waiting.length ? (
          waiting.map((member) => (
            <ListItem
              key={member.userId}
              secondaryAction={
                <IconButton
                  onClick={() => onAddLobbyMember(member.userId)}
                  edge="end"
                  aria-label="add"
                  color="success"
                >
                  <Icon name="userAdd" color="success" width={40} height={36} />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar
                  {...stringAvatar(member.userData?.name)}
                  style={{ fontSize: '1rem' }}
                />
              </ListItemAvatar>
              <ListItemText primary={member.userData?.name} />
            </ListItem>
          ))
        ) : (
          <div className={styles.empty}>
            <p>
              {search
                ? t('No matches found')
                : t('Currently nobody is waiting in the lobby')}
            </p>
          </div>
        )}
      </List>
    </div>
  );
}

export default withRoomContext(Lobby);
