import Card from '../../components/preflight/Card';
import Button from '@mui/material/Button';

const Home = ({ nextStep }) => {
  return (
    <Card
      heading="Run a pre call test"
      description="A quick test to ensure that your connection is working properly and there are no technical difficulties in the meeting"
      actions={
        <>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => nextStep()}
          >
            Run test
          </Button>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={() =>
              (window.location.href =
                'https://docs.compodium.com/docs/Troubleshooting/')
            }
          >
            Read more
          </Button>
        </>
      }
    />
  );
};

export default Home;
