import { getToken } from './token';
import { createRoomCheck, joinRoomPromise } from './room';
import StreamManager from './streams';
import { getDevice, getDeviceSettings } from './device';
import { handleGetUserMediaError, startVideoProducer } from '../media';

const unexpectedError = {
  title: 'Unexpected error',
  description:
    'Please try re-running the tests by refreshing. If the issue persists, please contact support.',
  color: 'error',
};
const connectionError = {
  title: "You're not able to connect to the server",
  description:
    'This is required to make meetings work. The reason for this might be your firewall blocking port 3478 or https traffic.',
  color: 'error',
};
const socketError = {
  title: "You're not able to connect to the websocket",
  description:
    'This is required to make meetings work. The reason for this might be your firewall blocking websocket connections.',
  color: 'error',
};
const deviceError = {
  title: 'You did not allow access to your webcam and microphone',
  description:
    'This is required to make meetings work. Please accept connection to your microphone and webcam.',
  color: 'error',
};
const streamError = {
  title: "You're not able to send or receive video and audio",
  description:
    'This is required to make meetings work. The reason for this might be your firewall blocking UDP traffic.',
  color: 'error',
};
const audioMissingError = {
  title: 'No microphone detected',
  description:
    'No microphone was detected. Please connect a microphone and try again.',
  color: 'error',
};
const videoMissingError = {
  title: 'No webcam detected',
  description: 'No webcam was detected. Please connect a webcam and try again.',
  color: 'error',
};
const videoError = {
  title: 'Could not access webcam',
  description:
    'Not able to access webcam. Please verify that your webcam is working and that it is not used by an other application before trying again.',
  color: 'error',
};
const knownErrors = {
  videoError,
  videoMissingError,
  audioMissingError,
  streamError,
  deviceError,
  socketError,
  connectionError,
  unexpectedError,
};

const roomId = 'preflightTest';
const streamTimeout = 5000;

function waitForConnectionStateChange(streamProducerTransport, timeout) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      streamProducerTransport.off('connectionstatechange', eventListener);
      resolve(false);
    }, timeout);

    const eventListener = (state) => {
      if (state === 'connected') {
        clearTimeout(timer);
        streamProducerTransport.off('connectionstatechange', eventListener);
        resolve(true);
      }
    };

    streamProducerTransport.on('connectionstatechange', eventListener);
  });
}

export const getAllChecks = (state) => {
  return [
    {
      setStatus: state.setTokenStatus,
      run: async (state, roomClient) => {
        const token = await getToken();
        if (token) {
          return { status: 'success', state: { token } };
        }
        return { status: 'error', error: connectionError };
      },
    },
    {
      setStatus: state.setSocketStatus,
      run: async (state, roomClient) => {
        try {
          await roomClient.init({ token: state.token, roomId });
          if (roomClient.socket.connected) {
            return { status: 'success' };
          }
        } catch {
          return { status: 'error', error: socketError };
        }
        // If the socket is not connected, return an error
        return { status: 'error', error: socketError };
      },
    },
    {
      setStatus: state.setPermission,
      run: async (state, roomClient) => {
        try {
          state.streamManager = new StreamManager(roomClient.streamHandler);
          try {
            const permission = await state.streamManager.checkPermission();
            if (permission === null) {
              return { status: 'error', error: unexpectedError };
            }
            if (!permission) {
              return { status: 'error', error: deviceError };
            }
          } catch (error) {
            handleGetUserMediaError(error, {
              onSystemRejection: () => {
                throw new Error(knownErrors.videoError.title);
              },
              onUserDenied: () => {
                throw new Error(knownErrors.deviceError.title);
              },
            });
          }
        } catch (error) {
          const knownError = Object.values(knownErrors).find(
            (e) => e.title === error.message
          );
          if (knownError) return { status: 'error', error: knownError };
          return { status: 'error', unexpectedError };
        }
        return { status: 'success' };
      },
    },
    {
      setStatus: state.setStreamStatus,
      run: async (state, roomClient) => {
        try {
          // To proceed the user must join the room
          const roomCreated = await createRoomCheck(state.token, roomId);
          if (!roomCreated) return { status: 'error', error: unexpectedError };
          const joinedRes = await joinRoomPromise(roomClient, roomId);
          if (!joinedRes.ok) return { status: 'error', error: unexpectedError };
          // Get the audio and video devices
          const devices = await roomClient.streamHandler.getDevices();
          state.devices = devices;

          // Check if audio and video input devices are available
          const kinds = devices.map((d) => d.kind);
          if (!kinds.includes('audioinput')) {
            return { status: 'error', error: audioMissingError };
          }
          if (!kinds.includes('videoinput')) {
            return { status: 'error', error: videoMissingError };
          }

          // Get audio and video devices
          const audioDevice = getDevice(state.devices, 'audioinput');
          const videoDevice = getDevice(state.devices, 'videoinput');

          // Setup device
          await roomClient.setupDevice({ audioDevice, videoDevice });

          // Start producer transport
          await roomClient.startTransports();

          // Wait for connection state change
          const connectionState = waitForConnectionStateChange(
            roomClient.producerTransport,
            streamTimeout
          );

          // Kill consumer transport
          roomClient.consumerTransport.close();

          // Get audio and video streams
          try {
            const audioStream = await state.streamManager.getAudioStream(
              roomClient.device,
              state.token,
              audioDevice
            );
          } catch (error) {
            handleGetUserMediaError(error, {
              onSystemRejection: () => {
                throw new Error(knownErrors.deviceError.title);
              },
              onUserDenied: () => {
                throw new Error(knownErrors.deviceError.title);
              },
            });
          }
          let videoStream;
          try {
            videoStream = await state.streamManager.getVideoStream(
              roomClient.device,
              state.token,
              videoDevice
            );
          } catch (error) {
            handleGetUserMediaError(error, {
              onSystemRejection: () => {
                throw new Error(knownErrors.videoError.title);
              },
              onCameraTaken: () => {
                throw new Error(knownErrors.videoError.title);
              },
              onUserDenied: () => {
                throw new Error(knownErrors.deviceError.title);
              },
            });
          }

          // Stop audio tracks
          state.streamManager.streamHandler.stopAudioTracks(audioDevice);

          // Create videoProducer

          await roomClient.startVideoProducer(videoStream, {
            ...getDeviceSettings(),
            videoEffect: null,
          });

          if (!roomClient.videoProducer)
            return { status: 'error', error: streamError };

          // Wait for connection result
          const connectionResult = await connectionState;
          if (!connectionResult) return { status: 'error', error: streamError };

          // Get videoProducer stats
          const stats = await roomClient.videoProducer.getStats();
          const transportStats = Array.from(stats.values()).filter(
            (report) => report.type === 'candidate-pair' && report.bytesSent
          );
          const success = transportStats[0].bytesSent > 0;

          // Close video tracks
          state.streamManager.streamHandler.stopVideoTracks(videoDevice);

          return {
            status: success ? 'success' : 'error',
            error: success ? null : streamError,
          };
        } catch (error) {
          const knownError = Object.values(knownErrors).find(
            (e) => e.title === error.message
          );
          if (knownError) return { status: 'error', error: knownError };
          return { status: 'error', unexpectedError };
        }
      },
    },
  ];
};
