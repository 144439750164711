import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';

import FileSelectorDialog from '../video/FileSelectorDialog';
import Control from './Control';

const options = ['file', 'screen'];

export default function ShareSelector({
  onToggleScreenShare,
  viewLayout,
  isMobile,
  sharingScreen,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileSelectorOpen, setFileSelectorOpen] = useState(false);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    if (sharingScreen) {
      onToggleScreenShare();
      setAnchorEl(null);
      return;
    }

    const video = document.createElement('video');
    if (video.captureStream || video.mozCaptureStream) {
      setAnchorEl(event.currentTarget);
    } else {
      onToggleScreenShare();
    }
  };

  function handleFileChange(file) {
    if (file) {
      onToggleScreenShare(false, file);
    }
    setFileSelectorOpen(false);
  }

  const handleMenuItemClick = (event, index) => {
    const option = options[index];
    if (option === 'screen') {
      onToggleScreenShare();
    } else {
      setFileSelectorOpen(true);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FileSelectorDialog
        isMobile={isMobile}
        open={fileSelectorOpen}
        onClose={handleFileChange}
      />
      <Control
        isMobile={isMobile}
        label={t('Toggle screen share')}
        ariaLabel="share"
        onClick={handleClickListItem}
        icon="shareScreen"
        color={sharingScreen ? 'danger' : 'white'}
      />
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            style={{ textTransform: 'capitalize' }}
            selected={option === viewLayout}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {t(`${option}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
