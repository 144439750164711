import { createSlice } from '@reduxjs/toolkit';
import logger from '../../utils/logger';

export const ConnectionState = {
  CLOSED: 'closed',
  CONFIGURED: 'configured',
  CONNECTED: 'connected',
  FAILED: 'failed',
  INITIALIZED: 'initialized',
  RECONNECTING: 'reconnecting',
  UNINITIALIZED: 'uninitialized', // initial state
};

export const roomSlice = createSlice({
  name: 'room',
  initialState: {
    connectionState: ConnectionState.UNINITIALIZED,
    peers: {},
    shareData: undefined,
    sharingScreen: null,
    joinedRoom: false,
    showNPS: false,
    isRoomLocked: false,
    isDuplicatePeer: false,
    isRoomLockEnabled: false,
    roomData: null,
    enableMetricsCollection: false,
  },
  reducers: {
    setConnectionState: (state, action) => {
      state.connectionState = action.payload;
    },
    setPeers: (state, action) => {
      state.peers = action.payload;
    },
    addPeer: (state, action) => {
      let updatedPeers = {
        ...state.peers,
        [action.payload.data.peerId]: {
          ...state.peers[action.payload.data.peerId],
          [action.payload.kind]: action.payload.data,
        },
      };
      logger.log('updated Peers in roomSlice are:', updatedPeers);
      state.peers = updatedPeers;
    },
    setShareData: (state, action) => {
      state.shareData = action.payload;
    },
    setSharingScreen: (state, action) => {
      state.sharingScreen = action.payload;
    },
    addShareDataProducer: (state, action) => {
      state.shareData = {
        producers: [
          ...(state.shareData?.producers || []),
          action.payload.producer,
        ],
        peerId: action.payload.peerId,
      };
    },
    setJoinedRoom: (state, action) => {
      state.joinedRoom = action.payload;
    },
    setShowNPS: (state, action) => {
      state.showNPS = action.payload;
    },
    setIsRoomLocked: (state, action) => {
      state.isRoomLocked = action.payload;
    },
    setIsDuplicatePeer: (state, action) => {
      state.isDuplicatePeer = action.payload;
    },
    setIsRoomLockEnabled: (state, action) => {
      state.isRoomLockEnabled = action.payload;
    },
    setRoomData: (state, action) => {
      state.roomData = action.payload;
    },
    setEnableMetricsCollection: (state, action) => {
      state.enableMetricsCollection = action.payload;
    },
  },
});

export const {
  setConnectionState,
  setPeers,
  addPeer,
  setShareData,
  setSharingScreen,
  addShareDataProducer,
  setJoinedRoom,
  setShowNPS,
  setIsRoomLocked,
  setIsDuplicatePeer,
  setIsRoomLockEnabled,
  setRoomData,
  setEnableMetricsCollection,
} = roomSlice.actions;

export default roomSlice.reducer;
