import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Icon } from '../../Icons';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styles from './VideoSettingsDialog.module.css';
import {
  toggleHideVideo,
  toggleMuted,
} from '../../../store/slices/controlSlice';

export default function VideoSettingsVA({
  videoDevice,
  setVideoDevice,
  audioDevice,
  setAudioDevice,
  outputDevice,
  setOutputDevice,
  deviceSettings,
  device,
  checkedPermissions,
  permissionsDenied,
  cameraTaken,
  requestPermissions,
  clientOptions,
  playTestAudio,
  stopTestAudio,
  audioLevel,
  streamHandler,
  availableVideoDevices,
  setAvailableVideoDevices,
  availableAudioDevices,
  setAvailableAudioDevices,
  availableOutputDevices,
  setAvailableOutputDevices,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [tryIdx, setTryIdx] = useState(0);
  const muted = useSelector((state) => state.control.muted);
  const hideVideo = useSelector((state) => state.control.hideVideo);
  const deviceSetup = useSelector((state) => state.device.deviceSetup);
  const showControls = !deviceSetup;

  useEffect(() => {
    if (!checkedPermissions) return;
    async function getDevices() {
      let devices = await streamHandler.getDevices();
      if (devices.every((d) => !d.label)) {
        // Firefox is super buggy with this behaviour, so we do some retries to make sure we get the devices
        if (tryIdx < 5) {
          setTimeout(() => {
            setTryIdx(tryIdx + 1);
          }, 100);
          return;
        }
      }
      const videoDevices = devices.filter((d) => d.kind === 'videoinput');
      const audioDevices = devices.filter((d) => d.kind === 'audioinput');
      const outputDevices = devices.filter((d) => d.kind === 'audiooutput');

      let selectableVideoDevices = clientOptions?.disableCamera
        ? []
        : videoDevices;
      let selectableAudioDevices = clientOptions?.disableMicrophone
        ? []
        : audioDevices;

      selectableVideoDevices =
        selectableVideoDevices.length > 0
          ? selectableVideoDevices
          : [{ label: t('No camera'), deviceId: 'nocam' }];
      selectableAudioDevices =
        selectableAudioDevices.length > 0
          ? selectableAudioDevices
          : [{ label: t('No microphone'), deviceId: 'nomic' }];

      setAvailableVideoDevices(selectableVideoDevices);
      setAvailableAudioDevices(selectableAudioDevices);

      setAvailableOutputDevices(
        outputDevices?.length
          ? outputDevices
          : [{ deviceId: 'notsupported', label: t('Default') }]
      );

      if (
        (!deviceSettings.videoDevice ||
          (deviceSettings.videoDevice !== 'nocam' &&
            !selectableVideoDevices.find(
              (x) => x.deviceId === deviceSettings.videoDevice
            ))) &&
        selectableVideoDevices.length > 0
      ) {
        setVideoDevice(selectableVideoDevices[0].deviceId);
      }

      if (
        (!deviceSettings.audioDevice ||
          (deviceSettings.audioDevice !== 'nomic' &&
            !selectableAudioDevices.find(
              (x) => x.deviceId === deviceSettings.audioDevice
            ))) &&
        selectableAudioDevices.length > 0
      ) {
        setAudioDevice(selectableAudioDevices[0].deviceId);
      }

      if (
        (!deviceSettings.outputDevice ||
          !outputDevices.find(
            (x) => x.deviceId === deviceSettings.outputDevice
          )) &&
        outputDevices.length > 0
      ) {
        setOutputDevice(outputDevices[0].deviceId);
      }
    }
    getDevices();
  }, [checkedPermissions, deviceSettings, tryIdx]);

  if (!checkedPermissions) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h4>{t('Grant access to camera and microphone')}</h4>
        <p>
          {t(
            'Before you join the meeting you need to grant the website access to your camera and microphone so that the other meeting participants can see and hear you.'
          )}
        </p>
        <Button
          variant="contained"
          color="newgreen"
          onClick={requestPermissions}
          style={{ alignSelf: 'center' }}
        >
          {t('Request permissions')}
        </Button>
      </div>
    );
  }

  return (
    <>
      {device &&
      device.canProduce('video') &&
      videoDevice &&
      !clientOptions?.disableCamera &&
      !cameraTaken ? (
        <div className={styles.selectContainer}>
          <div className={styles.selectLabel}>{t('Camera')}</div>
          <div className={styles.testContainer}>
            <Select
              value={
                availableVideoDevices.find((x) => x.deviceId === videoDevice)
                  ? videoDevice
                  : ''
              }
              onChange={(e) => setVideoDevice(e.target.value)}
              className={styles.select}
              id="video-options"
            >
              {availableVideoDevices.map((d) => (
                <MenuItem key={d.deviceId} value={d.deviceId}>
                  {d.label || d.deviceId}
                </MenuItem>
              ))}
            </Select>
            {showControls && (
              <Button
                className={styles.testButton}
                color="newgreen"
                variant="contained"
                onClick={() => {
                  dispatch(toggleHideVideo());
                }}
              >
                <Icon name={hideVideo ? 'videoOff' : 'videoOn'} color="white" />
              </Button>
            )}
          </div>
        </div>
      ) : (
        <p>
          {cameraTaken || clientOptions?.disableCamera
            ? t('The camera has been disabled')
            : permissionsDenied.video
            ? t('You have not given permission to use your camera')
            : t('Your browser does not support camera')}
        </p>
      )}
      {device &&
      device.canProduce('audio') &&
      audioDevice &&
      !clientOptions?.disableMicrophone ? (
        <div className={styles.selectContainer}>
          <div className={styles.selectLabel}>{t('Microphone')}</div>
          <div className={styles.testContainer}>
            <Select
              value={
                availableAudioDevices.find((x) => x.deviceId === audioDevice)
                  ? audioDevice
                  : ''
              }
              onChange={(e) => setAudioDevice(e.target.value)}
              className={styles.select}
            >
              {availableAudioDevices.map((d) => (
                <MenuItem key={d.deviceId} value={d.deviceId}>
                  {d.label || d.deviceId}
                </MenuItem>
              ))}
            </Select>
            {showControls && (
              <Button
                className={styles.testButton}
                color="newgreen"
                variant="contained"
                style={{
                  ...(!muted && {
                    backgroundImage: `linear-gradient(to top, rgba(38, 120, 109) ${audioLevel}%, rgba(30, 91, 83, 1) ${audioLevel}%)`,
                  }),
                }}
                onClick={() => {
                  dispatch(toggleMuted());
                }}
              >
                <Icon name={muted ? 'micOff' : 'micOn'} color="white" />
              </Button>
            )}
          </div>
        </div>
      ) : (
        <p>
          {permissionsDenied.audio
            ? t('You have not given permission to use your microphone')
            : clientOptions.disableMicrophone
            ? t('The microphone has been disabled')
            : t('Your browser does not support microphone')}
        </p>
      )}
      {outputDevice ? (
        <div className={styles.selectContainer}>
          <div className={styles.selectLabel}>{t('Speakers')}</div>
          <div className={styles.testContainer}>
            <Select
              value={outputDevice}
              onChange={(e) => {
                if (e.target.value === 'notsupported') return;
                setOutputDevice(e.target.value);
              }}
              className={styles.select}
            >
              {availableOutputDevices.map((d) => (
                <MenuItem key={d.deviceId} value={d.deviceId}>
                  {d.label || d.deviceId}
                </MenuItem>
              ))}
            </Select>
            <Button
              className={styles.testButton}
              color="newgreen"
              variant="contained"
              onClick={() => {
                stopTestAudio();
                setTimeout(() => {
                  playTestAudio();
                }, 20);
              }}
            >
              {t('Test')}
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
}
