import bowser from 'bowser';
import { browsers } from './browserVersions';

export function isBrowserSupported() {
  const browser = bowser.getParser(window.navigator.userAgent);

  for (const { name, minVersion } of browsers) {
    if (browser.satisfies({ [name.toLowerCase()]: `<${minVersion}` })) {
      return false;
    }
  }

  return true;
}

export function checkSafari() {
  const browser = bowser.getParser(window.navigator.userAgent);
  return browser.getBrowserName() === 'Safari';
}

export function checkFirefox() {
  const browser = bowser.getParser(window.navigator.userAgent);
  return browser.getBrowserName() === 'Firefox';
}

export function checkChrome() {
  // This also catches Chromium based browsers. To accommodate this, we rely on testing for the browser engine 'Blink' instead. Recent versions of Safari adapt some aspects of the Blink engine, so we will exclude Safari explicitly.
  const browser = bowser.getParser(window.navigator.userAgent);
  return !checkSafari() && browser.getEngineName() === 'Blink';
}
