import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icons';
import styles from './MembersList.module.css';
import { kickMember } from '../../actions/RoomActions';
import { useDispatch, useSelector } from 'react-redux';

import { stringAvatar } from '../../utils/avatar';
import { setPinned } from '../../store/slices/layoutSlice';
import { withRoomContext } from '../../lib/RoomClientContext';

function MembersList({
  onPeerMute,
  onRaiseHand,
  roomId,
  isAdmin,
  onClose,
  roomClient,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.userId);
  const handRaised = useSelector((state) => state.control.handRaised);
  const pinned = useSelector((state) => state.layout.pinned);
  const members = useSelector((state) => state.member.members);
  const raisedHands = useSelector((state) => state.member.raisedHands);
  const peersMuted = useSelector((state) => state.member.peersMuted);
  const audioDevice = useSelector(
    (state) => state.device.deviceSettings.audioDevice
  );

  async function forceLowerHand(peerId) {
    roomClient.send({ action: 'forceLowerHand', peerId });
  }

  function lowerHand(peerId) {
    if (peerId !== userId && isAdmin) {
      forceLowerHand(peerId);
    } else if (peerId === userId) {
      onRaiseHand();
    }
  }

  const nonGhosts = members.filter((m) => !m.isGhost);
  const ghosts = members.filter((m) => m.isGhost);
  const iconSize = 20;

  const renderMicIconOrButton = (member) => {
    const isCurrentUser = member.id === userId;
    const isMuted = peersMuted[member.id];

    const icon = (
      <Icon
        width={iconSize}
        height={iconSize}
        name={isMuted ? 'micOff' : 'micOn'}
        color={isMuted ? 'white' : 'white'}
      />
    );

    if (isAdmin && !isCurrentUser) {
      return (
        <Tooltip title={t('Force mute')}>
          <span>
            <IconButton
              onClick={() => onPeerMute(member)}
              color="white"
              disabled={isMuted}
            >
              {icon}
            </IconButton>
          </span>
        </Tooltip>
      );
    }
    if (isCurrentUser && audioDevice !== 'nomic') {
      return (
        <Tooltip title={t('Toggle microphone')}>
          <IconButton onClick={() => onPeerMute(member)} color="white">
            {icon}
          </IconButton>
        </Tooltip>
      );
    }
    return (
      // Wrapping the icon in a disabled button to keep the layout consistent
      <IconButton color="white" disabled={true}>
        {icon}
      </IconButton>
    );
  };

  return (
    <div className={styles.container}>
      <ListItemButton className={styles.header} onClick={onClose}>
        <ListItemIcon className={styles.icon}>
          <Icon name="participants" />
        </ListItemIcon>
        <ListItemText
          className={styles.headerText}
          primary={t('Participants')}
        />
        <ListItemIcon style={{ marginLeft: 'auto' }} className={styles.icon}>
          <Icon width={32} height={32} name="cross" />
        </ListItemIcon>
      </ListItemButton>
      <List className={styles.list} id="members">
        {nonGhosts.map((member) => (
          <ListItem
            key={member.id}
            secondaryAction={
              <>
                {(
                  member.id === userId ? handRaised : raisedHands[member.id]
                ) ? (
                  <Tooltip title={t('Lower hand')}>
                    <IconButton
                      color="white"
                      className={styles.button}
                      onClick={() => lowerHand(member.id)}
                    >
                      <Icon
                        width={iconSize}
                        height={iconSize}
                        name="raisedHand"
                        color="white"
                        className={styles.icon}
                      />
                    </IconButton>
                  </Tooltip>
                ) : null}
                <Tooltip title={t('Pin')}>
                  <IconButton
                    onClick={() =>
                      dispatch(
                        setPinned({
                          ...pinned,
                          [member.id]: !pinned[member.id],
                        })
                      )
                    }
                    color="white"
                  >
                    <Icon
                      width={iconSize}
                      height={iconSize}
                      name={pinned[member.id] ? 'pinned' : 'pin'}
                      color={pinned[member.id] ? 'white' : 'white'}
                    />
                  </IconButton>
                </Tooltip>
                {renderMicIconOrButton(member)}
                {isAdmin && member.id !== userId ? (
                  <Tooltip title={t('Kick')}>
                    <IconButton
                      onClick={() =>
                        kickMember(
                          token,
                          roomId,
                          member.id,
                          dispatch,
                          member.userData?.name,
                          t
                        )
                      }
                      color="danger"
                    >
                      <Icon
                        width={iconSize}
                        height={iconSize}
                        name="kickOut"
                        color="danger"
                      />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </>
            }
          >
            <ListItemAvatar className={styles.listAvatar}>
              <Avatar
                {...stringAvatar(member?.userData?.name, {
                  width: 25,
                  height: 25,
                })}
                className={styles.avatar}
              />
            </ListItemAvatar>
            <Tooltip
              title={
                member.id === userId
                  ? t('You')
                  : member.userData?.name || t('Unknown')
              }
              placement="top"
            >
              <ListItemText
                primary={
                  member.id === userId
                    ? t('You')
                    : member?.userData?.name || t('Unknown')
                }
                primaryTypographyProps={{ className: styles.text }}
                className={styles.textContainer}
              />
            </Tooltip>
          </ListItem>
        ))}
        {ghosts.length ? (
          <>
            <ListItem className={styles.header}>
              <ListItemIcon className={styles.icon}>
                <Icon
                  width={iconSize}
                  height={iconSize}
                  name="userAdd"
                  color="white"
                />
              </ListItemIcon>
              <ListItemText
                className={styles.headerText}
                primary={t('Invited members')}
              />
            </ListItem>
            {ghosts.map((member) => (
              <ListItem
                className={styles.ghost}
                key={member.id}
                secondaryAction={
                  <>
                    {isAdmin && member.id !== userId ? (
                      <Tooltip title={t('Kick')}>
                        <IconButton
                          onClick={() =>
                            kickMember(
                              token,
                              roomId,
                              member.id,
                              dispatch,
                              member.userData?.name,
                              t
                            )
                          }
                          color="danger"
                        >
                          <Icon
                            width={iconSize}
                            height={iconSize}
                            name="kickOut"
                            color="danger"
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </>
                }
              >
                <ListItemAvatar className={styles.listAvatar}>
                  <Avatar
                    {...stringAvatar(member?.userData?.name, {
                      width: 25,
                      height: 25,
                    })}
                    className={styles.avatar}
                  />
                </ListItemAvatar>
                <Tooltip
                  title={
                    member.id === userId
                      ? t('You')
                      : member.userData?.name || t('Unknown')
                  }
                  placement="top"
                >
                  <ListItemText
                    primary={
                      member.id === userId
                        ? t('You')
                        : member?.userData?.name || t('Unknown')
                    }
                    primaryTypographyProps={{ className: styles.text }}
                    className={styles.textContainer}
                  />
                </Tooltip>
              </ListItem>
            ))}
          </>
        ) : null}
      </List>
    </div>
  );
}

export default withRoomContext(MembersList);
