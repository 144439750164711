import React from 'react';
import {
  HashRouter,
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import VideoRoom from './views/VideoRoom';
import ConfirmView from './views/ConfirmView';
import KickedView from './views/KickedView';
import FullView from './views/FullView';
import UnauthorizedView from './views/UnauthorizedView';
import ByeView from './views/ByeView';
import PreflightHero from './views/Preflight/Hero';
import CheckPermissions from './components/CheckPermissions';
import WaitingRoom from './views/WaitingRoom';
import RoomContext from './lib/RoomClientContext';
import RoomClient from './lib/RoomClient';

const roomClient = new RoomClient({});

let Router = BrowserRouter;
if (process.env.NODE_ENV === 'development') {
  Router = HashRouter;
}

if (window.__SENTRY_DSN__ && window.__SENTRY_ENV__) {
  Sentry.init({
    dsn: window.__SENTRY_DSN__,
    environment: window.__SENTRY_ENV__,
    includeLocalVariables: true,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.05,
  });
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <>
      <Router>
        <SentryRoutes>
          <Route
            path="/video/:roomId"
            element={
              <RoomContext.Provider value={roomClient}>
                <CheckPermissions>
                  <VideoRoom />
                </CheckPermissions>
              </RoomContext.Provider>
            }
          />
          <Route path="/kicked" element={<KickedView />} />
          <Route path="/full" element={<FullView />} />
          <Route path="/bye" element={<ByeView />} />
          <Route path="/closed" element={<ByeView closed />} />
          <Route path="/duplicate" element={<ByeView duplicate />} />
          <Route path="/unauthorized" element={<UnauthorizedView />} />
          <Route exact path="/" element={<ByeView />} />
          <Route
            path="/video/:roomId/waiting-room"
            element={
              <RoomContext.Provider value={roomClient}>
                <WaitingRoom />
              </RoomContext.Provider>
            }
          />
          <Route path="/preflight" element={<PreflightHero />} />
        </SentryRoutes>
      </Router>
      <ConfirmView />
    </>
  );
}

export default App;
