import { createSlice } from '@reduxjs/toolkit';
import logger from '../../utils/logger';

export const requestSlice = createSlice({
  name: 'request',
  initialState: {
    requests: [],
  },
  reducers: {
    removeRequest: (state, action) => {
      state.requests = state.requests.filter(
        (r) => r.peerId !== action.payload
      );
    },
    clearRequests: (state) => {
      state.requests = [];
    },
    batchAddRequests: (state, action) => {
      state.requests = [...new Set([...state.requests, ...action.payload])];
    },
    addNewRequest: (state, action) => {
      if (state.requests.find((r) => r.peerId === action.payload.peerId)) {
        logger.log('request already exists, doing nothing');
        return;
      }
      state.requests = [...state.requests, action.payload];
    },
  },
});

export const { removeRequest, clearRequests, batchAddRequests, addNewRequest } =
  requestSlice.actions;

export default requestSlice.reducer;
