import daTranslation from './da/da.json';
import deTranslation from './de/de.json';
import enTranslation from './en/en.json';
import fiTranslation from './fi/fi.json';
import isTranslation from './is/is.json';
import noTranslation from './no/no.json';
import svTranslation from './sv/sv.json';
import frTranslation from './fr/fr.json';

// Note that the order here will determine the order in the language selector
export const languages = {
  en: {
    translation: enTranslation,
    name: 'English',
  },
  sv: {
    translation: svTranslation,
    name: 'Swedish',
  },
  no: {
    translation: noTranslation,
    name: 'Norwegian',
  },
  da: {
    translation: daTranslation,
    name: 'Danish',
  },
  de: {
    translation: deTranslation,
    name: 'German',
  },

  fi: {
    translation: fiTranslation,
    name: 'Finnish',
  },
  is: {
    translation: isTranslation,
    name: 'Icelandic',
  },
  fr: {
    translation: frTranslation,
    name: 'French',
  },
};
