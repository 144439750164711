import { createSlice } from '@reduxjs/toolkit';

export const memberSlice = createSlice({
  name: 'member',
  initialState: {
    members: [],
    peersSortMap: {},
    raisedHands: {},
    peersMuted: {},
  },
  reducers: {
    setMembers: (state, action) => {
      state.members = action.payload;
    },
    setPeersSortMap: (state, action) => {
      state.peersSortMap = action.payload;
    },
    addRaisedHand: (state, action) => {
      state.raisedHands = {
        ...state.raisedHands,
        [action.payload.peerId]: action.payload.raised,
      };
    },
    setRaisedHands: (state, action) => {
      state.raisedHands = action.payload;
    },
    setPeersMuted: (state, action) => {
      state.peersMuted = action.payload;
    },
    addMutedPeer: (state, action) => {
      state.peersMuted = {
        ...state.peersMuted,
        [action.payload.peerId]: action.payload.muted,
      };
    },
  },
});

export const {
  setMembers,
  setPeersSortMap,
  addRaisedHand,
  setRaisedHands,
  setPeersMuted,
  addMutedPeer,
} = memberSlice.actions;

export default memberSlice.reducer;
