import Drawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useSelector, useDispatch } from 'react-redux';
import { setShowSidebars } from '../store/slices/layoutSlice';

const Sidebar = ({ useSwipeableDrawer, anchor, children }) => {
  const dispatch = useDispatch();
  const showSidebars = useSelector((state) => state.layout.showSidebars);
  const showSidebar = showSidebars[anchor];

  function setShow(show) {
    dispatch(setShowSidebars({ side: anchor, show }));
  }

  return useSwipeableDrawer ? (
    <SwipeableDrawer
      disableSwipeToOpen={true}
      anchor={anchor}
      open={showSidebar}
      onOpen={() => setShow(true)}
      onClose={() => {
        setShow(false);
        window.scroll(0, 0);
      }}
      PaperProps={{ sx: { overflow: 'hidden' } }}
      sx={{
        flexShrink: 0,
        height: '100vh',
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 'calc(100vw - 72px)',
          maxWidth: 400,
        },
      }}
    >
      {children}
    </SwipeableDrawer>
  ) : (
    <Drawer
      PaperProps={{ sx: { overflow: 'hidden', border: 'none' } }}
      sx={{
        flexShrink: 0,
        height: '100vh',
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 400,
        },
      }}
      anchor={anchor}
      open={showSidebar}
      variant="persistent"
    >
      {children}
    </Drawer>
  );
};

export default Sidebar;
