import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { Icon } from '../Icons';

export default function RoomLockToggle({ isLocked, toggleLock }) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(isLocked ? 'Room is locked' : 'Room is unlocked')}>
      <IconButton onClick={toggleLock}>
        <Icon name={isLocked ? 'lock' : 'lockOpen'} color="white" />
      </IconButton>
    </Tooltip>
  );
}
