import React from 'react';

import { Lock, LockOpen } from '@mui/icons-material';

import { ReactComponent as MessageIcon } from '../assets/icons/icon_message.svg';
import { ReactComponent as MicOffIcon } from '../assets/icons/icon_mic_off.svg';
import { ReactComponent as MicOnIcon } from '../assets/icons/icon_mic_on.svg';
import { ReactComponent as MoreIcon } from '../assets/icons/icon_more.svg';
import { ReactComponent as MuteIcon } from '../assets/icons/icon_mute.svg';
import { ReactComponent as ParticipantsIcon } from '../assets/icons/icon_participants.svg';
import { ReactComponent as PinIcon } from '../assets/icons/icon_pin.svg';
import { ReactComponent as PinnedIcon } from '../assets/icons/icon_pinned.svg';
import { ReactComponent as RaiseHandIcon } from '../assets/icons/icon_hand.svg';
import { ReactComponent as RaisedHandIcon } from '../assets/icons/icon_hand_raised.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/icon_search.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/icon_settings.svg';
import { ReactComponent as ShareScreenIcon } from '../assets/icons/icon_share_screen.svg';
import { ReactComponent as VideoOffIcon } from '../assets/icons/icon_video_off.svg';
import { ReactComponent as VideoOnIcon } from '../assets/icons/icon_video_on.svg';
import { ReactComponent as KickOutIcon } from '../assets/icons/icon_kick_out.svg';
import { ReactComponent as LayoutIcon } from '../assets/icons/icon_layout.svg';
import { ReactComponent as LeaveCallIcon } from '../assets/icons/icon_leave_call.svg';
import { ReactComponent as SendIcon } from '../assets/icons/icon_send.svg';
import { ReactComponent as ArrowRightIcon } from '../assets/icons/icon_arrow_right.svg';
import { ReactComponent as UserAddIcon } from '../assets/icons/icon_user_add.svg';
import { ReactComponent as CubeIcon } from '../assets/icons/icon_cube.svg';
import { ReactComponent as ExpandIcon } from '../assets/icons/icon_expand.svg';
import { ReactComponent as CollapseIcon } from '../assets/icons/icon_collapse.svg';
import { ReactComponent as ThumbnailOutlinedIcon } from '../assets/icons/icon_thumbnail_outlined.svg';
import { ReactComponent as ThumbnailSolidIcon } from '../assets/icons/icon_thumbnail_solid.svg';
import { ReactComponent as CaretUpIcon } from '../assets/icons/icon_caret_up.svg';
import { ReactComponent as CaretDownIcon } from '../assets/icons/icon_caret_down.svg';
import { ReactComponent as CrossIcon } from '../assets/icons/icon_cross.svg';
import { ReactComponent as RecordIcon } from '../assets/icons/icon_record.svg';
import { ReactComponent as RecordOutlinedIcon } from '../assets/icons/icon_record_outlined.svg';

const icons = {
  message: MessageIcon,
  micOff: MicOffIcon,
  micOn: MicOnIcon,
  more: MoreIcon,
  mute: MuteIcon,
  participants: ParticipantsIcon,
  pin: PinIcon,
  pinned: PinnedIcon,
  raiseHand: RaiseHandIcon,
  raisedHand: RaisedHandIcon,
  search: SearchIcon,
  settings: SettingsIcon,
  shareScreen: ShareScreenIcon,
  videoOff: VideoOffIcon,
  videoOn: VideoOnIcon,
  kickOut: KickOutIcon,
  layout: LayoutIcon,
  leaveCall: LeaveCallIcon,
  send: SendIcon,
  arrowRight: ArrowRightIcon,
  userAdd: UserAddIcon,
  cube: CubeIcon,
  expand: ExpandIcon,
  collapse: CollapseIcon,
  thumbnailOutlined: ThumbnailOutlinedIcon,
  thumbnailSolid: ThumbnailSolidIcon,
  caretUp: CaretUpIcon,
  caretDown: CaretDownIcon,
  cross: CrossIcon,
  record: RecordIcon,
  recordOutlined: RecordOutlinedIcon,
  lock: Lock,
  lockOpen: LockOpen,
};

const defaultIcon = CubeIcon;

export function Icon({ name, color, ...props }) {
  let fill = '';
  if (color === 'danger') {
    fill = '#EF4444';
  } else if (color === 'white') {
    fill = '#FFF';
  } else if (color === 'gray') {
    fill = '#3E3E3E';
  } else if (color === 'primary') {
    fill = '#3265b7';
  } else if (color === 'success') {
    fill = '#33B980';
  } else if (color === 'black') {
    fill = '#000';
  } else if (color) {
    fill = color;
  }

  let style = { ...props.style };
  if (name !== 'raiseHand') {
    style.fill = fill;
  } else {
    style.stroke = fill;
  }

  return React.createElement(icons[name] || defaultIcon, {
    style,
    width: 24,
    height: 24,
    ...props,
  });
}

export {
  MessageIcon,
  MicOffIcon,
  MicOnIcon,
  MoreIcon,
  MuteIcon,
  ParticipantsIcon,
  PinIcon,
  PinnedIcon,
  RaiseHandIcon,
  RaisedHandIcon,
  SearchIcon,
  SettingsIcon,
  ShareScreenIcon,
  VideoOffIcon,
  VideoOnIcon,
  KickOutIcon,
  LayoutIcon,
  LeaveCallIcon,
  SendIcon,
  ArrowRightIcon,
  UserAddIcon,
  CubeIcon,
  ExpandIcon,
  CollapseIcon,
  ThumbnailOutlinedIcon,
  ThumbnailSolidIcon,
  CaretUpIcon,
  CaretDownIcon,
  CrossIcon,
  RecordIcon,
  RecordOutlinedIcon,
  Lock,
  LockOpen,
};
