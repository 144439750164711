import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNetworkStatus } from '../store/slices/monitoringSlice';

export default function usePeerNetworkStatusListener(roomId, socket) {
  const dispatch = useDispatch();
  const reportStatusRestoredRef = useRef();
  const token = useSelector((state) => state.user.token);

  async function reportNetworkStatus(status, restoreAfterTimeout = true) {
    if (!socket) return;

    if (restoreAfterTimeout && reportStatusRestoredRef.current) {
      clearTimeout(reportStatusRestoredRef.current);
      reportStatusRestoredRef.current = null;
    }

    await socket.request('networkStatus', status);

    if (!restoreAfterTimeout) return;

    reportStatusRestoredRef.current = setTimeout(() => {
      reportNetworkStatus(4, false);
    }, 5000);
  }

  // useEffect(() => {
  //   if (!token) return;
  //   async function init() {
  //     if (!socket) return;
  //     socket.off('peerNetworkStatus');
  //     // ({peerId, status: 0|1|2|3|4}) => void
  //     socket.on('peerNetworkStatus', ({ peerId, status }) => {
  //       dispatch(addNetworkStatus({ [peerId]: status }));
  //     });
  //   }

  //   init();

  //   return () => {
  //     if (!socket) return;
  //     socket.off('peerNetworkStatus');
  //   };
  // }, [token]);

  return { reportNetworkStatus };
}
