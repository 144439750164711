import * as Sentry from '@sentry/react';

import logger from './logger';
import { track } from '../actions/TrackActions';
import {
  VIDEO_SIZE,
  VIDEO_MOBILE_SIZE,
  SHARE_VIDEO_MOBILE_SIZE,
  SHARE_VIDEO_SIZE,
} from '../constants';
import { checkChrome, checkSafari } from './browserCheck';

const isSafari = checkSafari();

// Safari has issues with the canvas drawing when not in focus
// Chromium on Android has an issue when combining captureStream and vp8
//  Can be resolved inside startProducer by changing parameters (utils/media.js)
//  See https://bugs.chromium.org/p/chromium/issues/detail?id=1359707
export const ALLOW_VIDEO_MANIPULATION = !isSafari;
export const ALLOW_TALKING_DETECTION = false;

export async function getUserMedia(
  device,
  token,
  { videoDevice, audioDevice } = {},
  {
    useVideo = true,
    useAudio = true,
    isMobile = null,
    width = VIDEO_SIZE.width,
    height = VIDEO_SIZE.height,
  } = {},
  throwError = false
) {
  try {
    if (isMobile) {
      width = VIDEO_MOBILE_SIZE.width;
      height = VIDEO_MOBILE_SIZE.height;
    }
    if (!device || (videoDevice === 'nocam' && audioDevice === 'nomic')) return;

    const isChrome = checkChrome();

    let audio;
    if (audioDevice) {
      if (isChrome) {
        audio = true &&
          device.canProduce('audio') &&
          audioDevice !== 'nomic' &&
          useAudio && {
            deviceId: { exact: audioDevice },
            channelCount: 2,
          };
      } else if (isSafari) {
        audio = true &&
          device.canProduce('audio') &&
          audioDevice !== 'nomic' &&
          useAudio && {
            deviceId: { exact: audioDevice },
            channelCount: 2,
          };
      } else {
        audio = true &&
          device.canProduce('audio') &&
          audioDevice !== 'nomic' &&
          useAudio && {
            deviceId: { exact: audioDevice },
            channelCount: 2,
          };
      }
    } else {
      audio = useAudio && device.canProduce('audio');
    }

    let video;
    if (videoDevice) {
      video = true &&
        device.canProduce('video') &&
        videoDevice !== 'nocam' &&
        useVideo && {
          width: { ideal: width },
          height: { ideal: height },
          aspectRatio: { ideal: 16 / 9 },
          frameRate: { ideal: 30 },
          deviceId: { exact: videoDevice },
        };
    } else {
      video = useVideo && device.canProduce('video');
    }

    const stream = await navigator.mediaDevices.getUserMedia({
      video,
      audio,
    });
    logger.log(
      'current audio track constraints',
      stream?.getAudioTracks()[0]?.getSettings()
    );
    return stream;
  } catch (err) {
    track(
      'client-error',
      {
        error: 'getUserMedia',
        message: err.message,
        constraint: err.constraint,
        name: err.name,
      },
      token
    );
    logger.error('Error when getting user media', err);
    if (throwError) {
      throw err;
    } else {
      Sentry.captureException(err);
      return null;
    }
  }
}

export async function getDisplayMedia(device, token, isMobile) {
  if (!device.canProduce('video')) {
    return;
  }
  try {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      audio: false,
      video: {
        displaySurface: 'monitor',
        logicalSurface: true,
        cursor: true,
        width: {
          ideal: isMobile
            ? SHARE_VIDEO_MOBILE_SIZE.width
            : SHARE_VIDEO_SIZE.width,
        },
        height: {
          ideal: isMobile
            ? SHARE_VIDEO_MOBILE_SIZE.height
            : SHARE_VIDEO_SIZE.height,
        },
        aspectRatio: { ideal: 16 / 9 },
        frameRate: { ideal: 30 },
      },
    });

    return stream;
  } catch (err) {
    track(
      'client-error',
      {
        error: 'getDisplayMedia',
        message: err.message,
        constraint: err.constraint,
        name: err.name,
      },
      token
    );
    logger.error('Error when getting display media', err);
    Sentry.captureException(err);
    return null;
  }
}

const matchError = (e, name, message) =>
  e.name === name && e.message === message;
const noop = () => {};

export function handleGetUserMediaError(
  e,
  {
    onSystemRejection = noop,
    onUserDenied = noop,
    onUserDismissed = noop,
    onCameraTaken = noop,
    catchAll = noop,
  } = {}
) {
  // error cases listed in:
  // https://medium.com/joinglimpse/how-to-build-beautiful-camera-microphone-permission-checking-for-websites-e6a08415fa76

  if (e instanceof Error === false) return;

  if (
    /* system rejection, macOS */
    /* chrome */ matchError(
      e,
      'NotAllowedError',
      'Permission denied by system'
    ) ||
    /* Cannot happend for Safari */
    /* edge */ matchError(e, 'NotAllowedError', 'Permission denied') ||
    /* firefox */ matchError(
      e,
      'NotFoundError',
      'The object can not be found here'
    )
  ) {
    onSystemRejection();
  } else if (
    /* system rejection, Windows */
    /* Chrome */ matchError(
      e,
      'NotReadableError',
      'Could not start video source'
    ) ||
    /* Safari not available */
    /* Edge */ matchError(
      e,
      'NotReadableError',
      'Could not start video source'
    ) ||
    /* Firefox */ matchError(
      e,
      'NotReadableError',
      'Failed to allocate videosource'
    )
  ) {
    onSystemRejection();
  } else if (
    /* user denied, macOS & Windows */
    /* Chrome */ matchError(e, 'NotAllowedError', 'Permisssion denied') ||
    /* Safari */ matchError(
      e,
      'NotAllowedError',
      'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.'
    ) ||
    /* Edge */ matchError(e, 'NotAllowedError', 'Permission denied') ||
    /* Firefox */ matchError(
      e,
      'NotAllowedError',
      'The request is not allowed by the user agent or the platform in the current context.'
    )
  ) {
    onUserDenied();
  } else if (
    /* camera taken, Windows */
    /* Chrome */ matchError(
      e,
      'NotReadableError',
      'Could not start video source'
    ) ||
    /* Chrome */ matchError(e, 'NotReadableError', 'Device in use') ||
    /* Safari not available */
    /* Edge */ matchError(
      e,
      'NotReadableError',
      'Could not start video source'
    ) ||
    /* Firefox */ matchError(e, 'AbortError', 'Starting videoinput failed') ||
    /* Chrome */ matchError(e, 'Error', 'Could not start video source')
  ) {
    onCameraTaken();
  } else if (e.message === 'Permission dismissed') {
    onUserDismissed();
  } else if (e.message === 'Requested device not found') {
    noop();
  } else {
    catchAll();
  }
}
