import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { Icon } from './Icons';
import { resetUnreads } from '../store/slices/chatSlice';
import Lobby from './Lobby';
import RequestList from './WaitingView/RequestList';
import RoomLockToggle from './WaitingView/RoomLockToggle';
import styles from './RoomHeader.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateRoomLock } from '../actions/RoomActions';
import { useTranslation } from 'react-i18next';
import { withRoomContext } from '../lib/RoomClientContext';

function RoomHeader({
  roomName,
  numPeers,
  useLobby,
  onToggleMemberList,
  onToggleChat,
  showChat,
  showMemberList,
  hideRoomLock = true,
  roomClient,
}) {
  const { t } = useTranslation();
  const token = useSelector((state) => state.user.token);
  const roomId = useSelector((state) => state.user.roomId);
  const numWaiting = useSelector((state) => state.lobby.numWaiting);
  const numUnreads = useSelector((state) => state.chat.numUnreads);
  const userId = useSelector((state) => state.user.userId);
  const clientOptions = useSelector((state) => state.user.clientOptions);
  const members = useSelector((state) => state.member.members);
  const isRoomLocked = useSelector((state) => state.room.isRoomLocked);
  const [showLobby, setShowLobby] = useState(false);
  const dispatch = useDispatch();

  const onToggleRoomLock = async () => {
    updateRoomLock(token, roomId, !isRoomLocked, t, roomClient);
  };

  useEffect(() => {
    if (showChat) {
      dispatch(resetUnreads());
    }
  }, [numUnreads, showChat]);

  return (
    <header className={styles.header}>
      {!clientOptions?.disableMembersList ? (
        <div
          className={classnames(styles.box, styles.small, {
            [styles.hide]: showMemberList,
          })}
        >
          <IconButton
            color="white"
            onClick={onToggleMemberList}
            id="participants-button"
          >
            <Badge
              id="participants-badge"
              badgeContent={Math.max(0, numPeers)}
              color="secondary"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Icon name="participants" />
            </Badge>
          </IconButton>
        </div>
      ) : (
        <div />
      )}
      {roomName ? (
        <div
          style={{ padding: 0, borderRadius: '0.375rem' }}
          className={styles.box}
        >
          <div
            className={classnames(styles.lobbyBox, {
              [styles.clickable]: useLobby,
            })}
            style={{ cursor: useLobby ? 'pointer' : 'default' }}
            onClick={() => setShowLobby(!showLobby)}
            id="room-title"
          >
            {useLobby ? (
              <Badge
                badgeContent={Math.max(0, numWaiting)}
                color="secondary"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Icon name="cube" width={24} height={24} />
              </Badge>
            ) : null}
            <h2
              style={useLobby ? { marginLeft: '0.4rem' } : {}}
              className={styles.title}
            >
              {roomName}
            </h2>
            {!hideRoomLock && (
              <RoomLockToggle
                isLocked={isRoomLocked}
                toggleLock={(e) => {
                  e.stopPropagation();
                  onToggleRoomLock();
                }}
              />
            )}
          </div>
          {useLobby ? (
            <Lobby
              members={members}
              show={showLobby}
              roomId={roomId}
              userId={userId}
            />
          ) : null}
          {!hideRoomLock && <RequestList roomId={roomId} />}
        </div>
      ) : (
        <div>
          {!hideRoomLock && (
            <>
              <RoomLockToggle
                isLocked={isRoomLocked}
                toggleLock={(e) => {
                  e.stopPropagation();
                  onToggleRoomLock();
                }}
              />
              <RequestList roomId={roomId} />
            </>
          )}
        </div>
      )}
      {!clientOptions?.disableChat ? (
        <div
          className={classnames(styles.box, styles.small, {
            [styles.hide]: showChat,
          })}
        >
          <IconButton
            color="white"
            id="toggle-chat-button"
            onClick={onToggleChat}
          >
            <Badge
              badgeContent={numUnreads}
              color="secondary"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              <Icon name="message" width={28} height={28} />
            </Badge>
          </IconButton>
        </div>
      ) : (
        <div />
      )}
    </header>
  );
}

export default withRoomContext(RoomHeader);
