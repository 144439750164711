import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoTemplateView from './InfoTemplateView';

export default function ByeView({ closed, duplicate }) {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.top.postMessage('ended', '*');
  }, []);
  return (
    <InfoTemplateView>
      <h1>{t('Thank you for participating')}</h1>
      {closed ? <p>{t('The meeting was closed by the moderator')}</p> : null}
      {duplicate ? (
        <p>
          {t('The session was closed since you are active on another device')}
        </p>
      ) : null}
    </InfoTemplateView>
  );
}
