import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { CheckPermissionsDialog, PermissionsBlockedDialog } from './Modals';
import useResize from '../hooks/useResize';
import { VIDEO_SIZE, VIDEO_MOBILE_SIZE } from '../constants';
import styles from './CheckPermissions.module.css';
import { setStorageItem } from '../utils/storage';
import { checkSafari } from '../utils/browserCheck';
import { withRoomContext } from '../lib/RoomClientContext';

/* ## Steps to enter Permission flow:
 * 1. First time, permissions not checked nor denied
 * 2. Pressed "continue without devices" and refreshed
 * 3. Denied permissions and refreshed
 * 4. Permissions granted
 *
 * - In entries 1 and 2 the flow should be the same.
 * - In entry 3 the first the permissions are denied once asked in step 1.
 * - In entry 4 everything should be skipped
 *
 * ## Steps:
 * - CheckPermissions
 * - PermissionsBlocked
 *
 * # States:
 * 1. { checkedPermissions:false, permissionsBlocked:false, videoDenied:true,  audioDenied:true  }
 * 2. { checkedPermissions:true,  permissionsBlocked:false, videoDenied:true,  audioDenied:true  }
 * 3. { checkedPermissions:true,  permissionsBlocked:true,  videoDenied:true,  audioDenied:true  }
 * 4. { checkedPermissions:true,  permissionsBlocked:false, videoDenied:false, audioDenied:false }
 */
function CheckPermissions({ roomClient, children }) {
  const [loading, setLoading] = useState(true);
  const [checkedPermissions, setCheckedPermissions] = useState(false);
  const [cameraTaken, setCameraTaken] = useState(false);
  const [permissionsBlocked, setPermissionsBlocked] = useState(false);
  const [videoDenied, setVideoDenied] = useState(true);
  const [audioDenied, setAudioDenied] = useState(true);
  const [tryIdx, setTryIdx] = useState(0);

  const { width, height } = useResize();
  const isMobile = width <= 780 || height <= 520;

  useEffect(() => {
    (async () => {
      const devices = await roomClient.streamHandler.getDevices();
      setCheckedPermissions(
        devices.every((d) => !!d.label || d.deviceId === 'default')
      );
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!checkedPermissions) return;

    (async () => {
      const devices = await roomClient.streamHandler.getDevices();
      // Firefox is super buggy with this behaviour,
      // so we do some retries to make sure we get the devices
      const noLabels = devices.every((d) => !d.label);
      if (noLabels && tryIdx < 5) {
        setTimeout(() => {
          setTryIdx(tryIdx + 1);
        }, 100);
        return;
      }

      const videoDevices = devices.filter((d) => d.kind === 'videoinput');
      const audioDevices = devices.filter((d) => d.kind === 'audioinput');

      const vdenied = videoDevices.every(
        (x) => x.label === '' && x.deviceId !== 'default'
      );
      setVideoDenied(vdenied);
      const adenied = audioDevices.every(
        (x) => x.label === '' && x.deviceId !== 'default'
      );
      setAudioDenied(adenied);

      setLoading(false);
    })();
  }, [checkedPermissions, tryIdx, isMobile]);

  function onDenied() {
    setPermissionsBlocked(true);
    setCheckedPermissions(true);
  }

  function onDismissed() {
    setPermissionsBlocked(true);
    setCheckedPermissions(true);
  }

  function onCameraTaken() {
    setCameraTaken(true);
    setPermissionsBlocked(true);
    setCheckedPermissions(true);
  }

  async function onRetry() {
    setTryIdx(0);
  }

  async function dismissBlockedModal() {
    setPermissionsBlocked(false);
    const isSafari = checkSafari();
    setStorageItem(localStorage, 'deviceSettings', {
      videoEffect: null,
      videoDevice: 'nocam',
      audioDevice: 'nomic',
      outputDevice: 'default',
      audioManipulation: isSafari ? false : true,
    });
  }

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (!checkedPermissions) {
    return (
      <CheckPermissionsDialog
        open={!checkedPermissions}
        onClose={() => setCheckedPermissions(true)}
        onDenied={onDenied}
        onDismissed={onDismissed}
        onCameraTaken={onCameraTaken}
        onSystemRejection={onCameraTaken}
        isMobile={isMobile}
        streamHandler={roomClient.streamHandler}
      />
    );
  }

  if (permissionsBlocked && (videoDenied || audioDenied)) {
    return (
      <PermissionsBlockedDialog
        open={permissionsBlocked}
        onRetry={onRetry}
        onClose={dismissBlockedModal}
        cameraTaken={cameraTaken}
      />
    );
  }

  return children;
}

const CheckPermissionWrapper = ({ roomClient, children }) => {
  return <CheckPermissions roomClient={roomClient} children={children} />;
};

export default withRoomContext(CheckPermissionWrapper);
