import socketIOClient from 'socket.io-client';
import { EventEmitter } from 'events';
import logger from '../utils/logger';
import { sleep } from '../utils/sleep';
import { SOCKET_RECONNECT } from '../utils/constants';

class SocketTransport extends EventEmitter {
  constructor(token, joinType) {
    super();
    this._closed = false;
    this._socket = null;
    this._token = token;
    this._joinType = joinType;
    this._connected = false;
    this._sentMessages = new Map();
    this._reconnectionCounter = 0;
    this._createSocket();
  }

  get closed() {
    return this._closed;
  }

  get socket() {
    return this._socket;
  }
  get connected() {
    return this._connected;
  }

  close() {
    if (this._closed) return;
    logger.log('Goin to close socket connection');
    this._closed = true;
    this.emit('closed');
    this._socket.disconnect();
    this._socket = null;
  }

  async send(message) {
    if (this._closed) throw new Error('Socket transport already closed!');
    try {
      this._socket.send(JSON.stringify(message));
    } catch (err) {
      logger.error('Socket send failed');
      throw err;
    }
  }

  async request(method, data = undefined) {
    const request = {
      request: true,
      reqId: Math.round(Math.random() * 10000000),
      method: method,
      data: data || {},
    };

    logger.log(`request() method:${method}, id:${request.reqId}'`);

    this._socket.send(JSON.stringify(request));

    return new Promise((resolve, reject) => {
      const timeout = 1500 * (15 + 0.1 * this._sentMessages.size);
      const sent = {
        reqId: request.reqId,
        method: request.method,
        resolve: (message) => {
          if (!this._sentMessages.delete(request.reqId)) return;

          clearTimeout(sent.timer);
          resolve(message);
        },
        reject: (error) => {
          if (!this._sentMessages.delete(request.reqId)) return;

          clearTimeout(sent.timer);
          reject(error);
        },
        timer: setTimeout(() => {
          if (!this._sentMessages.delete(request.reqId)) return;

          reject(new Error(`request timeout for reqId ${request.reqId}`));
        }, timeout),
        close: () => {
          clearTimeout(sent.timer);
          reject(new Error('socket closed'));
        },
      };

      // Add sent stuff to the map.
      this._sentMessages.set(request.reqId, sent);
    });
  }

  _handleResponse(response) {
    logger.log('Received response', response);
    const sent = this._sentMessages.get(response.resId);

    if (!sent) {
      logger.error(
        `received response does not match any sent request [id:${response.resId}]`
      );

      throw new Error('Invalid request id!');
    }

    if (response.ok) {
      sent.resolve(response.data);
    } else {
      const error = new Error(response.errorReason);

      error.code = response.errorCode;
      sent.reject(error);
    }
  }

  _createSocket() {
    if (!this._token) throw new Error('Cannot create socket without token');
    logger.log('Going to create a socket with joinType:', this._joinType);
    const socket = socketIOClient({
      auth: { token: this._token, joinType: this._joinType },
    });
    socket.on('connect', () => {
      if (this._connected) {
        logger.log('Socket already connected!');
      } else {
        logger.log('connected to socket');
        this._connected = true;
        this.emit('connected');
      }
    });

    socket.on('disconnect', async () => {
      logger.error('socket disconnected!');
      this._connected = false;
      this.emit('disconnect');
      if (this._closed) {
        logger.log('No need to reconnect for roomClient close!');
      } else {
        if (this._reconnectionCounter > 240) {
          logger.log(
            'Reconnection not needed if user disconnection for more than 2 minutes, reconnection counter:',
            this._reconnectionCounter
          );
          return;
        }
        this._joinType = SOCKET_RECONNECT;
        this._socket.close();
        this._socket = null;
        this._createSocket();
        this._reconnectionCounter += 1;
        await sleep(500);
      }
    });

    socket.on('reconnect', () => {
      logger.log('socket reconnected after disconnect');
      this.emit('reconnect');
    });

    socket.on('connect_error', (err) => {
      logger.error('socket connect error', err);
      this._connected = false;
    });

    socket.on('error', (err) => {
      logger.error('Socket error', err);
      this._connected = false;
    });

    socket.on('message', (message) => {
      logger.log('Received message is', message);
      const parsedMessage = JSON.parse(message);

      if (parsedMessage.resId) {
        this._handleResponse(parsedMessage);
      } else {
        logger.log('New message received with action', parsedMessage.action);
        this.emit('message', parsedMessage);
      }
    });

    this._socket = socket;
  }

  async connect() {
    let socketConnectorCount = 0;
    while (!this.connected) {
      logger.log(`socket connected state ${this._connected}! wait for 25ms!`);
      socketConnectorCount++;
      await sleep(25);
      if (socketConnectorCount > 400) {
        throw new Error(
          'Unable to connect to socket for past 10 seconds. Exiting the loop;'
        );
      }
    }
  }
}

export default SocketTransport;
