import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import Control from './Control';

const options = ['grid', 'spotlight'];

export default function ViewLayoutSelector({
  onSetViewLayout,
  viewLayout,
  isMobile,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    const option = options[index];
    onSetViewLayout(option);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Control
        isMobile={isMobile}
        label={t('Change view layout')}
        ariaLabel="layout"
        onClick={handleClickListItem}
        icon="layout"
      />
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            style={{ textTransform: 'capitalize' }}
            selected={option === viewLayout}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {t(`${option} view`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
