import { useEffect, useState } from 'react';
import { debounce } from '../utils/functions';

function useResize() {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const onResize = debounce(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return {
    width,
    height,
  };
}

export default useResize;
