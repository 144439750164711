import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './FileSelectorDialog.module.css';
function FileSelectorDialog({ isMobile, open, onClose }) {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);

  useEffect(() => {
    if (!open) {
      setFile(null);
    }
  }, [open]);

  function handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
    event.target.value = null;
  }

  return (
    <Dialog
      PaperProps={{
        className: classnames(styles.dialog, { [styles.isMobile]: isMobile }),
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t('Select a file to share')}</DialogTitle>
      <DialogContent>
        <div className={styles.container}>
          <div className={styles.content}>
            <p className={styles.text}>
              {t('Choose the video or audio file that you want to share.')}
            </p>
            <input
              ref={fileRef}
              hidden
              type="file"
              accept="video/*, audio/*"
              onChange={handleFileChange}
              className={styles.fileInput}
            />
            <Button
              variant="contained"
              color="white"
              onClick={() => fileRef.current?.click()}
            >
              {t('Select file')}
            </Button>
            {file ? (
              <p className={styles.text}>
                {t('Selected file')}: <i>{file.name}</i>
              </p>
            ) : null}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="white"
          className={styles.button}
          onClick={() => onClose()}
        >
          {t('Cancel')}
        </Button>
        <Button
          className={styles.button}
          variant="contained"
          color="white"
          onClick={() => onClose(file)}
          disabled={!file}
          autoFocus
        >
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FileSelectorDialog;
