import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DisconnectedModal.module.css';
import { Button, CircularProgress } from '@mui/material';

function DisconnectedModal({ connectionFailure, onClick }) {
  const { t } = useTranslation();
  const timeOutPeriod = 135;
  const [timeOver, setTimeOver] = React.useState(false);

  useEffect(() => {
    setTimeout(() => setTimeOver(true), timeOutPeriod * 1000);
  }, []);

  return (
    <div className={styles.disconnectedModalContainer}>
      <div className={styles.disconnectedModalContent}>
        <h1>{t('Uh-oh!')}</h1>
        <p>
          {t(
            "Lost network connection. Hold on, you'll be back in the meeting as soon as we find your network again."
          )}
        </p>
      </div>
      {!timeOver && (
        <CircularProgress color="newgreen" aria-label="Trying to reconnect" />
      )}
      {connectionFailure && timeOver ? (
        <Button
          className={styles.button}
          color="newgreen"
          variant="contained"
          onClick={onClick}
        >
          {t('Rejoin')}
        </Button>
      ) : null}
    </div>
  );
}

export default DisconnectedModal;
