import { post, addCtCheck, cancelCalls } from '../utils/api';
import { showConfirm } from '../utils/confirm';

export const createRoom = (roomId, token, dispatch) => {
  cancelCalls('createRoom');
  const cancelToken = addCtCheck('createRoom');
  return post(`/room/${roomId}`, {}, dispatch, {
    token,
    cancelToken,
    skipSnack: true,
  });
};

function identity(x) {
  return x;
}

export async function kickMember(
  token,
  roomId,
  peerId,
  dispatch,
  peerName = 'the user',
  t = identity
) {
  const message = `${t('Are you sure that you want to kick')} ${peerName} ${t(
    'from the meeting?'
  )}`;
  const ok = await showConfirm({ title: t('Are you sure?'), message });
  if (ok) {
    cancelCalls('kickMember');
    const cancelToken = addCtCheck('kickMember');
    return post(`/room/${roomId}/kick`, { peerId }, dispatch, {
      token,
      cancelToken,
    });
  }
}

export async function updateRoomLock(
  token,
  roomId,
  isActive,
  t = identity,
  socket
) {
  const ok = await showConfirm({
    title: t(isActive ? 'Lock the room' : 'Unlock the room'),
    message: t(
      `Are you sure that you want to ${isActive ? 'lock' : 'unlock'} the room?`
    ),
  });
  if (ok) {
    await socket.request('updateRoomLock', { isActive });
  }
}
