import { post } from '../api';

export async function preflightToken(tokenId = '') {
  try {
    const res = await post('/preflight-token', { tokenId });
    return res?.data?.token;
  } catch (err) {
    return null;
  }
}

export function getToken() {
  return preflightToken();
}
