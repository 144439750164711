import React from 'react';
import Paper from '@mui/material/Paper';
import styles from './InfoTemplateView.module.css';

export default function InfoTemplateView({ children }) {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <Paper className={styles.paper} elevation={3}>
          {children}
        </Paper>
      </div>
    </div>
  );
}
