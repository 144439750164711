import { createSlice } from '@reduxjs/toolkit';

function calculateNumWaiting(state) {
  let inLobby = 0;
  for (const key of Object.keys(state.lobby)) {
    const x = state.lobby[key];
    if (state.roomMembers[key] || x?.alertOthers === false) continue;
    inLobby += 1;
  }

  const requested = Object.keys(state.requested).length;
  return Math.max(0, inLobby - requested);
}

export const lobbySlice = createSlice({
  name: 'lobby',
  initialState: {
    lobby: {},
    requested: {},
    numWaiting: 0,
    roomMembers: {},
  },
  reducers: {
    setRoomMembers: (state, action) => {
      state.roomMembers = action.payload;
      state.numWaiting = calculateNumWaiting(state);
    },
    addMember: (state, action) => {
      state.lobby = {
        ...state.lobby,
        [action.payload.user.userId]: {
          ...action.payload.user,
          alertOthers: action.payload.alertOthers,
        },
      };
      // Remove from requested
      state.requested = Object.fromEntries(
        Object.entries(state.requested).filter(
          ([userId]) => userId !== action.payload.user.id
        )
      );
      state.numWaiting = calculateNumWaiting(state);
    },
    removeMember: (state, action) => {
      state.lobby = Object.fromEntries(
        Object.entries(state.lobby).filter(
          ([userId]) => userId !== action.payload
        )
      );
      // Remove from requested
      state.requested = Object.fromEntries(
        Object.entries(state.requested).filter(
          ([userId]) => userId !== action.payload
        )
      );
      state.numWaiting = calculateNumWaiting(state);
    },
    addToRequested: (state, action) => {
      if (state.lobby[action.payload]?.alertOthers) {
        return;
      }
      state.requested = {
        ...state.requested,
        [action.payload]: true,
      };
      state.numWaiting = calculateNumWaiting(state);
    },
  },
});

export const { setRoomMembers, addMember, removeMember, addToRequested } =
  lobbySlice.actions;

export default lobbySlice.reducer;
