import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoTemplateView from './InfoTemplateView';

export default function KickedView() {
  const { t } = useTranslation();
  return (
    <InfoTemplateView>
      <h1>{t('The room is full')}</h1>
    </InfoTemplateView>
  );
}
