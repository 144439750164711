import React, { useState } from 'react';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { sendNPS } from '../actions/NPSActions';
import { useSelector, useDispatch } from 'react-redux';
import styles from './NPSView.module.css';
import logger from '../utils/logger';

export default function NPSView({ onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = useSelector((state) => state.user.token);
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [open, setOpen] = useState(true);
  const [showText, setShowText] = useState(false);

  function close() {
    setOpen(false);
    onClose();
  }

  async function onSubmit() {
    if (typeof rating !== 'number') {
      return;
    }
    try {
      await sendNPS({ rating, feedback, token }, dispatch);
    } catch (err) {
      throw new Error(`Error sending NPS: ${err.message}`);
    } finally {
      close();
    }
  }

  return (
    <Dialog className={styles.dialog} open={open} onClose={() => close()}>
      <DialogTitle className={styles.title}>
        {t('Thank you for participating')}
      </DialogTitle>
      <DialogContent className={styles.content}>
        <p id="feedback">{t('How did you find the quality of the meeting?')}</p>
        <Rating
          name="nps-rating"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
            setShowText(true);
          }}
          required
        />
        {showText && (
          <>
            <p>{t('What could we do better?')}</p>
            <TextField
              label={t('Write your feedback here')}
              value={feedback}
              onChange={(event) => {
                setFeedback(event.target.value);
              }}
              multiline
              rows={4}
              fullWidth
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="gray" onClick={() => close()}>
          {t('Close')}
        </Button>
        <Button color="newgreen" variant="contained" onClick={onSubmit}>
          {t('Send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
