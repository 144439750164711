import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoTemplateView from './InfoTemplateView';

export default function UnauthorizedView() {
  const { t } = useTranslation();
  return (
    <InfoTemplateView>
      <h1>{t('Unauthorized')}</h1>
      <p>{t('You are not authorized to access this page')}</p>
    </InfoTemplateView>
  );
}
