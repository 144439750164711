import { createSlice } from '@reduxjs/toolkit';

export const recordSlice = createSlice({
  name: 'record',
  initialState: {
    recording: null,
    recordingStartTime: null,
  },
  reducers: {
    setRecording: (state, action) => {
      state.recording = action.payload;
    },
    setRecordingStartTime: (state, action) => {
      state.recordingStartTime = action.payload;
    },
  },
});

export const { setRecording, setRecordingStartTime } = recordSlice.actions;

export default recordSlice.reducer;
