import { createSlice } from '@reduxjs/toolkit';

export const producerLayerSlice = createSlice({
  name: 'producerLayer',
  initialState: {
    spatialLayer: 2,
    spatialLayerShare: 1,
  },
  reducers: {
    setSpatialLayer: (state, action) => {
      state.spatialLayer = action.payload;
    },
    setSpatialLayerShare: (state, action) => {
      state.spatialLayerShare = action.payload;
    },
  },
});

export const { setSpatialLayer, setSpatialLayerShare } =
  producerLayerSlice.actions;

export default producerLayerSlice.reducer;
