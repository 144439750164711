import '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-backend-webgl';
import * as bodySegmentation from '@tensorflow-models/body-segmentation';
import { getImage } from './db';
import { createSnack } from '../actions/SnackActions';

let segmenter;

const model = bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation;
const segmenterConfig = {
  runtime: 'mediapipe',
  modelType: 'general',
  // TODO these should be hosted by our backend?
  solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@0.1.1632777926`,
};

class VideoManipulator {
  constructor(stream, simple = false) {
    this.video = document.createElement('video');
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.effect = null;
    this.backgroundImage = null;
    this.simple = simple;
    this.stop = false;
    this._tick = this._tick.bind(this);
    if (simple && stream) {
      this.outputStream = stream;
      this.outputStream.force = false;
    } else {
      this.outputStream = this.canvas.captureStream();
      if (stream) {
        this.setInputStream(stream);
      }
    }
  }

  async setEffect(effect, dispatch) {
    if (this.simple) return;
    try {
      this.effect = effect;
      if (effect?.type === 'blur' || effect?.type === 'virtual-background') {
        this._loadSegmentorNet();
      }

      if (effect?.type === 'virtual-background' && effect?.image) {
        let image = effect?.image;
        this.backgroundImage = document.createElement('img');
        if (typeof image === 'string' && image.startsWith('indexeddb://')) {
          this.backgroundImage.src = await getImage(
            image.replace('indexeddb://', '')
          );
        } else if (typeof image === 'string' && image.startsWith('http')) {
          this.backgroundImage.src = `/api/image?url=${image}`;
        } else {
          this.backgroundImage.src = image;
        }
      }
    } catch (err) {
      createSnack(dispatch, 'Error setting effect', 'error');
      throw new Error(`Error setting effect: ${err.message}`);
    }
  }

  isUsingEffect() {
    return this.effect === 'blur' || this.effect === 'virtual-background';
  }

  async setInputStream(stream) {
    this.stop = true;
    if (!stream) {
      this.destroy();
    }

    if (this.simple) {
      this.outputStream = stream;
      this.outputStream.force = true;
      return;
    }

    this.video.srcObject = stream;
    this.video.onplaying = () => {
      this.video.width = this.video.videoWidth;
      this.video.height = this.video.videoHeight;
      this.canvas.width = this.video.videoWidth;
      this.canvas.height = this.video.videoHeight;
      this.stop = false;
      this._tick();
    };
    await this.video.play();

    if (
      this.effect?.type === 'blur' ||
      this.effect?.type === 'virtual-background'
    ) {
      await this._loadSegmentorNet();
    }

    this.outputStream.force = true;
  }

  destroy() {
    if (this.video.srcObject) {
      this.video.srcObject = null;
    }
    this.stop = true;
    clearInterval(this.interval);
    this.outputStream = this.canvas.captureStream();
  }

  getOutputStream() {
    return this.outputStream;
  }

  getCanvas() {
    return this.canvas;
  }

  async _tick() {
    if (this.stop) return;
    const dt = Date.now();
    const { effect } = this;
    if (effect?.type === 'blur' && segmenter) {
      const segmentation = await segmenter.segmentPeople(this.video);
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.video.width = this.video.videoWidth;
      this.video.height = this.video.videoHeight;
      this.canvas.width = this.video.videoWidth;
      this.canvas.height = this.video.videoHeight;
      bodySegmentation.drawBokehEffect(
        this.canvas,
        this.video,
        segmentation,
        0.5,
        effect?.strength || 12,
        4,
        false
      );
    } else if (
      effect?.type === 'virtual-background' &&
      segmenter &&
      this.backgroundImage &&
      this.backgroundImage.complete &&
      this.video.width &&
      this.canvas.width
    ) {
      const segmentation = await segmenter.segmentPeople(this.video);
      /*
      // Convert the segmentation into a mask to darken the background.
      const foregroundColor = { r: 0, g: 0, b: 0, a: 0 };
      const backgroundColor = { r: 0, g: 0, b: 0, a: 255 };
      const backgroundDarkeningMask = await bodySegmentation.toBinaryMask(
        segmentation,
        foregroundColor,
        backgroundColor,
      );
      */

      const mask = segmentation[0];
      const maskdata = await mask?.mask.toImageData();
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.video.width = this.video.videoWidth;
      this.video.height = this.video.videoHeight;
      this.canvas.width = this.video.videoWidth;
      this.canvas.height = this.video.videoHeight;

      this.ctx.drawImage(
        this.video,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      const datafg = this.ctx.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      this.ctx.drawImage(
        this.backgroundImage,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      const databg = this.ctx.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );

      // Use mask to draw video on foreground and background on background
      for (let i = 0; i < databg.data.length; i += 4) {
        /*
        if (backgroundDarkeningMask.data[i + 3] === 0) {
          databg.data[i] = datafg.data[i];
          databg.data[i + 1] = datafg.data[i + 1];
          databg.data[i + 2] = datafg.data[i + 2];
          databg.data[i + 3] = datafg.data[i + 3];
        }
        */
        const prob = maskdata.data[i + 3] / 255;
        databg.data[i] = databg.data[i] * (1 - prob) + datafg.data[i] * prob;
        databg.data[i + 1] =
          databg.data[i + 1] * (1 - prob) + datafg.data[i + 1] * prob;
        databg.data[i + 2] =
          databg.data[i + 2] * (1 - prob) + datafg.data[i + 2] * prob;
        databg.data[i + 3] =
          databg.data[i + 3] * (1 - prob) + datafg.data[i + 3] * prob;
      }

      this.ctx.putImageData(databg, 0, 0);
    } else {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.video.width = this.video.videoWidth;
      this.video.height = this.video.videoHeight;
      this.canvas.width = this.video.videoWidth;
      this.canvas.height = this.video.videoHeight;
      this.ctx.drawImage(this.video, 0, 0);
    }

    const delay = 1000 / 25 - (Date.now() - dt);
    setTimeout(this._tick, Math.max(12, delay));
  }

  async _loadSegmentorNet() {
    if (segmenter) return;
    segmenter = await bodySegmentation.createSegmenter(model, segmenterConfig);
  }
}

export default VideoManipulator;
