import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useWaitingListListener(roomId, onJoin, roomClient) {
  const admitted = useSelector((state) => state.user.admitted);
  const roomLocked = useSelector((state) => state.room.isRoomLocked);

  const requestJoin = useCallback(async () => {
    await roomClient.request('requestJoin', roomId);
  }, [roomId]);

  useEffect(() => {
    async function init() {
      if (roomLocked && !admitted) {
        requestJoin();
      }
    }

    init();
  }, [roomId, onJoin, requestJoin, roomLocked, admitted]);

  return { resend: requestJoin };
}
