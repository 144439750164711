import { createSlice } from '@reduxjs/toolkit';
import { parseJwt } from '../../utils/jwt';
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '../../utils/storage';

const TOKEN_IDENTIFIER = 'video-user-token';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    token: null,
    userId: null,
    role: 'guest',
    clientOptions: {},
    roomId: null,
    admitted: false,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload.token;
      const parsed = parseJwt(action.payload.token);
      state.userId = parsed.id;
      state.role = parsed.role || 'guest';
      state.clientOptions = parsed.clientOptions || {};
      state.roomId = action.payload.roomId;
      setStorageItem(
        sessionStorage,
        `${TOKEN_IDENTIFIER}:${action.payload.roomId}`,
        action.payload.token,
        false
      );
    },
    unsetToken: (state) => {
      state.token = null;
      if (state.roomId) {
        removeStorageItem(
          sessionStorage,
          `${TOKEN_IDENTIFIER}:${state.roomId}`
        );
      }
      state.userId = null;
      state.role = 'guest';
      state.clientOptions = {};
    },
    loadToken: (state, action) => {
      const token = getStorageItem(
        sessionStorage,
        `${TOKEN_IDENTIFIER}:${action.payload}`,
        null,
        false
      );
      if (token) {
        state.roomId = action.payload;
        const parsed = parseJwt(token);
        state.userId = parsed.id;
        state.role = parsed.role || 'guest';
        state.clientOptions = parsed.clientOptions || {};
        state.token = token;
      }
    },
    setAdmitted: (state, action) => {
      state.admitted = action.payload;
    },
  },
});

export const { setToken, unsetToken, loadToken, setAdmitted } =
  userSlice.actions;

export default userSlice.reducer;
