class VideoLayouts {
  constructor(renderSize = 2) {
    this.renderList = [];
    this.renderSize = renderSize;
    this.shareData = null;
    this.pins = [];
    this.hands = [];
    this.activeSpeaker = null;
    this.selfId = null;
    this.floatSelf = false;
    this.floatShare = true;
    this.peers = {};
    this.orderMap = {};
  }

  getOrderMap() {
    return this.orderMap;
  }

  setSelfId(selfId) {
    this.selfId = selfId;
  }

  setRenderSize(renderSize) {
    this.renderSize = renderSize;
  }

  setPins(pins) {
    this.pins = Object.keys(pins).filter((x) => pins[x]);
  }

  setHands(hands) {
    this.hands = Object.keys(hands).filter(
      (x) => hands[x] && x !== this.selfId
    );
  }

  setFloatSelf(floatSelf) {
    this.floatSelf = floatSelf;
  }

  setFloatShare(floatShare) {
    this.floatShare = floatShare;
  }

  setPeers(peers) {
    this.peers = peers;
  }

  setShareData(shareData) {
    this.shareData = shareData;
  }

  setActiveSpeaker(peerId) {
    this.activeSpeaker = peerId;
  }

  getCoreList() {
    const showShare =
      this.shareData &&
      (!this.floatShare || this.shareData.peerId !== this.selfId);
    const showSelf = !this.floatSelf && this.selfId;
    const numTiles =
      this.peers.length + (showShare ? 1 : 0) + (!showSelf ? -1 : 0);
    const prioList = [];

    if (showShare) {
      prioList.push('share');
    }
    if (
      this.activeSpeaker &&
      !(this.floatSelf && this.activeSpeaker === this.selfId) &&
      numTiles > this.renderSize
    ) {
      prioList.push(this.activeSpeaker);
    }
    if (
      showSelf &&
      !prioList.includes(this.selfId) &&
      numTiles > this.renderSize
    ) {
      prioList.push(this.selfId);
    }

    return prioList;
  }

  getOthers(currentList) {
    const allowedPeersMap = this.peers.reduce((acc, x) => {
      acc[x] = true;
      return acc;
    }, {});
    let curr = currentList.reduce((acc, x) => {
      acc[x] = true;
      return acc;
    }, {});

    const others = [];

    if (this.pins?.length) {
      others.push(...this.pins.filter((x) => allowedPeersMap[x]));
    }

    const remainingPeers = this.peers.filter((peerId) => {
      return (
        (peerId !== this.selfId || !this.floatSelf) &&
        !currentList.includes(peerId) &&
        !curr[peerId] &&
        allowedPeersMap[peerId]
      );
    });

    others.push(...remainingPeers);
    const uniqueOthers = Array.from(new Set(others));
    return uniqueOthers;
  }

  getRenderList(page) {
    const coreList = this.getCoreList();
    let others = this.getOthers(coreList);
    if (others.length + coreList.length > this.renderSize) {
      const size = this.renderSize - coreList.length;
      const start = (page - 1) * size;
      const end = page * size;
      others = others.slice(start, end);
    }
    return [...coreList, ...others];
  }

  getNumPages() {
    const coreList = this.getCoreList();
    const remainingTilesPerPage = this.renderSize - coreList.length;
    const others = this.getOthers(coreList);
    return Math.ceil(others.length / remainingTilesPerPage);
  }

  createRenderList(page) {
    this.renderList = this.getRenderList(page);
    this.orderMap = this.renderList.reduce((acc, x, idx) => {
      acc[x] = idx;
      return acc;
    }, {});
  }
}

export default VideoLayouts;
