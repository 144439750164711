import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { getSubject } from '../utils/confirm';

import styles from './ConfirmView.module.css';

export default function ConfirmView() {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  useEffect(() => {
    const subject = getSubject();
    const subscription = subject.subscribe(setData);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <Dialog
      PaperProps={{ className: styles.dialog }}
      open={data !== null}
      onClose={() => data.cb(false)}
    >
      <DialogTitle className={styles.title}>{data?.title}</DialogTitle>
      <DialogContent className={styles.dialogContent} dividers>
        <p>{data?.message}</p>
      </DialogContent>
      <DialogActions>
        <Button className={styles.button} onClick={() => data?.cb(false)}>
          {t('Cancel')}
        </Button>
        <Button className={styles.button} onClick={() => data?.cb(true)}>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
