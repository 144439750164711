import { Children } from 'react';
import classnames from 'classnames';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import styles from './Dialog.module.css';

function Dialog({
  open,
  onClose = () => {},
  title,
  children,
  actions,
  isMobile,
  disableBackdropClose = false,
  disableEscapeKeyClose = false,
}) {
  const handleClose = (e, reason) => {
    if (disableBackdropClose && reason === 'backdropClick') return;
    if (disableEscapeKeyClose && reason === 'escapeKeyDown') return;
    onClose();
  };
  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        className: classnames(styles.dialog, { [styles.isMobile]: isMobile }),
      }}
    >
      {!!title && <DialogTitle>{title}</DialogTitle>}
      {!!children && (
        <DialogContent className={styles.dialogContent}>
          {children}
        </DialogContent>
      )}
      {!!actions && (
        <DialogActions
          className={classnames(styles.actions, {
            [styles.singleAction]: Children.toArray(actions).length === 1,
          })}
        >
          {actions}
        </DialogActions>
      )}
    </MuiDialog>
  );
}

export default Dialog;
