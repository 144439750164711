import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography as Text } from '@mui/material';
import Dialog from './Dialog';
import { checkChrome } from '../../utils/browserCheck';

const audioVideoHelpUrl =
  'https://docs.compodium.com/docs/Troubleshooting#if-audio-or-video-is-not-working';

function PermissionsBlockedDialog({
  open,
  cameraTaken,
  onRetry = () => {},
  onClose = () => {},
}) {
  const { t } = useTranslation();

  function getImgSrc() {
    const isChrome = checkChrome();
    if (isChrome)
      return `${window.location.origin}/blocked-access-icons/chrome.png`;
    return null;
  }
  const url = window?.location?.origin || '-';

  const reload = () => {
    onRetry();
    window.location.reload();
  };

  const blockedSrc = getImgSrc();

  return (
    <Dialog
      open={open}
      title={t('Grant access to camera and microphone')}
      actions={
        <>
          <Button variant="contained" color="newgreen" onClick={reload}>
            {t('Try again')}
          </Button>
          <Button variant="outlined" color="newgreen" onClick={onClose}>
            {t('Continue without devices')}
          </Button>
        </>
      }
    >
      <Stack gap={1}>
        <Text>
          {t(
            "It appears as though the website's access to the camera or microphone is blocked."
          )}
        </Text>
        {blockedSrc ? (
          <Box component="ol">
            <Text component="li">
              {t('Click the camera icon to the right of the URL field')}
            </Text>

            <img
              src={getImgSrc()}
              alt={t("Image showing the camera icon's location")}
              width="100%"
            />

            <Text component="li">
              {t(
                'Choose "Always allow {{url}} access to camera and microphone" and then "Done"',
                { url, interpolation: { escapeValue: false } }
              )}
            </Text>

            <Text component="li">
              {t('Press Try again on this modal to reload the page')}
            </Text>
          </Box>
        ) : (
          <>
            <Text>
              {t(
                'This will affect the experience for you and others in the meeting.'
              )}
            </Text>
            {cameraTaken && (
              <Text>
                {t(
                  'The camera seems to be used by a different application. Please close that application and press Try again on this modal.'
                )}
              </Text>
            )}
          </>
        )}
        <Text
          fontWeight={700}
          color="#fff"
          component="a"
          href={audioVideoHelpUrl}
          target="_blank"
          rel="noreferrer"
        >
          {t('Do you need help?')}
        </Text>
      </Stack>
    </Dialog>
  );
}

export default PermissionsBlockedDialog;
