import * as Sentry from '@sentry/react';
import { post } from '../utils/api';
import logger from '../utils/logger';

export async function track(event, data = {}, token) {
  // the tracking endpoint requires authentication, so no point in even trying to track if we don't have a token
  if (!token) return;
  try {
    await post(`/track/${event}`, data, null, { token });
  } catch (e) {
    logger.log('Failed to send track event', { event }, e);
    Sentry.captureException(e);
  }
}
