import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import styles from './BrowserNotSupported.module.css';
import classnames from 'classnames';
import { DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import SupportedBrowserList from './SupportedBrowserList';

function BrowserNotSupported({ onContinue, isMobile }) {
  const [dialogOpen, setDialogOpen] = useState(true);

  const { t } = useTranslation();

  const handleClose = () => {
    setDialogOpen(false);
    onContinue();
  };

  return (
    <Dialog
      open={dialogOpen}
      PaperProps={{
        className: classnames(styles.dialog, { [styles.isMobile]: isMobile }),
      }}
    >
      <DialogContent className={styles.dialogContent}>
        <div className={styles.container}>
          <div>
            <h3 className={styles.sectionTitle}>
              {t('Oops it looks like your')} <br />{' '}
              {t('Current browser not supported')}
            </h3>
            <p>{t('Upgrading your browser version')}</p>
          </div>
          <SupportedBrowserList />
          <Button
            color="white"
            variant="contained"
            className={styles.button}
            onClick={handleClose}
          >
            {t('Continue anyway')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default BrowserNotSupported;
