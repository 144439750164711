import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import VideoSettingsDialog from './settings/VideoSettingsDialog';
import ViewLayoutSelector from '../control/ViewLayoutSelector';
import RecordingSelector from '../control/RecordingSelector';
import Control from '../control/Control';
import ShareSelector from '../control/ShareSelector';
import MuteSelector from '../control/MuteSelector';
import { useSelector } from 'react-redux';

import BrowserNotSupported from '../Modals/BrowserNotSupported';
import { isBrowserSupported } from '../../utils/browserCheck';

import styles from './VideoControls.module.css';
import Pagination from './Pagination';

export default function VideoControls({
  device,
  onToggleMute,
  onToggleVideo,
  onToggleScreenShare,
  onToggleRecording,
  onLeave,
  onRaiseHand,
  onSetViewLayout,
  isAdmin,
  isMobile,
  allowVideoManipulation,
  showSettings,
  setShowSettings,
  onCloseSettings,
  showTalkingPopover,
  setShowTalkingPopover,
  userData,
  streamHandler,
}) {
  const userId = useSelector((state) => state.user.userId);
  const clientOptions = useSelector((state) => state.user.clientOptions);
  const muted = useSelector((state) => state.control.muted);
  const hideVideo = useSelector((state) => state.control.hideVideo);
  const handRaised = useSelector((state) => state.control.handRaised);
  const viewLayout = useSelector((state) => state.layout.viewLayout);
  const numPages = useSelector((state) => state.layout.numPages);
  const recording = useSelector((state) => state.record.recording);
  const deviceSettings = useSelector((state) => state.device.deviceSettings);
  const sharingScreen = useSelector((state) => state.room.sharingScreen);

  const browserSupported = isBrowserSupported();
  const [showBrowserMessage, setShowBrowserMessage] = useState(false);

  useEffect(() => {
    if (!browserSupported) {
      setShowBrowserMessage(true);
    }
  }, []);

  const { t } = useTranslation();

  const nomic =
    clientOptions?.disableMicrophone || deviceSettings.audioDevice === 'nomic';
  const nocam =
    clientOptions?.disableCamera || deviceSettings.videoDevice === 'nocam';

  const controls = [
    {
      shouldRender: true,
      control: (
        <MuteSelector
          showTalkingPopover={showTalkingPopover}
          setShowTalkingPopover={setShowTalkingPopover}
          isMobile={isMobile}
          nomic={nomic}
          muted={muted}
          onToggleMute={onToggleMute}
        />
      ),
    },
    {
      shouldRender: true,
      control: (
        <Control
          isMobile={isMobile}
          label={t('Toggle video')}
          ariaLabel="video-control"
          onClick={() => onToggleVideo()}
          icon={nocam || hideVideo ? 'videoOff' : 'videoOn'}
          disabled={nocam}
        />
      ),
    },
    {
      shouldRender: !isMobile && !clientOptions?.disableShareScreen,
      control: (
        <ShareSelector
          isMobile={isMobile}
          sharingScreen={sharingScreen}
          onToggleScreenShare={onToggleScreenShare}
        />
      ),
    },
    {
      shouldRender: !clientOptions?.disableRecording && isAdmin,
      control: (
        <RecordingSelector
          isSelfRecording={userId === recording}
          isMobile={isMobile}
          onToggleRecording={onToggleRecording}
          recording={recording}
        />
      ),
    },
    {
      shouldRender: !clientOptions?.disableRaiseHand,
      control: (
        <Control
          isMobile={isMobile}
          label={t('Raise hand')}
          ariaLabel="raise-hand"
          onClick={onRaiseHand}
          icon={handRaised ? 'raisedHand' : 'raiseHand'}
          style={{ fontSize: 26 }}
        />
      ),
    },
    {
      shouldRender: !isMobile && !clientOptions?.disableLayout,
      control: (
        <ViewLayoutSelector
          isMobile={isMobile}
          viewLayout={viewLayout}
          onSetViewLayout={onSetViewLayout}
        />
      ),
    },
    {
      shouldRender: true,
      control: (
        <Control
          isMobile={isMobile}
          label={t('Settings')}
          ariaLabel="settings"
          onClick={() => setShowSettings(!showSettings)}
          icon="settings"
        />
      ),
    },
    {
      shouldRender: true,
      control: (
        <Control
          onClick={onLeave}
          isMobile={isMobile}
          label={t('Leave')}
          ariaLabel="leave"
          color="danger"
          icon="leaveCall"
        />
      ),
    },
    {
      shouldRender: numPages > 1,
      control: <Pagination count={numPages} isMobile={isMobile} />,
    },
  ];

  const handleContinue = () => {
    setShowBrowserMessage(false);
  };

  return (
    <>
      {showSettings && showBrowserMessage ? (
        <BrowserNotSupported onContinue={handleContinue} isMobile={isMobile} />
      ) : (
        <>
          <VideoSettingsDialog
            device={device}
            deviceSettings={deviceSettings}
            open={showSettings}
            onClose={onCloseSettings}
            allowVideoManipulation={allowVideoManipulation}
            isMobile={isMobile}
            userData={userData}
            useAspectRatio
          />
        </>
      )}

      <div
        className={classnames(styles.container, { [styles.mobile]: isMobile })}
      >
        <div className={styles.containerInner}>
          {controls.map(
            ({ shouldRender, control }, index) =>
              shouldRender && { ...control, key: index }
          )}
        </div>
      </div>
    </>
  );
}
