import { post } from '../utils/api';
import { sleep } from '../utils/sleep';

export async function exchangeToken(tokenId, dispatch) {
  const maxRetries = 4;
  let currentDelay = 2000; // Starting delay in milliseconds

  for (let retry = 0; retry < maxRetries; retry++) {
    try {
      const res = await post('/exchange-token', { tokenId }, dispatch);
      return res?.data?.token; // Return the response if successful
    } catch (err) {
      const status = err.response?.status || 0;
      switch (status) {
        case 400:
        case 401:
        case 403:
          throw err; // Throw immediately if the response status is 400, 401 or 403
        default:
          break; // Retry for other errors
      }
      if (retry === maxRetries - 1) {
        throw err; // Throw error after all retries are exhausted
      }
      await sleep(currentDelay);
      currentDelay *= 2; // Exponential backoff for next retry
    }
  }
}

export async function kickSelf(token, dispatch) {
  await post('/kick-self', null, dispatch, { skipSnack: true, token });
}
