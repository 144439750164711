import logger from '../utils/logger';

class WindowMessageHandler {
  constructor() {
    this.parentOrigin = null;
    this._onMessage = this._onMessage.bind(this);
    window.addEventListener('message', this._onMessage);
    this.sendReady();
  }

  _onMessage(event) {
    const { data } = event;
    if (!data || !data.type) return;
    const { type, payload } = data;
    logger.log('WindowMessageHandler._onMessage', type, payload);
    switch (type) {
      case 'originHandshake':
        logger.log('Received origin handshake message', event);
        this.parentOrigin = event.origin;
        break;
      default:
        break;
    }
  }

  destroy() {
    window.removeEventListener('message', this._onMessage);
  }

  send(type, payload) {
    if (!this.parentOrigin) {
      return;
    }
    window.parent.postMessage({ type, payload }, '*');
    logger.log('Send message to parent window', type, payload);
  }

  sendReady() {
    window.parent.postMessage({ type: 'ready', payload: true }, '*');
    logger.log('Send ready message to parent window');
  }
}

export default WindowMessageHandler;
