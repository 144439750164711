import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

export const DataRow = ({ test, status }) => {
  let icon;
  switch (status) {
    case 'success':
      icon = <CheckCircleIcon color="success" fontSize="large" />;
      break;
    case 'error':
      icon = <CancelIcon color="error" fontSize="large" />;
      break;
    case 'warning':
      icon = <ErrorIcon color="warning" fontSize="large" />;
      break;
    default:
      icon = <HourglassEmptyIcon fontSize="large" />;
  }
  return (
    <tr>
      <td style={{ paddingRight: '15%' }}>
        <span style={{ fontWeight: 'bold' }}>{test}</span>
      </td>
      <td>{icon}</td>
    </tr>
  );
};
