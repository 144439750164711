import logger from './logger';

export const getStorageItem = (
  storage,
  key,
  fallback = undefined,
  decodeJson = true
) => {
  try {
    const value = storage.getItem(key);
    return value !== null ? (decodeJson ? JSON.parse(value) : value) : fallback;
  } catch (error) {
    logger.error('Error retrieving state from storage:', {
      storage,
      key,
      error,
    });
    return fallback;
  }
};

export const setStorageItem = (storage, key, value, stringify = true) => {
  try {
    return storage.setItem(key, stringify ? JSON.stringify(value) : value);
  } catch (error) {
    logger.error('Error persisting state to storage:', { storage, key, error });
  }
};

export const removeStorageItem = (storage, key) => {
  try {
    storage.removeItem(key);
  } catch (error) {
    logger.error('Error removing state in storage:', { storage, key, error });
  }
};
