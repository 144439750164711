import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './VideoRoom.module.css';
import peerStyles from '../components/video/PeerView.module.css';
import classnames from 'classnames';
import PeerView from '../components/video/PeerView';
import { useWebRTCStatus } from '../contexts/WebRTCIssueContext';
import { useDispatch, useSelector } from 'react-redux';
import { setShareFullScreen } from '../store/slices/controlSlice';
import {
  setFloatSelf,
  setFloatShare,
  setPinned,
} from '../store/slices/layoutSlice';
import { withRoomContext } from '../lib/RoomClientContext';

let DEBUG;

function VideoPeers({
  roomId,
  isAdmin,
  isMobile,
  setUsersetFloatSelf,
  peersCombined,
  renderSize,
  allowVideoManipulation,
  onPeerMute,
  onToggleMute,
  roomClient,
}) {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const shareFullscreen = useSelector((state) => state.control.shareFullscreen);
  const hideVideo = useSelector((state) => state.control.hideVideo);
  const videoLoading = useSelector((state) => state.control.videoLoading);
  const handRaised = useSelector((state) => state.control.handRaised);
  const muted = useSelector((state) => state.control.muted);
  const viewLayout = useSelector((state) => state.layout.viewLayout);
  const dominantSpeaker = useSelector((state) => state.layout.dominantSpeaker);
  const floatShare = useSelector((state) => state.layout.floatShare);
  const floatSelf = useSelector((state) => state.layout.floatSelf);
  const pinned = useSelector((state) => state.layout.pinned);
  const numPages = useSelector((state) => state.layout.numPages);
  const recording = useSelector((state) => state.record.recording);
  const members = useSelector((state) => state.member.members);
  const peersSortMap = useSelector((state) => state.member.peersSortMap);
  const raisedHands = useSelector((state) => state.member.raisedHands);
  const deviceSettings = useSelector((state) => state.device.deviceSettings);
  const deviceSetup = useSelector((state) => state.device.deviceSetup);
  const shareData = useSelector((state) => state.room.shareData);
  const [maxWidth] = useState(window.innerWidth);
  const [maxHeight] = useState(window.innerHeight);
  const [videoFitMap, setVideoFitMap] = useState({});
  const [query] = useSearchParams();
  const numPeers = peersCombined.length;

  const { networkStatuses } = useWebRTCStatus();
  const resolution = {
    width: maxWidth / numPeers,
    height: maxHeight / numPeers,
  };
  const dataPeers = Object.values(peersSortMap).length;
  const isAlone = numPeers === 0 && (!shareData || floatShare);

  useEffect(() => {
    if (query.get('debug')) {
      DEBUG = true;
    }
  }, []);

  return (
    <div
      data-peers={dataPeers}
      className={classnames(styles.videos, {
        [peerStyles['spotlight-layout']]:
          viewLayout === 'spotlight' && (numPages === 1 || shareFullscreen),
        [peerStyles['spotlight-pagination-layout']]:
          viewLayout === 'spotlight' && numPages > 1 && !shareFullscreen,
        [peerStyles['grid-layout']]: viewLayout === 'grid',
        [styles.recording]: !!recording,
      })}
    >
      {shareData ? (
        <PeerView
          roomId={roomId}
          peerId={shareData.peerId}
          producers={shareData.producers.map((p) => {
            return {
              kind: p.kind,
              producer: { id: p.producerId, kind: p.kind },
              consumer: p?.consumer,
              stream: p.stream,
              canvas: p.video,
              show: true,
            };
          })}
          deviceSettings={deviceSettings}
          dominantSpeaker={dominantSpeaker}
          raisedHand={false}
          order={0}
          show={true}
          peerMuted={false}
          resolution={resolution}
          isSelf={shareData.peerId === userId}
          isAdmin={isAdmin}
          videoFit="contain"
          isMobile={isMobile}
          isShare
          shareFullscreen={shareFullscreen}
          setShareFullscreen={(val) => {
            if (val) {
              dispatch(setFloatSelf(true));
            }
            dispatch(setShareFullScreen(val));
          }}
          floatShare={floatShare}
          setFloatShare={(val) => dispatch(setFloatShare(val))}
          viewLayout={viewLayout}
          userData={members.find((x) => x.id === shareData.peerId)?.userData}
        />
      ) : null}
      {peersCombined.map((peer) => (
        <PeerView
          key={peer.id}
          roomId={roomId}
          peerId={peer.id}
          producers={peer.producers}
          deviceSettings={deviceSettings}
          dominantSpeaker={dominantSpeaker}
          raisedHand={raisedHands[peer.id]}
          order={peersSortMap[peer.id]}
          onPeerMute={() => onPeerMute(peer)}
          show={peer.id in peersSortMap}
          resolution={resolution}
          videoFit={videoFitMap[peer.id]}
          onVideoFitChange={(val) => {
            setVideoFitMap((prev) => ({
              ...prev,
              [peer.id]: val,
            }));
          }}
          userData={members.find((x) => x.id === peer.id)?.userData}
          onPin={() =>
            dispatch(setPinned({ ...pinned, [peer.id]: !pinned[peer.id] }))
          }
          pinned={pinned[peer.id]}
          isAdmin={isAdmin}
          isAlone={false}
          isSelf={false}
          isMobile={isMobile}
          debug={DEBUG}
          viewLayout={viewLayout}
          networkStatus={networkStatuses[peer.id]}
        />
      ))}
      <PeerView
        roomId={roomId}
        peerId={userId}
        producers={[
          roomClient.videoProducer && !hideVideo && !videoLoading
            ? {
                kind: 'video',
                producer: roomClient.videoProducer,
                consumer: null,
                stream: roomClient.videoManipulator?.getOutputStream(),
                canvas: allowVideoManipulation
                  ? roomClient.videoManipulator?.getCanvas()
                  : null,
                show: !hideVideo,
              }
            : null,
        ].filter((x) => x)}
        deviceSettings={deviceSettings}
        dominantSpeaker={dominantSpeaker}
        raisedHand={handRaised}
        order={peersSortMap[userId]}
        show={deviceSetup && (floatSelf || userId in peersSortMap)}
        resolution={resolution}
        videoFit={videoFitMap[userId]}
        onVideoFitChange={(val) => {
          setVideoFitMap((prev) => ({
            ...prev,
            [userId]: val,
          }));
        }}
        floatSelf={floatSelf}
        setFloatSelf={(val, triggerSelf = true) => {
          if (triggerSelf) {
            setUsersetFloatSelf(true);
          }
          dispatch(setFloatSelf(val));
        }}
        isSelf
        isAdmin={isAdmin}
        audioMuted={muted}
        onPin={() => setPinned({ ...pinned, [userId]: !pinned[userId] })}
        pinned={pinned[userId]}
        isMobile={isMobile}
        userData={members.find((x) => x.id === userId)?.userData}
        onPeerMute={() => onToggleMute()}
        viewLayout={viewLayout}
        isAlone={isAlone}
        setShareFullscreen={(val) => dispatch(setShareFullScreen(val))}
      />
    </div>
  );
}

export default withRoomContext(VideoPeers);
